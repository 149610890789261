import { FlatList, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC, useEffect, useState } from 'react';
import { View as RootView } from '../../components/Themed';
import { IUser } from '../../models/IUser';
import { firebase } from '../../app/config/web/firebase_config';
import { ActivityIndicator, Divider, Text } from 'react-native-paper';
import Spacing from '../../res/constants/Spacing';
import { DocumentData } from 'firebase/firestore';
import { Image2 } from '../../../assets/icons';
import { format } from 'date-fns';
import { PrivateScreenProps } from '../../app/navigation/types';
import ProfilePicture from '../../components/images/ProfilePicture';
import { UserRole } from '../../utils/enums';
import { MATERIAL_COLORS } from '../../res/constants/Colors';

const AdministrationScreen = ({ navigation, route }: PrivateScreenProps<'Administration'>) => {
  // #region STATES
  const [userList, setUserList] = useState<IUser[]>([]);
  // The number of documents to fetch per page.
  const [lastDoc, setLastDoc] = useState<DocumentData>();
  const [loading, setLoading] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  // #endregion
  // #region CUSTOM HOOKS
  // #endregion
  // #region FUNCTIONS
  const loadMore = async () => {
    if (allLoaded || loading) {
      return;
    }

    setLoading(true);

    const usersCollection = firebase.firestore().collection('users');
    let query = usersCollection.orderBy('uid').limit(15);
    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }
    const usersSnapshot = await query.get();

    if (!usersSnapshot.empty) {
      const lastDocSnapshot = usersSnapshot.docs[usersSnapshot.docs.length - 1];
      setLastDoc(lastDocSnapshot);
      setUserList((prevUsers) => [...prevUsers, ...usersSnapshot.docs.map((doc) => doc.data() as IUser)]);
    } else {
      setAllLoaded(true);
    }

    setLoading(false);
  };
  // #endregion
  // #region EFFECTS
  useEffect(() => {
    loadMore();
  }, []);
  // #endregion
  // #region MEMBERS
  const imageSize = 54;
  // #endregion
  return (
    <RootView style={styles.root}>
      {/* Render your documents here. */}
      <FlatList
        data={userList.sort((a, b) => {
          if (a.role && b.role) {
            return a.role - b.role;
          } else return 0;
        })}
        ItemSeparatorComponent={() => <Divider />}
        onEndReached={loadMore} // This prop will call the loadMore function when the end of the list is reached.
        onEndReachedThreshold={0.1} // This prop defines how far from the end (in terms of percentage) the end is considered to be reached.
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.uid.toString()}
        renderItem={({ item }) => {
          let creationDate: string = '';
          if (item.metadata.creationTime) {
            let dateObj = new Date(item.metadata.creationTime);

            creationDate = format(dateObj, 'MMM dd, yyy');
          }

          return (
            <TouchableOpacity
              onPress={() => navigation.navigate('Account', { userId: item.uid })}
              style={{
                paddingHorizontal: Spacing.regular,
                paddingVertical: Spacing.small,
                flexDirection: 'row',
                alignItems: 'center',
                gap: Spacing.small,
              }}
            >
              <ProfilePicture size={45} source={item.photoURL} hasRadius={true} />

              <View style={{ flex: 1 }}>
                <Text variant="titleSmall" style={{ flex: 1, color: item.role === UserRole.INACTIVE ? MATERIAL_COLORS.grey_500 : undefined }}>
                  {item.businessName ? item.businessName : item.email}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{ flex: 1, color: item.role === UserRole.INACTIVE ? MATERIAL_COLORS.grey_500 : undefined }}
                  variant="bodyMedium"
                >
                  {item.displayName ? item.displayName : item.email}
                </Text>
              </View>
              <Text style={{ color: item.role === UserRole.INACTIVE ? MATERIAL_COLORS.grey_500 : undefined }} variant="labelSmall">
                {creationDate}
              </Text>
            </TouchableOpacity>
          );
        }}
        ListFooterComponent={loading ? <ActivityIndicator size="large" /> : null}
      />
    </RootView>
  );
};

export default AdministrationScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingHorizontal: Spacing.regular,
  },
});
