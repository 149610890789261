import { ScrollView, StyleSheet } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Dialog, Portal } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';
import CurrencyListItem from './CurrencyListItem';
import { useFirestore } from '../../../app/providers/firestore';
import currency from '../../../res/data/currency';

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  uid: string;
}

const CurrencyDialog: FC<Props> = ({ visible, setVisible, uid }) => {
  const { handleUpdateUser, currentUser } = useFirestore();

  useEffect(() => {
    if (currentUser && currentUser.currency) setCurrentSelectedItem(currentUser.currency);
  }, [currentUser]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { translate } = useLocalization();
  const hideDialog = () => setVisible(false);
  const [currentSelectedItem, setCurrentSelectedItem] = useState<number | undefined>(undefined);
  const handleOnItemClick = useCallback((index: number) => {
    setCurrentSelectedItem(index);
  }, []);

  return (
    <Portal>
      <Dialog style={{ alignSelf: 'center', width: isTabletOrMobile ? '95%' : '40%' }} visible={visible} onDismiss={hideDialog}>
        <Dialog.Title>{translate('general.currency')}</Dialog.Title>
        <Dialog.ScrollArea>
          <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
            {currency.map((item, index) => {
              const selected = currentSelectedItem === item.id;

              return <CurrencyListItem key={index} selected={selected} index={index} setSelected={handleOnItemClick} title={item.currency} />;
            })}
          </ScrollView>
        </Dialog.ScrollArea>
        <Dialog.Actions>
          <Button
            onPress={() => {
              if (currentSelectedItem !== undefined) handleUpdateUser(uid, { currency: currency[currentSelectedItem].id }).then(() => hideDialog());
            }}
          >
            Ok
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default CurrencyDialog;

const styles = StyleSheet.create({ root: {} });
