import { FlatList, StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import useColorScheme from '../../../hooks/useColorScheme';
import Styles from '../../../res/constants/Styles';
import { Portal, Modal, Text, Button } from 'react-native-paper';
import Spacing from '../../../res/constants/Spacing';
import Config from '../../../res/constants/Config';
import Layout, { DEFAULT_GAP } from '../../../res/constants/Layout';
import { MATERIAL_COLORS } from '../../../res/constants/Colors';
import GeneralSeparator from '../../separator/GeneralSeparator';
import { Orientation } from '../../../utils/enums';
import BillOrderListItem from '../../orders/BillOrderListItem';
import { useFirestore } from '../../../app/providers/firestore';
import { printTotal } from '../../../utils/common';
import { IOrder } from '../../../models/IOrder';
import { IMeta } from '../../../models/IMeta';
import collections from '../../../utils/collections';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';

interface Props {
  title: string;
  message: string;
  visible: boolean;
  dismiss: () => void;
  onConfirm: () => void;
  tableItems: any;
}

const SIZE = Config.isWeb && Layout.window.width > 800 ? 400 : Layout.window.width * 0.8;

const BillDialog: FC<Props> = ({ title, message, visible, dismiss, onConfirm, tableItems }) => {
  //#region HOOOKS
  const colorScheme = useColorScheme();
  const { currentUser, handleDataCU } = useFirestore();
  const { translate } = useLocalization();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];

  if (!visible || !currentUser) return null;

  const bills = tableItems.data.reduce(
    (
      acc: { customerName: any; customerPhone: any; customerAddress: any; orderItems: any[]; orderKey: string }[],
      order: { cartItems: any[]; customerName: any; customerPhone: any; customerAddress: any; key: string }
    ) => {
      const orderItems = order.cartItems.reduce(
        (
          itemAcc: {
            get: (arg0: any) => any;
            set: (arg0: any, arg1: { name: string; quantity: number; key: string; price: number; image: string }) => void;
          },
          cartItem: { name: string; key: string; price: number; image: string }
        ) => {
          if (cartItem.name && typeof cartItem.price === 'number') {
            const existingItem = itemAcc.get(cartItem.name);

            if (existingItem) {
              existingItem.quantity += 1;
              existingItem.price += cartItem.price;
              existingItem.image = cartItem.image; // Update image with the image of the current cart item
            } else {
              itemAcc.set(cartItem.name, {
                name: cartItem.name,
                quantity: 1,
                key: cartItem.key,
                price: cartItem.price,
                image: cartItem.image, // Set image when creating a new bill item
              });
            }
          } else {
            console.error(`Invalid cart item encountered: ${JSON.stringify(cartItem)}`);
          }

          return itemAcc;
        },
        new Map<string, { name: string; quantity: number; price: number; image: string | null }>()
      );

      const orderBill = {
        customerName: order.customerName,
        customerPhone: order.customerPhone,
        customerAddress: order.customerAddress,
        orderKey: order.key,
        orderItems: Array.from(orderItems.values()),
      };

      acc.push(orderBill);

      return acc;
    },
    []
  );

  //#endregion
  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={dismiss}
        contentContainerStyle={{
          width: SIZE,
          maxHeight: '80%',
          backgroundColor: theme.background,
          alignSelf: 'center',
          borderRadius: Spacing.defaultRadius,
        }}
      >
        <View style={{ padding: Spacing.regular }}>
          <Text style={{ alignSelf: 'center', color: theme.text }} variant="titleLarge">
            {title}
          </Text>

          <Text style={{ color: theme.text }} variant="bodyMedium">
            {message}
          </Text>

          <View style={{ flex: 1, paddingVertical: Spacing.regular }}>
            <FlatList
              ItemSeparatorComponent={() => <GeneralSeparator orientation={Orientation.HORIZONTAL} size={'90%'} color={MATERIAL_COLORS.grey_300} />}
              data={bills[0].orderItems}
              contentContainerStyle={{ gap: DEFAULT_GAP, flexGrow: 1 }}
              renderItem={({ item, index }: { item: any; index: number }) => {
                return <BillOrderListItem key={index} {...{ currentUser }} item={item} />;
              }}
            />
          </View>
          <View style={{ flexDirection: 'row', gap: Spacing.medium, justifyContent: 'flex-end' }}>
            <Button
              icon={'printer'}
              onPress={() => {
                printTotal(bills, currentUser, tableItems.title.header);
              }}
              textColor={theme.onPrimary}
              style={{ backgroundColor: theme.primary }}
              mode="contained-tonal"
            >
              {translate('general.print')}
            </Button>

            <Button
              textColor={theme.onPrimary}
              style={{ backgroundColor: theme.primary }}
              mode="contained-tonal"
              onPress={() => {
                tableItems.data.forEach((order: IOrder) => {
                  if (order) {
                    const meta: IMeta = { ...order.meta, modified: new Date().getTime(), isActive: false };

                    const data = {
                      ...order,
                      meta,
                      staffKey: tableItems.staffKey,
                    };

                    handleDataCU(data, collections.orders);
                  }
                });
                dismiss();
              }}
            >
              {translate('general.close_table')}
            </Button>
          </View>
        </View>
      </Modal>
    </Portal>
  );
};

export default BillDialog;

const styles = StyleSheet.create({ root: {} });
