import React, { FC } from 'react';
import { View, ViewProps } from '../Themed';
import { useMediaQuery } from 'react-responsive';
import Spacing from '../../res/constants/Spacing';
import { Button } from 'react-native-paper';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import Config from '../../res/constants/Config';
import { SafeAreaView } from 'react-native-safe-area-context';
import { IconSource } from 'react-native-paper/lib/typescript/src/components/Icon';

interface Props extends ViewProps {
  onButtonClick: () => void;
  buttonTitle: string;
  buttonIcon?: IconSource;
}
const RightPanel: FC<Props> = ({ onButtonClick, buttonTitle, buttonIcon, ...props }) => {
  //#region HOOKS
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 }) || !Config.isWeb;
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  return (
    <View
      {...props}
      style={{ width: !isTabletOrMobile ? '40%' : '100%', overflow: 'hidden', ...(!isTabletOrMobile && { borderRadius: Spacing.defaultRadius }) }}
    >
      {props.children}

      <SafeAreaView
        edges={['bottom']}
        style={{ backgroundColor: theme.lightSelectedItem, paddingHorizontal: Spacing.regular, paddingBottom: Spacing.regular }}
      >
        <Button icon={buttonIcon} mode="contained-tonal" contentStyle={{ backgroundColor: theme.primary }} textColor="white" onPress={onButtonClick}>
          {buttonTitle}
        </Button>
      </SafeAreaView>
    </View>
  );
};

export default RightPanel;
