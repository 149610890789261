import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import React, { FC, memo } from 'react';
import { MATERIAL_COLORS } from '../../../res/constants/Colors';
import { DEFAULT_ITEM_HEIGHT } from '../../../res/constants/Layout';
import Spacing from '../../../res/constants/Spacing';
import CText from '../../CText';
import { IGeneralItem } from '../../../models/IGeneralItem';

interface Props {
  item: IGeneralItem;
  selectOption: (value: any) => void;
  isOptionSelected: boolean;
}
const ICON_SIZE = DEFAULT_ITEM_HEIGHT - Spacing.regular;
const OptionListItem: FC<Props> = ({ item, selectOption, isOptionSelected }) => {
  if (item === undefined) {
    return null;
  }
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={[styles.root, { backgroundColor: isOptionSelected ? MATERIAL_COLORS.light_blue_50 : undefined }]}
      onPress={() => {
        selectOption(item);
      }}
    >
      {item.icon && (
        <Image style={{ height: ICON_SIZE, width: ICON_SIZE }} source={require(`../../../../assets/images/allergens/${item.icon}.svg`)} />
      )}
      <CText style={{ marginStart: Spacing.regular }} fontType="body1">
        {item.title}
      </CText>
    </TouchableOpacity>
  );
};

export default memo(OptionListItem);

const styles = StyleSheet.create({
  root: {
    height: DEFAULT_ITEM_HEIGHT - 1,
    alignItems: 'center',
    borderRadius: Spacing.defaultRadius,
    marginVertical: 0.5,
    flexDirection: 'row',
    paddingHorizontal: Spacing.regular,
  },
});
