import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { Portal, Modal, Text, Button, Divider } from 'react-native-paper';
import Spacing from '../../../res/constants/Spacing';
import Config from '../../../res/constants/Config';
import Layout, { DEFAULT_GAP } from '../../../res/constants/Layout';
import { FieldValues, useForm } from 'react-hook-form';
import CustomInput from '../../inputs/CustomInput';
import { IMeta } from '../../../models/IMeta';
import collections from '../../../utils/collections';
import { useFirestore } from '../../../app/providers/firestore';
import { useAppDispatch } from '../../../hooks/hooks';
import { clearCart } from '../../../app/features/cart/cartSlice';
import { IOrder } from '../../../models/IOrder';
import { useNavigation } from '@react-navigation/native';
import useColorScheme from '../../../hooks/useColorScheme';
import Styles from '../../../res/constants/Styles';

interface Props {
  visible: boolean;
  setVisible: () => void;
  tableKey: string;
  business: string;
  cartItems: any[];
}
const SIZE = Config.isWeb && Layout.window.width > 800 ? 400 : Layout.window.width * 0.8;

const CheckOutDialog: FC<Props> = ({ visible, setVisible, tableKey, business, cartItems }) => {
  const { handleDataUnAuthorized } = useFirestore();
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customerName: '',
      customerPhone: '',
      customerAddress: '',
    },
  });

  const onSubmit = (params: FieldValues) => {
    const { customerName, customerPhone, customerAddress } = params;

    const meta: IMeta = { created: new Date().getTime(), isActive: true, isPending: true, isCompleted: false };

    const payload: IOrder = {
      customerName,
      customerPhone,
      customerAddress,
      meta,
      tableKey,
      cartItems,
    };

    handleDataUnAuthorized(business, payload, collections.orders).then((value) => {
      navigation.navigate('OrderStatus' as never, { key: value, uid: business } as never);
    });
    setVisible();
    dispatch(clearCart());
  };
  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={setVisible}
        contentContainerStyle={{
          width: SIZE,
          backgroundColor: theme.background,
          alignSelf: 'center',
          borderRadius: Spacing.defaultRadius,
        }}
      >
        <View
          style={{
            flex: 1,
            paddingHorizontal: Spacing.regular,
            overflow: 'hidden',
            gap: DEFAULT_GAP,
            borderRadius: Spacing.defaultRadius,
          }}
        >
          <Text variant="bodyLarge" style={{ padding: Spacing.regular, alignSelf: 'center' }}>
            Enter your info
          </Text>
          <Divider />
          <CustomInput
            error={errors.customerName}
            required={true}
            control={control}
            name={'customerName'}
            placeholder={'Your Name'}
            iconProps={{ leftName: 'User', isLeftIconly: true, showRight: false }}
          />

          <CustomInput
            error={errors.customerPhone}
            required={true}
            control={control}
            name={'customerPhone'}
            placeholder={'Your Phone'}
            iconProps={{ leftName: 'Call', isLeftIconly: true, showRight: false }}
            keyboardTypeOptions="phone-pad"
          />

          <CustomInput
            error={errors.customerAddress}
            required={true}
            control={control}
            name={'customerAddress'}
            placeholder={'Your Address'}
            iconProps={{ leftName: 'Location', isLeftIconly: true, showRight: false }}
          />

          <View style={{ padding: Spacing.regular }}>
            <Button mode="contained" onPress={handleSubmit(onSubmit)} contentStyle={{ backgroundColor: theme.primary }}>
              Ok
            </Button>
          </View>
        </View>
      </Modal>
    </Portal>
  );
};

export default CheckOutDialog;

const styles = StyleSheet.create({ root: {} });
