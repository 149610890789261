import { StyleSheet, Text, View } from 'react-native';
import React, { FC } from 'react';
import { PrivateStackScreenProps } from '../../../../app/navigation/types';

const SponsorDetailScreen = ({ navigation, route }: PrivateStackScreenProps<'SponsorDetail'>) => {
  // #region STATES
  // #endregion
  // #region CUSTOM HOOKS
  // #endregion
  // #region FUNCTIONS
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <View style={styles.root}>
      <Text>SponsorDetailScreen</Text>
    </View>
  );
};

export default SponsorDetailScreen;

const styles = StyleSheet.create({ root: {} });
