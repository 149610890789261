import { StyleSheet, View, Image } from 'react-native';
import React, { FC } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { AuthType } from '../../utils/enums';
import Layout from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { AppImages } from '../../../assets/images';
import { Text } from 'react-native-paper';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';

interface Props {
  type: AuthType;
}
const Header: FC<Props> = ({ type }) => {
  //#region HOOKS
  const { translate } = useLocalization();
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView edges={['top']} style={{ flex: 1, padding: Spacing.regular }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ flex: 1, color: theme.text }} variant="titleLarge">
            {type === AuthType.LOGIN ? translate('authentication.login') : translate('authentication.register')}
          </Text>
        </View>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Image source={AppImages.main_icon2} style={{ height: Layout.window.height * 0.17, width: Layout.window.height * 0.17 }} />
          <Text style={{ marginTop: Spacing.small, color: theme.text }} variant="titleMedium">
            Menu Amore Mio
          </Text>
        </View>
      </SafeAreaView>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({ root: {} });
