import { StyleSheet } from 'react-native';
import React, { FC, useEffect } from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
import { MATERIAL_COLORS } from '../../res/constants/Colors';

interface Props {
  current: boolean;
}
const INDICATOR_SIZE = 10;

const IndicatorItem: FC<Props> = ({ current }) => {
  const animatedValue = useSharedValue(INDICATOR_SIZE);

  const style = useAnimatedStyle(() => {
    return { width: animatedValue.value };
  });

  useEffect(() => {
    if (current) animatedValue.value = withSpring(INDICATOR_SIZE * 2);
    else animatedValue.value = withSpring(INDICATOR_SIZE);
  }, [current]);
  return <Animated.View style={[styles.root, style]} />;
};

export default IndicatorItem;

const styles = StyleSheet.create({
  root: {
    width: INDICATOR_SIZE,
    height: INDICATOR_SIZE,
    borderRadius: INDICATOR_SIZE / 2,
    backgroundColor: MATERIAL_COLORS.amber,
  },
});
