import { StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { PrivateStackScreenProps } from '../../../../app/navigation/types';
import { View as RootView } from '../../../../components/Themed';
import Spacing from '../../../../res/constants/Spacing';
import EditItemSideMenu from '../../../../components/side_menu/EditItemSideMenu';
import { FormType, ItemStatus, UserRole } from '../../../../utils/enums';
import { useFirestore } from '../../../../app/providers/firestore';
import collections from '../../../../utils/collections';
import { ITable } from '../../../../models/ITable';
import { useMediaQuery } from 'react-responsive';
import useLocalization from '../../../../app/providers/localization/LocalizationProvider';
import { IconButton } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import BusinessPicker from '../../../../components/general/userPicker/BusinessPicker';
import { useAppSelector } from '../../../../hooks/hooks';
import { LeftPanelRefactor } from '../../../../components/responsive_containers/LeftPanelRefactor';
import useToggle from '../../../../hooks/useToggle';
import RightPanelRefactor from '../../../../components/responsive_containers/RightPanelRefactor';
import TableForm from '../TableForm';

const EditTableScreen = ({ navigation, route }: PrivateStackScreenProps<'EditTable'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  //#region STATES
  const [tableList, setTableList] = useState<ITable[]>([]);
  const [currentModel, setCurrentModel] = useState<ITable | undefined>(undefined);
  const [formType, setFormType] = useState<FormType>(route.params.type);
  const [confirmDialogVisibility, toggleConfirmDialog] = useToggle(false);
  const [archivedMode, toggleArchivedMode] = useToggle(false);
  const currentSelectedUser: any = useAppSelector((state) => state.backOffice.selectedUser);
  const tableKey = route.params.key;

  //#endregion
  //#region HOOKS
  const { translate } = useLocalization();
  const { currentUser, handleListenCollectionChange } = useFirestore();
  //#endregion
  //#region FUNCTIONS

  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
      } else {
        return currentUser.uid;
      }
    }
  };

  const handleClearObject = () => {
    setCurrentModel(undefined);
    setFormType(FormType.NEW);
    navigation.setParams({ type: FormType.NEW });
  };

  const handleMenuItemClick = (index: number) => {
    switch (index) {
      case 0:
        toggleArchivedMode();
        handleClearObject();
        break;

      default:
        break;
    }
  };

  const handleSelectCurrentModel = (item: ITable) => {
    if (!isTabletOrMobile) {
      setCurrentModel(item);
      setFormType(FormType.EDIT);
      navigation.setParams({ type: FormType.EDIT });
    } else {
      navigation.navigate('TableDetail', { key: item.key, type: FormType.EDIT, archivedMode: archivedMode });
    }
  };

  //#endregion
  //#region EFFETCS
  useFocusEffect(
    useCallback(() => {
      const uid = handleTargetUid();
      if (uid) {
        const tables = handleListenCollectionChange(uid, collections.table)
          .where('meta.status', '==', archivedMode ? ItemStatus.INACTIVE : ItemStatus.ACTIVE)
          .onSnapshot((querySnapshot) => {
            const temporary: ITable[] = [];
            querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
              temporary.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });
            setTableList(temporary);

            if (tableKey) {
              const table: ITable = temporary.filter((i) => i.key === tableKey)[0];
              if (table) setCurrentModel(table);
            }
          });

        return () => {
          tables();
        };
      }
    }, [currentUser, currentSelectedUser, archivedMode])
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        {
          if (currentUser?.role === UserRole.BACKOFFICE)
            return (
              <BusinessPicker onBackClick={() => (navigation.canGoBack() ? navigation.pop() : navigation.navigate('Root', { screen: 'Profile' }))} />
            );
        }
      },
      headerRight: () =>
        currentModel !== undefined && <IconButton icon={archivedMode ? 'delete-restore' : 'delete'} onPress={() => toggleConfirmDialog()} />,
    });
  }, [navigation, formType, archivedMode, currentUser, currentSelectedUser]);
  //#endregion

  return (
    <RootView style={[styles.root, { padding: isTabletOrMobile ? 0 : Spacing.regular }]}>
      <LeftPanelRefactor
        showFab={!archivedMode}
        onFabPress={() => {
          handleClearObject();
          if (isTabletOrMobile) {
            navigation.navigate('TableDetail', { key: undefined, type: FormType.NEW, archivedMode });
          }
        }}
      >
        <EditItemSideMenu
          isBackVisible={archivedMode}
          menuItems={[{ id: 0, title: translate('general.recycle_bin').toString(), icon: 'delete' }]}
          items={tableList}
          onPress={handleSelectCurrentModel}
          onMenuItemPress={handleMenuItemClick}
          onBackPress={() => {
            toggleArchivedMode();
            handleClearObject();
          }}
          currentSelectedItem={currentModel && currentModel?.key}
        />
      </LeftPanelRefactor>

      <RightPanelRefactor>
        <TableForm {...{ currentModel, handleClearObject, formType, archivedMode, confirmDialogVisibility, toggleConfirmDialog }} />
      </RightPanelRefactor>
    </RootView>
  );
};

export default EditTableScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    gap: Spacing.small,
  },
});
