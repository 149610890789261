import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { IconButton, Text } from 'react-native-paper';
import CustomCartIcon from '../pressable/CustomCartIcon';
import { useNavigation } from '@react-navigation/native';
import { IMenu } from '../../models/IMenu';
import Spacing from '../../res/constants/Spacing';

interface Props {
  businessId: string;
  tableKey: string;
  currentMenu: IMenu;
}
const Header: FC<Props> = ({ businessId, tableKey, currentMenu }) => {
  // #region STATES
  // #endregion
  // #region CUSTOM HOOKS
  const navigation = useNavigation();
  // #endregion
  // #region FUNCTIONS
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <View style={styles.root}>
      <IconButton
        icon={'chevron-left'}
        onPress={() => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else navigation.navigate('Menu' as never);
        }}
      />
      <Text numberOfLines={2} ellipsizeMode="tail" variant="titleMedium" style={{ flex: 1, textAlign: 'center' }}>
        {currentMenu.name}
      </Text>
      <CustomCartIcon {...{ businessId, tableKey }} />
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: Spacing.medium,
  },
});
