import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';

import { firebase } from '@react-native-firebase/auth';
import { FirebaseAuthTypes } from '@react-native-firebase/auth';
import firestore from '@react-native-firebase/firestore';

import { userMapper } from '../../../models/IUser';
import { ILoginDTO } from '../../../models/dto/ILoginDTO';
import collections from '../../../utils/collections';
import { UserRole } from '../../../utils/enums';

const Context = createContext<any>({});

export interface IAuthenticationProviderProps {
  children: ReactNode;
}

export const AuthenticationProvider = ({ children }: IAuthenticationProviderProps) => {
  const usersRef = useMemo(() => firestore().collection(collections.users), []);
  //#region STATES
  const [user, setUser] = useState<FirebaseAuthTypes.User | undefined | null>();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  //#endregion
  //#region FUNCTIONS
  function onAuthStateChanged(user: FirebaseAuthTypes.User | null) {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }

    setInitialLoading(false);
  }

  const signInWithEmailAndPassword = async (body: ILoginDTO) => {
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(body.email, body.password)
        .then((authUser: FirebaseAuthTypes.UserCredential) => {
          if (authUser.user) {
            updateUser(authUser.user);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async (value: FirebaseAuthTypes.User) => {
    await usersRef
      .doc(value.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          usersRef
            .doc(value.uid)
            .update(userMapper(value))
            .then(() => {
              console.debug('User updated!');
            })
            .catch((error) => console.error(`useFirebase ${error}`));
        } else {
          usersRef
            .doc(value.uid)
            .set({ ...userMapper(value), role: UserRole.SIMPLE })
            .then(() => {
              console.debug('User created!');
            })
            .catch((error) => console.error(`useFirebase ${error}`));
        }
      })
      .catch((error) => console.error(error));
  };

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
    } catch (e) {
    } finally {
      //TODO: set loading false
      AsyncStorage.clear();
    }
  };
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(onAuthStateChanged);
  }, []);
  //#endregion

  const memoValue = useMemo(() => ({ user, signInWithEmailAndPassword, signOut }), [user]);
  return <Context.Provider value={memoValue}>{children}</Context.Provider>;
};

export default function useAuth() {
  return useContext(Context);
}
