import { MATERIAL_COLORS } from '../res/constants/Colors';
import { DEFAULT_ITEM_HEIGHT } from '../res/constants/Layout';
import { al, el, en, it, ro } from '../res/data/DummyIngredients';
import { Currency } from './enums';
import { MaterialColorType, MaterialColorNumberType } from './types';
import * as MediaLibrary from 'expo-media-library';
import currency from '../res/data/currency';
import { format } from 'date-fns';
import { IUser } from '../models/IUser';

export const maximumExpandableHeight = (items: any[], hasExtraHeight?: boolean) => {
  const EXTRA_HEIGHT = hasExtraHeight ? DEFAULT_ITEM_HEIGHT : 0;
  if (items === undefined) return DEFAULT_ITEM_HEIGHT + EXTRA_HEIGHT;
  if (items.length === 0) return DEFAULT_ITEM_HEIGHT + EXTRA_HEIGHT;

  if (items.length > 5) return DEFAULT_ITEM_HEIGHT * 5 + EXTRA_HEIGHT;
  else return DEFAULT_ITEM_HEIGHT * items.length + EXTRA_HEIGHT;
};

export const materialColorProvider = (family: MaterialColorType, value?: MaterialColorNumberType) => {
  if (value) return MATERIAL_COLORS[`${family}_${value}`];
  else return MATERIAL_COLORS[`${family}`];
};

export async function requestPermissions(): Promise<boolean> {
  const { status } = await MediaLibrary.requestPermissionsAsync();

  if (status === 'granted') {
    return true;
  } else {
    alert('Permission to access media library was not granted.');
    return false;
  }
}

export const currencyResolver = (value?: Currency) => {
  if (value !== undefined && value !== null) {
    return currency[value];
  }
};

interface Translation {
  id: number;
  title: string;
}

interface Translations {
  [language: string]: Translation[];
}

export function preparePayload(translations: Translations) {
  const payload = [];

  for (const translation of Object.values(translations)[0]) {
    const item = {
      id: translation.id,
      emoji: '',
      name: {} as { [key: string]: string },
    };

    const emojiEndIndex = translation.title.indexOf(' ');
    if (emojiEndIndex !== -1) {
      item.emoji = translation.title.slice(0, emojiEndIndex);
    }

    for (const language in translations) {
      const translationForLanguage = translations[language].find((t) => t.id === translation.id);
      if (translationForLanguage) {
        const titleWithoutEmoji = translationForLanguage.title.slice(emojiEndIndex + 1);
        item.name[language] = titleWithoutEmoji;
      }
    }

    payload.push(item);
  }

  return payload;
}

export const translations = {
  al: al,
  el: el,
  en: en,
  it: it,
  ro: ro,
};

export const printInvoice = (invoice: any[], currentUser: IUser, tableName: string, customerNumber: string, customerAddress: string, singlePage: boolean = false) => {
  let printWindow = window.open('', '', 'width=600,height=600');
  if (printWindow) {
    printWindow.document.open();

    let invoiceHTML = '';
    let pageTotal = 0;

    if (singlePage) {
      // Handle all items on a single page
      invoiceHTML += startNewPage(currentUser, tableName, customerNumber, customerAddress);
      invoice.forEach((item) => {
        const itemHTMLResult = buildItemHtml(item, currentUser);
        invoiceHTML += itemHTMLResult.html;
        pageTotal += itemHTMLResult.total;
      });
      invoiceHTML += endPage(pageTotal, currentUser);
    } else {
      // Current logic handling null and non-null subCategory items
      const nullSubCategoryItems = invoice.filter((item) => item.data[0].subCategory === null);
      const nonNullSubCategoryItems = invoice.filter((item) => item.data[0].subCategory !== null);
      nonNullSubCategoryItems.sort((a, b) => a.data[0].subCategory - b.data[0].subCategory);

      if (nullSubCategoryItems.length > 0) {
        invoiceHTML += startNewPage(currentUser, tableName, customerNumber, customerAddress);
        nullSubCategoryItems.forEach((item) => {
          const itemHTMLResult = buildItemHtml(item, currentUser);
          invoiceHTML += itemHTMLResult.html;
          pageTotal += itemHTMLResult.total;
        });
        invoiceHTML += endPage(pageTotal, currentUser);
        pageTotal = 0; // reset pageTotal for next page
      }

      let currentSubCategory: any = null;

      nonNullSubCategoryItems.forEach((item) => {
        if (currentSubCategory !== item.data[0].subCategory) {
          if (currentSubCategory !== null) {
            invoiceHTML += endPage(pageTotal, currentUser);
            pageTotal = 0; // reset pageTotal for next page
          }
          invoiceHTML += startNewPage(currentUser, tableName, customerNumber, customerAddress);
          currentSubCategory = item.data[0].subCategory;
        }

        const itemHTMLResult = buildItemHtml(item, currentUser);
        invoiceHTML += itemHTMLResult.html;
        pageTotal += itemHTMLResult.total;
      });

      if (nonNullSubCategoryItems.length > 0) {
        invoiceHTML += endPage(pageTotal, currentUser);
      }
    }

    printWindow.document.write(invoiceHTML);
    printWindow.document.close();
    printWindow.print();
  }
};

const startNewPage = (currentUser: IUser, tableName: string, customerNumber: string, customerAddress: string) => {
  return `
  <style>
  body {
    font-family: Arial, sans-serif;
}
.title {
    text-align: center;
    font-size: 18px;
}
.date {
    text-align: center;
    font-size: 12px;
    padding: 10px;
}
.divider {
    border-top: 1px solid black;
}
.item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 14px;
    align-items: center;
}
.item-quantity {
    width: 30px;
    text-align: center;
    margin-right: 5px;
    font-size: 12px;
}
.item-name {
    flex: 1;
    margin-right: 5px;
    font-size: 12px;
}
.item-price {
    font-size: 12px;
}
.total {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 14px;
}
.removed-ingredients {
  margin-top: 10px;
}

.removed-ingredients-title {
  font-size: 12px;
  font-weight: bold;
}

.removed-ingredients-list {
  font-size: 12px;
  list-style-type: none;
  padding-left: 0;
}

.removed-ingredients-list li {
  margin-bottom: 5px;
}
  </style>
  <div class="title">${currentUser?.businessName}</div>
  <div class="date">${tableName} • ${format(new Date(), 'dd MMM, yyyy HH:mm')}</div>
  ${customerAddress && customerNumber && `<div class="date">${customerNumber} • ${customerAddress}</div>`}
  <div class="divider"/>
  `;
};

const buildItemHtml = (item: any, currentUser: IUser) => {
  let totalCost = item.data[0].price * item.data.length;
  let itemHTML = `
  <div class="item">
    <div class="item-quantity">${item.data.length} x </div>
    <div class="item-name">${item.data[0].name}</div>
    <div class ="item-price">${totalCost} ${currencyResolver(currentUser.currency)?.symbol}</div>
  </div>`;

  return { html: itemHTML, total: totalCost };
};

const endPage = (total: number, currentUser: IUser) => {
  return `
  <div class="divider"/>
  <div class="total">
    <div>Total</div>
    <div>${total} ${currencyResolver(currentUser?.currency)?.symbol}</div>
  </div>
  <p style="page-break-after: always;"></p>`;
};


export const printTotal = (invoice:any, currentUser:IUser, tableName:string) =>{

  let printWindow = window.open('', '', 'width=600,height=600');
  if (printWindow) {
    printWindow.document.open();

    let html = `
    <style>
    body {
      font-family: Arial, sans-serif;
    }
    .title {
      text-align: center;
      font-size: 18px;
    }
    .date {
      text-align: center;
      font-size: 12px;
      padding: 10px;
    }
    .divider {
      border-top: 1px solid black;
    }
    .item {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      font-size: 14px;
      align-items: center;
    }
    .item-quantity {
      width: 30px;
      text-align: center;
      margin-right: 5px;
      font-size: 12px;
    }
    .item-name {
      flex: 1;
      font-size: 12px;
    }
    .item-price {
      font-size: 12px;
    }
    .total {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      font-size: 14px;
    }
    </style>
    
    <div class="title">${currentUser?.businessName}</div>
    <div class="date">${tableName} • ${format(new Date(), 'dd MMM, yyyy HH:mm')}</div>
    ${invoice[0].customerAddress && invoice[0].customerPhone && `<div class="date">${invoice[0].customerPhone} • ${invoice[0].customerAddress}</div>`}
    <div class="divider"/>
    `;

    // Intermediary data structure
    let itemsGroupedByKey: { [key: string]: { name: string, quantity: number, price: number } } = {};

    // Grand total price of all items
    let grandTotal = 0;

    // Iterate over the invoice and fill the data structure
    invoice.forEach((order: any) => {
      order.orderItems.forEach((item: any) => {
        // If item does not exist in the map, initialize it
        if (!itemsGroupedByKey[item.key]) {
          itemsGroupedByKey[item.key] = { name: item.name, quantity: 0, price: 0 };
        }

        // Sum quantity and price
        itemsGroupedByKey[item.key].quantity += item.quantity;
        itemsGroupedByKey[item.key].price += item.price;
        grandTotal += item.price;
      });
    });

    // Generate HTML for each item
    for (const itemKey in itemsGroupedByKey) {
      html += `
      <div class="item">
        <span class="item-quantity">${itemsGroupedByKey[itemKey].quantity} x </span>
        <span class="item-name">${itemsGroupedByKey[itemKey].name}</span>
        <span class="item-price">${itemsGroupedByKey[itemKey].price.toFixed(2)} ${currencyResolver(currentUser.currency)?.symbol}</span>
      </div>
      `;
    }
    html += `<div class="divider"/>`;

    // Add grand total to the bottom of the bill
    html += `
    <div class="total">
      <span>Total</span>
      <span>${grandTotal.toFixed(2)} ${currencyResolver(currentUser.currency)?.symbol}</span>
    </div>
    `;

    printWindow.document.write(html);
    printWindow.document.close();
    printWindow.print();

  }
}

export function getInitials(name: string) {
  var parts = name.split(' ');
  var initials = '';
  for (var i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0].toUpperCase();
    }
  }
  return initials;
}