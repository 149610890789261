import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  currentLanguage: string;
}

const initialState: InitialState = {
  currentLanguage: 'it',
};

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
});

export const { changeLanguage } = localeSlice.actions;
export default localeSlice.reducer;
