import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Text } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';
import { MATERIAL_COLORS } from '../../res/constants/Colors';

interface Props {
  title?: string;
}
const Title: FC<Props> = ({ title }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  return (
    <View style={styles.root}>
      <Text style={[styles.text, { color: theme.text }]} variant={isTabletOrMobile ? 'bodyLarge' : 'headlineSmall'}>
        {title}
      </Text>
    </View>
  );
};

export default Title;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Spacing.small,
    width: '100%',
    paddingHorizontal: Spacing.regular,
    justifyContent: 'center',
  },
  text: { textAlign: 'center', alignSelf: 'center', flex: 1 },
});
