import { LinkingOptions } from '@react-navigation/native';
import { PrivateStackParamList, PublicParamList, SimpleUserStackParamList } from './types';

export const linking: LinkingOptions<PrivateStackParamList & PublicParamList & SimpleUserStackParamList> = {
  prefixes: ['https://agenda-roko.web.app/', 'myapp://', 'https://wwww.menuamoremio.com/', 'https://192.168.1.65:19006/'],
  config: {
    screens: {
      Root: {
        screens: {
          Home: 'home',
          Profile: 'profile',
          Stats: 'stats',
        },
      },
      Menu: 'menu/:business/:table',
      MenuDetail: 'menu-detail/:business/:table/:menuKey',
      Cart: 'cart/:business/:table',
      EditMenu: 'edit-menu/:key/:type',
      EditTable: 'edit-table/:key/:type',
      EditStaff: 'edit-staff/:key/:type',
      StaffDetail: 'staff-detail/:key/:type',
      EditTranslations: 'edit-translations',
      LandingPage: '',
      Login: 'login',
      Register: 'register',
      Orders: 'orders',
      OrderDetail: 'order-detail/:key',
      OrderStatus: 'order-status/:key/:uid',
      Settings: 'settings',
    },
  },
};
