import { StyleSheet } from 'react-native';
import React, { FC, useCallback, useMemo } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import CText from '../CText';
import CustomPressable from '../pressable/CustomPressable';
import useColorScheme from '../../hooks/useColorScheme';
import { AuthType } from '../../utils/enums';
import Styles from '../../res/constants/Styles';
import { useNavigation } from '@react-navigation/native';
import Spacing from '../../res/constants/Spacing';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

interface Props {
  type: AuthType;
}
const Footer: FC<Props> = ({ type }) => {
  //#region HOOKS
  const navigation = useNavigation();
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();

  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region FUNCTIONS
  const text = useMemo(() => {
    return type === AuthType.LOGIN ? translate('authentication.missing_account') : translate('authentication.existing_account');
  }, [type, translate]);

  const actionText = useMemo(() => {
    return type === AuthType.LOGIN ? translate('authentication.register') : translate('authentication.login');
  }, [type, translate]);

  const handleOnActionPress = useCallback(() => {
    type === AuthType.LOGIN ? navigation.navigate('Register' as never) : navigation.navigate('Login' as never);
  }, [type]);
  //#endregion
  return (
    <SafeAreaView style={styles.root} edges={['bottom']}>
      <CText fontType="body1">{text}</CText>
      <CustomPressable style={{ paddingHorizontal: Spacing.small }} onPress={handleOnActionPress}>
        <CText fontType="body1" style={{ color: theme.primary, textDecorationLine: 'underline' }}>
          {actionText}
        </CText>
      </CustomPressable>
    </SafeAreaView>
  );
};

export default Footer;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: Spacing.regular,
  },
});
