import { StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PrivateStackScreenProps } from '../../app/navigation/types';
import { useFirestore } from '../../app/providers/firestore';
import { View as RootView } from '../../components/Themed';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { useMediaQuery } from 'react-responsive';
import Config from '../../res/constants/Config';
import { IOrder } from '../../models/IOrder';
import collections from '../../utils/collections';
import { ITable } from '../../models/ITable';
import { IMeta } from '../../models/IMeta';
import { useFocusEffect } from '@react-navigation/native';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import EditItemSideMenuRefactor from '../../components/side_menu/EditItemSideMenuRefactor';
import OrderDetailComponentRefactor from '../../components/orders/OrderDetailComponentRefactor';
import DepartmentPicker from '../../components/departments/DepartmentPicker';
import { useAppSelector } from '../../hooks/hooks';
import { IMenu, ISuggestion } from '../../models/IMenu';

const OrdersScreenRefactor = ({ navigation, route }: PrivateStackScreenProps<'Orders'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  //#region STATES
  const [order, setOrder] = useState<IOrder | undefined>(undefined);
  const [tables, setTables] = useState<ITable[]>([]);
  const [table, setTable] = useState<ITable | undefined>(undefined);
  //#endregion
  //#region HOOKS
  const { orders, handleGetDocument, handleDataCU, currentUser, handleListenCollectionChange } = useFirestore();
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  const department: number = useAppSelector((state) => state.departments.selectedDepartment);

  //#endregion
  //#region EFFECTS
  useFocusEffect(
    useCallback(() => {
      if (currentUser) {
        const unsubscribe = handleListenCollectionChange(currentUser.uid, collections.table).onSnapshot((querySnapshot) => {
          const temporary: any[] = [];
          querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
            temporary.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });
          setTables(temporary);
        });

        return () => {
          unsubscribe();
        };
      }
    }, [currentUser])
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return <DepartmentPicker />;
      },
    });
  }, [navigation]);

  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];

  const filteredOrders = useMemo(() => {
    if (department === 0) {
      return orders;
    }

    return orders
      .filter((order: IOrder) => order.cartItems.some((item) => item.subCategory === department) && !order.meta.isCompleted)
      .map((order) => {
        const clonedOrder = { ...order };
        clonedOrder.cartItems = clonedOrder.cartItems.filter((item: { subCategory: number; meta: IMeta }) => {
          return department === 0 ? item.subCategory === department : item.subCategory === department && !item.meta.isCompleted;
        });
        return clonedOrder;
      })
      .filter((order) => order.cartItems.length > 0); // filter out orders with no cart items
  }, [orders, department]);

  useEffect(() => {
    setOrder(filteredOrders[0]);
  }, [filteredOrders]);

  const groupByTableKey = (list: IOrder[]): { [key: string]: IOrder[] } => {
    return list.reduce((grouped: any, item) => {
      (grouped[item.tableKey] = grouped[item.tableKey] || []).push(item);
      return grouped;
    }, {});
  };

  let groupedData = groupByTableKey(department === 0 ? orders : filteredOrders);

  const getTableName = (tableKey: string) => {
    const foundIndex = tables.findIndex((i) => i.key === tableKey);

    if (foundIndex > -1) {
      return tables[foundIndex].name;
    } else {
      return 'Table not found';
    }
  };

  // Converting to section list data
  let sectionListData = Object.keys(groupedData).map((key) => {
    return {
      title: { header: getTableName(key), key },
      data: groupedData[key],
    };
  });

  //#endregion
  //#region ACTIONS
  if (currentUser === undefined) {
    return null;
  }
  //#endregion
  //#region FUNCTIONS

  const handleItemClick = (item: IOrder) => {
    if (item.key)
      if (isTabletOrMobile || !Config.isWeb) {
        navigation.navigate('OrderDetail', { key: item.key, tableKey: item.tableKey });
      } else {
        setOrder(item);
        handleGetDocument(currentUser?.uid, item.tableKey, collections.table).then(setTable);
      }
  };

  const handleSectionTitleClick = (item: string) => {
    if (order && order.key)
      if (isTabletOrMobile || !Config.isWeb) {
        navigation.navigate('OrderDetail', { key: order.key, tableKey: item });
      }
  };

  const onSubmit = (duration?: number, isCompleted = false) => {
    if (department === 0) {
      if (order) {
        const meta: IMeta = { ...order.meta, modified: new Date().getTime(), isCompleted, isPending: false };

        const data = {
          ...order,
          meta,
          duration: duration || null,
        };

        handleDataCU(data, collections.orders).then(() => setOrder(undefined));
      }
    } else {
      const temporaryCartItems: IMenu[] = [];

      order?.cartItems.forEach((item) => {
        const meta: IMeta = { ...item.meta, modified: new Date().getTime(), isCompleted, isPending: false };
        const data = {
          ...item,
          meta,
        };

        temporaryCartItems.push(data);
      });

      const payload = {
        ...order,
        cartItems: temporaryCartItems,
      };
      console.log(payload);
    }
  };

  const onSubmitRef = (duration?: number, isCompleted = false) => {
    if (order) {
      let meta: IMeta = { ...order.meta };

      // Find the order to be updated in the orders array
      const orderToUpdate = orders.find((o) => o.key === order.key);

      if (!orderToUpdate) {
        return; // If the order to update is not found in the orders array, abort the function
      }

      // Create a new cartItems array for the order to update, where each item is updated if it matches the current department
      const updatedItems = orderToUpdate.cartItems.map((item: IMenu) => {
        if (item.subCategory === department) {
          item.meta.isCompleted = isCompleted;
          item.meta.isPending = false;
          item.meta.modified = new Date().getTime();
          // for now, let's just return the item as is
          return item;
        } else if (department === 0) {
          item.meta.isCompleted = isCompleted;
          item.meta.isPending = false;
          item.meta.modified = new Date().getTime();
          // for now, let's just return the item as is
          return item;
        }
        return item; // if the item's subCategory does not match the current department, leave it unchanged
      });

      if (department === 0) {
        meta = { ...meta, modified: new Date().getTime(), isCompleted, isPending: false };
      }

      const data = {
        ...orderToUpdate,
        cartItems: updatedItems,
        meta,
        duration: duration || null,
      };

      handleDataCU(data, collections.orders).then(() => setOrder(undefined));
    }
  };

  //#endregion
  return (
    <RootView
      style={[
        styles.root,
        {
          padding: !isTabletOrMobile && Config.isWeb ? Spacing.regular : 0,
          backgroundColor: theme.lightBackground,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: theme.lightSelectedItem,
          overflow: 'hidden',
          ...(!isTabletOrMobile && Config.isWeb && { borderRadius: Spacing.defaultRadius }),
        }}
      >
        <EditItemSideMenuRefactor
          emptyListMessage={translate('pages.orders.no_items').toString()}
          items={sectionListData}
          onPress={handleItemClick}
          onTitleClick={handleSectionTitleClick}
        />
      </View>

      {/* format(item.meta.created, 'EEE dd HH:mm') */}
      {!isTabletOrMobile && Config.isWeb && (
        <View
          style={{
            flex: 1,
            backgroundColor: theme.lightSelectedItem,
            justifyContent: 'space-between',
            ...(!isTabletOrMobile && Config.isWeb && { borderRadius: Spacing.defaultRadius }),
          }}
        >
          {order && table && <OrderDetailComponentRefactor {...{ order, table, department }} onSubmit={onSubmitRef} />}
        </View>
      )}
    </RootView>
  );
};

export default OrdersScreenRefactor;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    gap: Spacing.small,
  },
});
