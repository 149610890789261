import { StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useState } from 'react';
import { IGeneralItem } from '../../../../models/IGeneralItem';
import useColorScheme from '../../../../hooks/useColorScheme';
import Styles from '../../../../res/constants/Styles';
import { MATERIAL_COLORS } from '../../../../res/constants/Colors';
import Spacing from '../../../../res/constants/Spacing';
import SearchBar from '../../../search/SearchBar';
import LabelSelectoreListitem from './LabelSelectorListitem';

type MultipleSelectProps = {
  multiple: true;
  value?: any[];
  onChange: (value: IGeneralItem[]) => void;
};

type SingleSelectProps = {
  multiple?: false;
  value?: IGeneralItem;
  onChange: (value: IGeneralItem | undefined) => void;
};

type Props = {
  options: IGeneralItem[];
  enableSearch?: boolean;
} & (SingleSelectProps | MultipleSelectProps);

const LabelSelector: FC<Props> = ({ multiple, value, onChange, options, enableSearch = true }) => {
  // #region STATES
  const [query, setQuery] = useState<string>('');
  // #endregion
  // #region CUSTOM HOOKS
  const colorScheme = useColorScheme();
  // #endregion
  // #region FUNCTIONS
  const filteredOptions = options.filter((option) => option.title.toLowerCase().includes(query.toLowerCase()));

  const selectOption = useCallback(
    (option: IGeneralItem) => {
      if (multiple) {
        if (value) {
          const optionIndex = value.findIndex((o) => JSON.stringify(o) === JSON.stringify(option));
          if (optionIndex > -1) {
            const newValue = [...value];
            newValue.splice(optionIndex, 1);
            onChange(newValue);
          } else {
            onChange([...value, option]);
          }
        } else {
          onChange([option]); // If value is undefined, initialize it with the new option
        }
      } else {
        onChange(option);
      }
    },
    [value, multiple, onChange]
  );

  const isOptionSelected = useCallback(
    (option: IGeneralItem) => {
      if (value) {
        return multiple
          ? value.some((element) => JSON.stringify(element) === JSON.stringify(option))
          : JSON.stringify(option) === JSON.stringify(value);
      } else {
        return false;
      }
    },
    [value, multiple]
  );

  // #endregion
  // #region EFFECTS
  // #endregion

  return (
    <View style={styles.root}>
      {enableSearch && <SearchBar query={query} setQuery={setQuery} />}
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: Spacing.minimal,
          marginHorizontal: Spacing.medium,
        }}
      >
        {filteredOptions.map((item, index) => {
          return <LabelSelectoreListitem key={index} item={item} selectOption={selectOption} isOptionSelected={isOptionSelected} />;
        })}
      </View>
    </View>
  );
};

export default LabelSelector;

const styles = StyleSheet.create({
  root: {
    backgroundColor: MATERIAL_COLORS.white,
    borderRadius: Spacing.small,
    borderColor: 'transparent',
    borderWidth: 1,
    flex: 1,
  },
});
