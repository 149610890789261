import { StyleSheet, TouchableOpacity } from 'react-native';
import React, { FC, useEffect, useState } from 'react';
import { Chip, Text } from 'react-native-paper';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { useFirestore } from '../../app/providers/firestore';
import collections from '../../utils/collections';
import Spacing from '../../res/constants/Spacing';
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface Props {
  item: any;
  onRemoveIngredient: () => void;
  onRestoreIngredient: () => void;
}
const ChipItem: FC<Props> = ({ item, onRemoveIngredient, onRestoreIngredient }) => {
  //#region STATES
  const [ingredient, setIngredient] = useState<any>(undefined);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { locale } = useLocalization();
  const { handleGetTranslation } = useFirestore();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    handleGetTranslation(item.id, collections.ingredients).then(setIngredient);
  }, [item]);
  //#endregion
  if (ingredient === undefined) {
    return null;
  } else
    return (
      <TouchableOpacity
        activeOpacity={1}
        onPress={onRestoreIngredient}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: item.removedByCustomer ? MATERIAL_COLORS.grey_100 : MATERIAL_COLORS.light_blue_100,
          borderRadius: Spacing.big,
        }}
      >
        <Text style={{ marginStart: Spacing.regular, flex: 1 }} variant="labelLarge">
          {ingredient ? `${ingredient.emoji} ${ingredient.name[locale]}` : 'Translation missing'}
        </Text>

        <MaterialCommunityIcons name="close" size={15} onPress={onRemoveIngredient} style={{ margin: Spacing.small }} />
      </TouchableOpacity>
      // <Chip
      //   textStyle={{ color: theme.text }}
      //   onClose={onRemoveIngredient}
      //   onPress={onRestoreIngredient}
      //   style={{ backgroundColor: item.removedByCustomer ? MATERIAL_COLORS.grey_100 : MATERIAL_COLORS.light_blue_100 }}
      // >
      //   {ingredient ? `${ingredient.emoji} ${ingredient.name[locale]}` : 'Translation missing'}
      // </Chip>
    );
};

export default ChipItem;

const styles = StyleSheet.create({ root: {} });
