import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { initializeAuth } from 'firebase/auth';
import { getReactNativePersistence } from 'firebase/auth/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import 'firebase/compat/functions';
import { getMessaging, isSupported } from 'firebase/messaging';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
  WEB_FIREBASE_API_KEY,
  WEB_FIREBASE_AUTH_DOMAIN,
  WEB_FIREBASE_PROJECT_ID,
  WEB_FIREBASE_STORAGE_BUCKET,
  WEB_FIREBASE_MESSAGING_SENDER_ID,
  WEB_FIREBASE_APP_ID,
  WEB_FIREBASE_MEASUREMENT_ID,
} from '@env';
import { FirebaseApp } from '@firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const firebaseConfig = {
  apiKey: WEB_FIREBASE_API_KEY,
  authDomain: WEB_FIREBASE_AUTH_DOMAIN,
  projectId: WEB_FIREBASE_PROJECT_ID,
  storageBucket: WEB_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: WEB_FIREBASE_MESSAGING_SENDER_ID,
  appId: WEB_FIREBASE_APP_ID,
  measurementId: WEB_FIREBASE_MEASUREMENT_ID,
};

let app: firebase.app.App | FirebaseApp | undefined;

if (firebase.apps.length === 0) {
  app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();
}

initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
});
const messaging = async () => (await isSupported()) && getMessaging(app);

const firestore = getFirestore(app);

const auth = getAuth(app);

const storage = getStorage(app);

export { firebase, messaging, auth, firestore, storage };
