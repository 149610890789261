import { IGeneralItem } from '../../models/IGeneralItem';

export const ProfileNewItemOptionMenuBackOfficeUser: IGeneralItem[] = [
  { id: 0, title: 'Staff', icon: 'People' },
  { id: 1, title: 'Menu', icon: 'Paper' },
  { id: 2, title: 'Table', icon: 'Folder' },
  { id: 3, title: 'Sponsors', icon: 'Work' },
  { id: 4, title: 'Translations', icon: 'Document' },
];

export const ProfileNewItemOptionMenu: IGeneralItem[] = [
  { id: 0, title: 'Staff', icon: 'People' },
  { id: 1, title: 'Menu', icon: 'Paper' },
  { id: 2, title: 'Table', icon: 'Folder' },
];

export const ProfileSettingOptionMenu: IGeneralItem[] = [
  { id: 0, title: 'Settings', icon: 'Setting' },
  { id: 1, title: 'Account', icon: 'Work' },
  { id: 2, title: 'Log out', icon: 'Logout' },
];
