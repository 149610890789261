import React from 'react';
import * as IconPack from '../../../assets/icons';
import { IconName } from './types';
import { Props } from './withIcon';

export interface IconProps {
  primaryColor?: string;
  secondaryColor?: string;
  strokeWidth?: string;
  opacity?: string;
  set?: 'bold' | 'bulk' | 'light' | 'broken' | 'two-tone' | 'curved' | undefined;
  size?: number | 'small' | 'medium' | 'large' | 'xlarge' | undefined;
  name: IconName;
}

const Iconly: React.FC<IconProps> = ({ primaryColor, secondaryColor, strokeWidth, opacity, set, size, name, ...props }) => {
  const Icon = icons[name];

  if (!Icon) {
    throw new Error(`Icon '${name}' doesn't exists in react-iconly`);
  }

  return (
    <Icon primaryColor={primaryColor} secondaryColor={secondaryColor} strokeWidth={strokeWidth} opacity={opacity} set={set} size={size} {...props} />
  );
};

export default Iconly;

interface IconObject {
  Search: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Folder: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Wallet: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Graph: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Bookmark: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Category: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Home: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Notification: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Chat: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Heart: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Heart2: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Paper: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  PaperPlus: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  PaperNegative: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  PaperFail: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  PaperDownload: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  PaperUpload: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Send: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Password: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Swap: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Work: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Scan: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Activity: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Calendar: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Message: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Video: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Plus: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Chart: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Game: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Bag: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Bag2: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  TicketStar: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  MoreCircle: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  MoreSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Discount: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Buy: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  InfoCircle: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  InfoSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Danger: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  CloseSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  TickSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Discovery: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Location: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Document: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Setting: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  TimeSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  TimeCircle: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  VolumeUp: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  VolumeDown: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  VolumeOff: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Star: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Ticket: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Camera: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  User: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  AddUser: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  TwoUsers: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  People: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Login: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Logout: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Download: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Upload: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Voice: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Voice2: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Delete: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Edit: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  EditSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Play: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ShieldDone: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ShieldFail: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Show: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Hide: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Filter: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Filter2: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Image: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Image2: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Call: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Calling: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  CallMissed: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  CallSilent: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Lock: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  Unlock: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronDownCircle: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronRightCircle: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronUpCircle: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronLeftCircle: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronDown: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronRight: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronUp: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ChevronLeft: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowDownSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowRightSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowUpSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowLeftSquare: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowDown: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowRight: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowUp: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  ArrowLeft: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  CaretRight: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  CaretDown: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  CaretUp: React.MemoExoticComponent<(props: Props) => JSX.Element>;
  CaretLeft: React.MemoExoticComponent<(props: Props) => JSX.Element>;
}

const icons: IconObject = {
  Search: IconPack.Search,
  Folder: IconPack.Folder,
  Wallet: IconPack.Wallet,
  Graph: IconPack.Graph,
  Bookmark: IconPack.Bookmark,
  Category: IconPack.Category,
  Home: IconPack.Home,
  Notification: IconPack.Notification,
  Chat: IconPack.Chat,
  Heart: IconPack.Heart,
  Heart2: IconPack.Heart2,
  Paper: IconPack.Paper,
  PaperPlus: IconPack.PaperPlus,
  PaperNegative: IconPack.PaperNegative,
  PaperFail: IconPack.PaperFail,
  PaperDownload: IconPack.PaperDownload,
  PaperUpload: IconPack.PaperUpload,
  Send: IconPack.Send,
  Password: IconPack.Password,
  Swap: IconPack.Swap,
  Work: IconPack.Work,
  Scan: IconPack.Scan,
  Activity: IconPack.Activity,
  Calendar: IconPack.Calendar,
  Message: IconPack.Message,
  Video: IconPack.Video,
  Plus: IconPack.Plus,
  Chart: IconPack.Chart,
  Game: IconPack.Game,
  Bag: IconPack.Bag,
  Bag2: IconPack.Bag2,
  TicketStar: IconPack.TicketStar,
  MoreCircle: IconPack.MoreCircle,
  MoreSquare: IconPack.MoreSquare,
  Discount: IconPack.Discount,
  Buy: IconPack.Buy,
  InfoCircle: IconPack.InfoCircle,
  InfoSquare: IconPack.InfoSquare,
  Danger: IconPack.Danger,
  CloseSquare: IconPack.CloseSquare,
  TickSquare: IconPack.TickSquare,
  Discovery: IconPack.Discovery,
  Location: IconPack.Location,
  Document: IconPack.Document,
  Setting: IconPack.Setting,
  TimeSquare: IconPack.TimeSquare,
  TimeCircle: IconPack.TimeCircle,
  VolumeUp: IconPack.VolumeUp,
  VolumeDown: IconPack.VolumeDown,
  VolumeOff: IconPack.VolumeOff,
  Star: IconPack.Star,
  Ticket: IconPack.Ticket,
  Camera: IconPack.Camera,
  User: IconPack.User,
  AddUser: IconPack.AddUser,
  TwoUsers: IconPack.TwoUsers,
  People: IconPack.People,
  Login: IconPack.Login,
  Logout: IconPack.Logout,
  Download: IconPack.Download,
  Upload: IconPack.Upload,
  Voice: IconPack.Voice,
  Voice2: IconPack.Voice2,
  Delete: IconPack.Delete,
  Edit: IconPack.Edit,
  EditSquare: IconPack.EditSquare,
  Play: IconPack.Play,
  ShieldDone: IconPack.ShieldDone,
  ShieldFail: IconPack.ShieldFail,
  Show: IconPack.Show,
  Hide: IconPack.Hide,
  Filter: IconPack.Filter,
  Filter2: IconPack.Filter2,
  Image: IconPack.Image,
  Image2: IconPack.Image2,
  Call: IconPack.Call,
  Calling: IconPack.Calling,
  CallMissed: IconPack.CallMissed,
  CallSilent: IconPack.CallSilent,
  Lock: IconPack.Lock,
  Unlock: IconPack.Unlock,
  ChevronDownCircle: IconPack.ChevronDownCircle,
  ChevronRightCircle: IconPack.ChevronRightCircle,
  ChevronUpCircle: IconPack.ChevronUpCircle,
  ChevronLeftCircle: IconPack.ChevronLeftCircle,
  ChevronDown: IconPack.ChevronDown,
  ChevronRight: IconPack.ChevronRight,
  ChevronUp: IconPack.ChevronUp,
  ChevronLeft: IconPack.ChevronLeft,
  ArrowDownSquare: IconPack.ArrowDownSquare,
  ArrowRightSquare: IconPack.ArrowRightSquare,
  ArrowUpSquare: IconPack.ArrowUpSquare,
  ArrowLeftSquare: IconPack.ArrowLeftSquare,
  ArrowDown: IconPack.ArrowDown,
  ArrowRight: IconPack.ArrowRight,
  ArrowUp: IconPack.ArrowUp,
  ArrowLeft: IconPack.ArrowLeft,
  CaretRight: IconPack.CaretRight,
  CaretDown: IconPack.CaretDown,
  CaretUp: IconPack.CaretUp,
  CaretLeft: IconPack.CaretLeft,
};
