import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
};

export const DEFAULT_ITEM_HEIGHT = height * 0.06;
export const DEFAULT_ITEM_HEIGHT_2 = 40;

export const USER_INFO_CARD = height * 0.3;
export const DEFAULT_GAP = 2;

export const RIGHT_SIDE_BAR_WIDTH = width * 0.3;
