import { StyleSheet, View } from 'react-native';
import React, { FC, memo } from 'react';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import Spacing from '../../res/constants/Spacing';
import CText from '../CText';
import CustomPressable from '../pressable/CustomPressable';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

interface Props {
  item: any;
  selected: boolean;
  onPress: () => void;
}
const IngredientItem: FC<Props> = ({ item, selected, onPress }) => {
  const { locale } = useLocalization();
  return (
    <View
      style={{
        backgroundColor: selected ? MATERIAL_COLORS.light_blue_100 : MATERIAL_COLORS.grey_100,
        margin: Spacing.minimal,
        borderRadius: Spacing.defaultRadius,
        overflow: 'hidden',
      }}
    >
      <CustomPressable style={{ padding: Spacing.small }} onPress={onPress}>
        <CText fontType="caption">{`${item.emoji} ${item.name[locale]}`}</CText>
      </CustomPressable>
    </View>
  );
};

export default memo(IngredientItem);

const styles = StyleSheet.create({ root: {} });
