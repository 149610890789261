import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Text } from 'react-native-paper';

interface Props {
  text?: string;
}
const EmptyListComponent: FC<Props> = ({ text }) => {
  // #region STATES
  // #endregion
  // #region CUSTOM HOOKS
  const colorScheme = useColorScheme();
  // #endregion
  // #region FUNCTIONS
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  const theme = Styles[colorScheme];
  // #endregion
  return (
    <View style={styles.root}>
      <Text style={{ color: theme.text }} variant="titleMedium">
        {text}
      </Text>
    </View>
  );
};

export default EmptyListComponent;

const styles = StyleSheet.create({ root: { flexGrow: 1, alignItems: 'center', justifyContent: 'center' } });
