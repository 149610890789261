import { StyleSheet } from 'react-native';
import React from 'react';
import { View, ViewProps } from '../Themed';
import { useMediaQuery } from 'react-responsive';
import Config from '../../res/constants/Config';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { FAB, useTheme } from 'react-native-paper';

export interface LeftPanelProps extends ViewProps {
  children?: React.ReactNode;
  onFabPress: () => void;
  showFab: boolean;
}

export const LeftPanelRefactor: React.FC<LeftPanelProps> = ({ children, onFabPress, showFab, style, ...props }) => {
  //#region HOOKS
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 }) || !Config.isWeb;
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  const paperTheme = useTheme();
  //#endregion

  return (
    <View
      {...props}
      style={[isTabletOrMobile ? styles.tabletOrMobile : styles.web, styles.root, style, { backgroundColor: theme.lightSelectedItem }]}
    >
      {children}

      {showFab && (
        <FAB
          theme={{ colors: { primaryContainer: paperTheme.colors.primary } }}
          icon="plus"
          color={theme.onPrimary}
          style={[styles.fab]}
          onPress={onFabPress}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    overflow: 'hidden',
  },
  web: { borderRadius: Spacing.defaultRadius },
  tabletOrMobile: { borderRadius: 0 },
  fab: {
    position: 'absolute',
    margin: Spacing.big,
    right: 0,
    bottom: 0,
  },
});
