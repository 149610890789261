const AppImages = {
  background_on_boarding: require('./on_board_background.png'),
  doddle_message: require('./doddle_message.png'),
  table_cloth: require('./table_cloth.png'),
  al: require('./flags/al.svg'),
  it: require('./flags/it.svg'),
  ro: require('./flags/ro.svg'),
  us: require('./flags/us.svg'),
  el: require('./flags/gr.svg'),
  sr: require('./flags/rs.svg'),
  de: require('./flags/de.svg'),
  pl: require('./flags/pl.svg'),
  ru: require('./flags/ru.svg'),
  fr: require('./flags/fr.svg'),
  ua: require('./flags/ua.svg'),
  es: require('./flags/es.svg'),
  me: require('./flags/me.svg'),
  hr: require('./flags/hr.svg'),
  main_icon: require('./main_icon.svg'),
  main_icon2: require('./main_icon2.svg'),
  gradient_background: require('./gradient_background.png'),
  chef: require('./chef.png'),
  qr_banner: require('./qr_banner.jpeg'),
  food_dodle: require('./food_dodle.jpg'),
  celery: require('./allergens/celery.svg'),
  crustaceans: require('./allergens/crustaceans.svg'),
  fish: require('./allergens/fish.svg'),
  gluten: require('./allergens/gluten.svg'),
  huevo: require('./allergens/huevo.svg'),
  lupins: require('./allergens/lupins.svg'),
  milk: require('./allergens/milk.svg'),
  mustard: require('./allergens/mustard.svg'),
  nuts: require('./allergens/nuts.svg'),
  peanuts: require('./allergens/peanuts.svg'),
  sesame: require('./allergens/sesame.svg'),
  shellfish: require('./allergens/shellfish.svg'),
  soy: require('./allergens/soy.svg'),
  sulfites: require('./allergens/sulfites.svg'),
  testImage: require('./mockImage.jpeg'),
};

export { AppImages };
