import { StyleSheet } from 'react-native';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { View, ViewProps } from '../Themed';
import Config from '../../res/constants/Config';

interface Props extends ViewProps {}

const LeftPanel: FC<Props> = ({ ...props }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 }) || !Config.isWeb;

  if (isTabletOrMobile) {
    return null;
  }

  return (
    <View {...props} style={[props.style, styles.root]}>
      {props.children}
    </View>
  );
};

export default LeftPanel;

const styles = StyleSheet.create({ root: { flex: 1 } });
