import { createSlice } from '@reduxjs/toolkit';
import { IMenu } from '../../../models/IMenu';
import { RootState } from '../../store/store';

interface InitialState {
  items: IMenu[];
}

const initialState: InitialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addCartItem: (state, action) => {
      state.items.push({ ...action.payload, id: new Date().getTime() });
    },
    removeCartItem: (state, action) => {
      const exactIndex = state.items.findIndex((item) => item.key === action.payload.key && item.price === action.payload.price);
      const indexToRemove = state.items.findIndex((item) => item.key === action.payload.key);

      let copy = [...state.items];

      if (exactIndex !== -1) {
        copy.splice(exactIndex, 1);
      } else {
        if (indexToRemove !== -1) {
          copy.splice(indexToRemove, 1);
        }
      }

      state.items = copy;
    },
    clearCart: (state) => {
      state.items = [];
    },
  },
});

export const { addCartItem, removeCartItem, clearCart } = cartSlice.actions;
export const selectCartItemsWithId = (state: RootState, key: string) => state.cart.items.filter((item) => item.key === key);
export const selectCartTotal = (state: RootState) => state.cart.items.reduce((total, item) => (total += item.price), 0);
export default cartSlice.reducer;
