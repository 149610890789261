import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { useMediaQuery } from 'react-responsive';
import Config from '../../res/constants/Config';
import Spacing from '../../res/constants/Spacing';
import { ViewProps, View as RootView } from '../Themed';

interface Props extends ViewProps {}

const RightPanelRefactor: FC<Props> = ({ children, style, ...props }) => {
  //#region HOOKS
  const colorScheme = useColorScheme();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 }) || !Config.isWeb;
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  if (isTabletOrMobile) {
    return null;
  } else
    return (
      <RootView style={[styles.root, style, { backgroundColor: theme.lightSelectedItem }]}>
        <View style={{ flex: 1 }}>{children}</View>
      </RootView>
    );
};

export default RightPanelRefactor;

const styles = StyleSheet.create({
  root: {
    width: '40%',
    borderRadius: Spacing.defaultRadius,
    padding: Spacing.regular,
    justifyContent: 'space-between',
  },
});
