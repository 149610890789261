import { StyleSheet } from 'react-native';
import React, { FC, memo } from 'react';
import { View as RootView } from '../../components/Themed';
import { DEFAULT_ITEM_HEIGHT } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import CustomPressable from '../pressable/CustomPressable';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Text } from 'react-native-paper';
import { format } from 'date-fns';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

interface Props {
  item: any;
  onPress: (item: any) => void;
  selected: boolean;
}
const AllPurposeItemList: FC<Props> = ({ item, onPress, selected }) => {
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  const { locale, translate } = useLocalization();
  return (
    <RootView style={styles.root}>
      <CustomPressable onPress={() => onPress(item)} style={[styles.button, { backgroundColor: selected ? theme.lightSelectedItem : undefined }]}>
        <Text style={{ flex: 1, color: theme.text }} variant="bodyMedium">
          {item.name ? (typeof item.name === 'string' ? item.name : item.name[locale]) : `${translate('general.items')}: ${item.cartItems?.length}`}
        </Text>

        {item && item.meta && item.meta.created && (
          <Text style={{ color: theme.text }} variant="bodyMedium">
            {format(item.meta.created, 'dd MMM, yyyy')}
          </Text>
        )}
      </CustomPressable>
    </RootView>
  );
};

export default memo(AllPurposeItemList);

const styles = StyleSheet.create({
  root: { minHeight: DEFAULT_ITEM_HEIGHT, overflow: 'hidden' },
  button: {
    paddingHorizontal: Spacing.regular,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
});
