import { StyleSheet, View } from 'react-native';
import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, Portal, Text, TextInput } from 'react-native-paper';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';
import { useMediaQuery } from 'react-responsive';
import Spacing from '../../../res/constants/Spacing';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { FormType } from '../../../utils/enums';
import { IPrice } from '../../../models/IPrice';
import SelectableList from '../../general/selectable/SelectableList';
import { IGeneralItem } from '../../../models/IGeneralItem';
import { MATERIAL_COLORS } from '../../../res/constants/Colors';
import useColorScheme from '../../../hooks/useColorScheme';
import Styles from '../../../res/constants/Styles';

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  onAddNewItem: (value: IPrice) => void;
  onDeleteItem: (value: IPrice) => void;
  value?: IPrice;
  type: FormType;
}
const TITLE_OPTIONS = [
  { id: 0, title: 'Small' },
  { id: 1, title: 'Medium' },
  { id: 2, title: 'Large' },
];
const PriceDialog: FC<Props> = ({ visible, setVisible, onAddNewItem, onDeleteItem, value, type }) => {
  // #region STATES
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [selectedTitle, setSelectedTitle] = useState<IGeneralItem | undefined>(value);
  // #endregion
  // #region CUSTOM HOOKS
  const { translate } = useLocalization();
  const colorScheme = useColorScheme();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ values: value });
  // #endregion
  // #region FUNCTIONS
  const hideDialog = () => setVisible(false);

  const handleGetTitle = (title: string) => {
    return TITLE_OPTIONS.find((i) => i.title === title);
  };
  const onSubmit = (params: FieldValues) => {
    let id = params.id;

    if (id === undefined) {
      // Use current timestamp in milliseconds as id
      id = new Date().getTime().toString();
    }

    onAddNewItem({ id, title: params.title, value: Number(params.value) });
    reset({ id: undefined, title: '', value: '' }, { keepValues: false });
    hideDialog();
  };
  // #endregion
  // #region EFFECTS
  useEffect(() => {
    if (!value) {
      reset({ id: undefined, title: '', value: '' }, { keepValues: false });
    }
  }, [visible]);
  // #endregion
  // #region MEMBERS
  const theme = Styles[colorScheme];
  // #endregion
  return (
    <Portal>
      <Dialog style={{ alignSelf: 'center', width: isTabletOrMobile ? '95%' : '40%' }} visible={visible} onDismiss={() => setVisible(false)}>
        <Dialog.Title>{'New price'}</Dialog.Title>
        <Dialog.Content>
          <Text variant="bodyLarge">{translate('general.price_dialog_message')}</Text>
          <View style={{ marginTop: Spacing.small }}>
            <Controller
              rules={{ required: true }}
              control={control}
              name={'title'}
              render={({ field }) => (
                <SelectableList
                  error={errors.title}
                  options={TITLE_OPTIONS}
                  value={handleGetTitle(field.value)}
                  placeHolder={translate('general.name').toString()}
                  multiple={false}
                  onChange={(value) => {
                    field.onChange(value?.title);
                  }}
                />
              )}
            />
            <Controller
              rules={{ required: true }}
              control={control}
              name={'value'}
              render={({ field }) => (
                <TextInput
                  theme={{ colors: { onSurfaceVariant: theme.placeholder } }}
                  error={errors.value !== undefined}
                  {...field}
                  outlineStyle={{
                    backgroundColor: MATERIAL_COLORS.white,
                    borderRadius: Spacing.defaultRadius,
                    borderColor: errors.value === undefined ? 'transparent' : MATERIAL_COLORS.red,
                  }}
                  mode="outlined"
                  placeholder="Price"
                  style={{ flex: 1 }}
                  keyboardType="number-pad"
                />
              )}
            />
          </View>
        </Dialog.Content>
        <Dialog.Actions>
          {type === FormType.EDIT && (
            <Button
              style={{ width: 70 }}
              mode="contained"
              onPress={() => {
                if (value) onDeleteItem(value);
              }}
            >
              Delete
            </Button>
          )}
          <Button style={{ width: 70 }} mode="contained" onPress={handleSubmit(onSubmit)}>
            Ok
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default PriceDialog;

const styles = StyleSheet.create({ root: {} });
