import { AppImages } from '../../../assets/images';

export default [
  {
    id: 0,
    locale: 'al',
    flag: AppImages.al,
    phonePrefix: '+355',
    country: 'Albania',
    language: 'albanian',
  },
  {
    id: 1,
    locale: 'el',
    flag: AppImages.el,
    phonePrefix: '+30',
    country: 'Greece',
    language: 'greek',
  },
  {
    id: 2,
    locale: 'en',
    flag: AppImages.us,
    phonePrefix: '+1',
    country: 'US',
    language: 'english',
  },
  {
    id: 3,
    locale: 'it',
    flag: AppImages.it,
    phonePrefix: '+39',
    country: 'Italy',
    language: 'italian',
  },
  {
    id: 4,
    locale: 'ro',
    flag: AppImages.ro,
    phonePrefix: '+40',
    country: 'Romania',
    language: 'romanian',
  },
  {
    id: 5,
    locale: 'rs',
    flag: AppImages.sr,
    phonePrefix: '+40',
    country: 'Serbian',
    language: 'serbian',
  },
  {
    id: 6,
    locale: 'de',
    flag: AppImages.de,
    phonePrefix: '+40',
    country: 'German',
    language: 'german',
  },
  {
    id: 7,
    locale: 'pl',
    flag: AppImages.pl,
    phonePrefix: '+40',
    country: 'Polish',
    language: 'polish',
  },
  {
    id: 8,
    locale: 'ru',
    flag: AppImages.ru,
    phonePrefix: '+40',
    country: 'Russian',
    language: 'russian',
  },
  {
    id: 9,
    locale: 'fr',
    flag: AppImages.fr,
    phonePrefix: '+40',
    country: 'France',
    language: 'french',
  },
  {
    id: 10,
    locale: 'ua',
    flag: AppImages.ua,
    phonePrefix: '+40',
    country: 'Ukraine',
    language: 'ukrainian',
  },
  {
    id: 11,
    locale: 'es',
    flag: AppImages.es,
    phonePrefix: '+40',
    country: 'Spain',
    language: 'spanish',
  },
  {
    id: 12,
    locale: 'me',
    flag: AppImages.me,
    phonePrefix: '+40',
    country: 'Montenegro',
    language: 'montenegrin',
  },
  {
    id: 13,
    locale: 'hr',
    flag: AppImages.hr,
    phonePrefix: '+40',
    country: 'Croatia',
    language: 'croatian',
  },
];
