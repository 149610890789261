import { FlatList, Image, StyleSheet, View } from 'react-native';
import React, { FC, useState } from 'react';
import { Menu, Text } from 'react-native-paper';
import DummyCountries from '../../res/data/DummyCountries';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import CustomPressable from '../pressable/CustomPressable';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';

interface Props {
  showText?: boolean;
}
const CountryPicker: FC<Props> = ({ showText = true }) => {
  //#region HOOKS
  const { locale, setLanguage } = useLocalization();
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  //#endregion
  //#region STATES
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  //#endregion
  //#region FUNCTIONS
  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);
  const getCountry = () => {
    const foundIndex = DummyCountries.findIndex((i) => i.locale === locale);
    if (foundIndex > -1) {
      return DummyCountries[foundIndex];
    } else {
      return DummyCountries[0];
    }
  };

  //#endregion
  return (
    <View style={styles.root}>
      <Menu
        contentStyle={{ backgroundColor: 'white', maxHeight: 350 }}
        onDismiss={closeMenu}
        visible={menuVisible}
        anchor={
          <CustomPressable style={{ flexDirection: 'row', alignItems: 'center' }} onPress={openMenu}>
            {showText && <Text variant="bodyMedium">{getCountry().country}</Text>}
            <Image
              source={getCountry().flag}
              style={{ height: 24, width: 24, marginStart: Spacing.regular, borderRadius: 12 }}
              resizeMode="contain"
            />
          </CustomPressable>
        }
      >
        <FlatList
          data={DummyCountries}
          style={{ height: 350 }}
          renderItem={({ item, index }) => (
            <CustomPressable
              key={index}
              style={{ flexDirection: 'row', alignItems: 'center', padding: Spacing.small }}
              onPress={() => {
                setLanguage(item.locale);
                closeMenu();
              }}
            >
              <Text variant="bodyMedium" style={{ flex: 1, color: theme.text }}>
                {item.country}
              </Text>
              <Image source={item.flag} style={{ height: 24, width: 24, marginStart: Spacing.regular, borderRadius: 12 }} resizeMode="contain" />
            </CustomPressable>
          )}
        />
      </Menu>
    </View>
  );
};

export default CountryPicker;

const styles = StyleSheet.create({
  root: {},
});
