import { StyleSheet } from 'react-native';
import React, { FC } from 'react';
import CustomPressable from './CustomPressable';

import { FontAwesome } from '@expo/vector-icons';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import Iconly from '../iconly/mapIcons';
import { DEFAULT_ITEM_HEIGHT, DEFAULT_ITEM_HEIGHT_2 } from '../../res/constants/Layout';

interface Props {
  iconName?: any;
  isIconly?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}
const GeneralIcon: FC<Props> = ({ iconName, isIconly, disabled = true, onPress }) => {
  if (iconName) {
    return (
      <CustomPressable style={styles.root} disabled={disabled} onPress={onPress}>
        {isIconly ? (
          <Iconly name={iconName} primaryColor={MATERIAL_COLORS.blue_grey} size={20} />
        ) : (
          <FontAwesome name={iconName} color={MATERIAL_COLORS.blue_grey} size={20} />
        )}
      </CustomPressable>
    );
  } else return null;
};

export default GeneralIcon;

const styles = StyleSheet.create({
  root: {
    height: DEFAULT_ITEM_HEIGHT,
    width: DEFAULT_ITEM_HEIGHT_2,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
