import { StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useState } from 'react';
import { Menu, IconButton, Text, Divider, ActivityIndicator } from 'react-native-paper';
import Spacing from '../../../res/constants/Spacing';
import ProfilePicture from '../../images/ProfilePicture';
import useColorScheme from '../../../hooks/useColorScheme';
import Styles from '../../../res/constants/Styles';
import { IUser } from '../../../models/IUser';
import { useFocusEffect } from '@react-navigation/native';
import { useFirestore } from '../../../app/providers/firestore';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setSelectedUser } from '../../../app/features/back_office/backOfficeSlice';
import Options from './filter/Options';
import { UserRole } from '../../../utils/enums';
import { MATERIAL_COLORS } from '../../../res/constants/Colors';

const options = [
  {
    id: 0,
    icon: 'account-eye',
    toolTip: 'Inactive accounts',
    userRole: 0,
  },
  {
    id: 1,
    icon: 'account-check',
    toolTip: 'Active accounts',
    userRole: 2,
  },
  {
    id: 2,
    icon: 'account-cog',
    toolTip: 'Backoffice accounts',
    userRole: 1,
  },
];
interface Props {
  onBackClick?: () => void;
}
const BusinessPicker: FC<Props> = ({ onBackClick }) => {
  //#region STATES
  const [users, setUsers] = useState<IUser[]>([]);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const currentSelectedUser: any = useAppSelector((state) => state.backOffice.selectedUser);
  const [currentSelectedItem, setCurrentSelectedItem] = useState<(typeof options)[0]>(options[1]);
  //#endregion
  //#region HOOKS
  const { translate } = useLocalization();
  const colorScheme = useColorScheme();
  const { currentUser, handleListenUsersChange } = useFirestore();
  const dispatch = useAppDispatch();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region EFFECTS
  useFocusEffect(
    useCallback(() => {
      if (currentUser) {
        setLoading(true);
        const users = handleListenUsersChange()
          .where('role', '==', currentSelectedItem.userRole)
          .onSnapshot((querySnapshot) => {
            const temporary: any[] = [];
            querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
              temporary.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });
            setUsers(temporary);
            setLoading(false);
          });
        return () => {
          users();
        };
      }
    }, [currentUser, currentSelectedItem])
  );
  //#endregion
  return (
    <View style={styles.root}>
      {onBackClick && <IconButton iconColor={theme.text} icon={'arrow-left'} onPress={onBackClick} />}

      <ProfilePicture size={35} hasRadius={true} source={currentSelectedUser?.photoURL} />
      <Text style={{ marginLeft: Spacing.regular, minWidth: 70 }} variant="titleMedium">
        {currentSelectedUser !== undefined
          ? currentSelectedUser.businessName
            ? currentSelectedUser.businessName
            : currentSelectedUser.email
          : translate('general.select_business')}
      </Text>

      <Menu
        contentStyle={{ backgroundColor: theme.background }}
        visible={menuVisible}
        onDismiss={() => {
          setMenuVisible(false);
        }}
        anchor={
          <IconButton
            iconColor={theme.text}
            icon="chevron-down"
            onPress={() => {
              setMenuVisible(true);
            }}
          />
        }
      >
        <Options {...{ currentSelectedItem, options, setCurrentSelectedItem }} />
        <Divider />
        {loading ? (
          <Menu.Item
            titleStyle={{ alignItems: 'center' }}
            style={{ alignItems: 'center', justifyContent: 'center' }}
            title={<ActivityIndicator size={'small'} />}
            contentStyle={{ alignItems: 'center' }}
          />
        ) : (
          <>
            {users.length > 0 ? (
              users.map((item, index) => (
                // FIXME: Pass this component in another file so we can fix unnecessary rerenders
                <Menu.Item
                  style={{
                    backgroundColor:
                      currentSelectedUser && currentSelectedUser.uid && item.uid === currentSelectedUser.uid
                        ? MATERIAL_COLORS.blue_grey_50
                        : undefined,
                  }}
                  key={index}
                  onPress={() => {
                    dispatch(setSelectedUser(item));
                    setMenuVisible(false);
                  }}
                  title={
                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: Spacing.medium }}>
                      <ProfilePicture size={25} hasRadius={true} source={item?.photoURL} />

                      <View>
                        <Text numberOfLines={1} variant="titleSmall">
                          {item.businessName !== undefined ? item.businessName : item.email}
                        </Text>
                        {item.role && (
                          <Text style={{ color: MATERIAL_COLORS.grey_500, textTransform: 'lowercase' }} variant="labelSmall">
                            {UserRole[item.role]}
                          </Text>
                        )}
                      </View>
                    </View>
                  }
                />
              ))
            ) : (
              <View style={{ padding: Spacing.regular, alignItems: 'center' }}>
                <Text>No {UserRole[currentSelectedItem.userRole]} users found</Text>
              </View>
            )}
          </>
        )}
      </Menu>
    </View>
  );
};

export default BusinessPicker;

const styles = StyleSheet.create({ root: { flexDirection: 'row', alignItems: 'center', paddingHorizontal: Spacing.regular } });
