import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import Spacing from '../../res/constants/Spacing';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { useMediaQuery } from 'react-responsive';
import CustomPressable from '../pressable/CustomPressable';
import { Divider, Text } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { DEFAULT_GAP } from '../../res/constants/Layout';

interface Props {
  onPress: (index: number) => void;
}
const MenuItems: FC<Props> = ({ onPress }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  //#region HOOKS
  const { translate } = useLocalization();
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region FUNCTIONS

  const renderButton = (name: React.ComponentProps<typeof MaterialCommunityIcons>['name'], title: string, index: number) => {
    return (
      <CustomPressable
        style={[
          styles.buttons,
          {
            backgroundColor: isTabletOrMobile ? theme.lightSelectedItem : undefined,
            marginHorizontal: isTabletOrMobile ? Spacing.regular : Spacing.small,
          },
        ]}
        onPress={() => onPress(index)}
      >
        {isTabletOrMobile && <MaterialCommunityIcons name={name} size={24} style={{ marginEnd: Spacing.medium }} />}
        <Text variant="titleSmall">{title}</Text>
      </CustomPressable>
    );
  };
  //#endregion
  return (
    <View style={[styles.root, { flexDirection: isTabletOrMobile ? 'column' : 'row', gap: DEFAULT_GAP }]}>
      {renderButton('login', translate('authentication.login').toString(), 0)}

      {/* <Button labelStyle={styles.headerLabels} textColor={MATERIAL_COLORS.blue_grey_800} mode="text">
              {translate('general.about_us')}
            </Button> */}
      {renderButton('phone', translate('general.contact').toString(), 2)}
      {/* {renderButton('file-document', translate('general.terms-and-conditions').toString(), 3)} */}
    </View>
  );
};

export default MenuItems;

const styles = StyleSheet.create({
  root: { paddingVertical: Spacing.regular, flex: 1 },
  headerLabels: {
    fontSize: 16,
  },
  buttons: {
    padding: Spacing.regular,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: Spacing.defaultRadius,
  },
});
