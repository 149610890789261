import { FlatList, StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import GeneralItem from './GeneralItem';
import { IGeneralItem } from '../../models/IGeneralItem';
import { DEFAULT_ITEM_HEIGHT } from '../../res/constants/Layout';
import CText from '../CText';
import SearchBar from '../search/SearchBar';

interface Props {
  selectedValue: any | any[];
  items: IGeneralItem[];
  onPress: (value: IGeneralItem) => void;
  height: number;
  hasSearch?: boolean;
}
const PAGE_SIZE = 5;

const GeneralList: FC<Props> = ({ selectedValue, items, onPress, height, hasSearch = false }) => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const startIndex = (page - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const [query, setQuery] = useState<string>('');
  const [data, setData] = useState<IGeneralItem[]>(items);

  const handleOnPress = useCallback((value: IGeneralItem) => onPress(value), []);

  // const handleLoadMore = () => {
  //   if (isLoading || startIndex >= items.length) {
  //     return;
  //   }

  //   setIsLoading(true);
  //   setPage(page + 1);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 500); // Simulate a delay in loading more data
  // };

  useEffect(() => {
    setData(items.slice(0, endIndex));
  }, [page]);

  const filteredData = items.filter((item: IGeneralItem) => item.title.toLowerCase().includes(query.toLowerCase()));

  const getItemLayout = (index: number) => ({
    length: DEFAULT_ITEM_HEIGHT - 2,
    offset: (DEFAULT_ITEM_HEIGHT - 2) * index,
    index,
  });

  return (
    <View style={{ height }}>
      {hasSearch && <SearchBar query={query} setQuery={setQuery} />}
      <FlatList
        windowSize={13}
        data={filteredData}
        initialNumToRender={6}
        contentContainerStyle={{ gap: 1, overflow: 'scroll' }}
        keyExtractor={(item) => item.title + item.id}
        getItemLayout={(_item, index) => getItemLayout(index)}
        renderItem={({ item, index }) => {
          let checked = false;
          if (Array.isArray(selectedValue)) {
            const foundIndex = selectedValue.findIndex((i) => i.key === item.id);
            checked = foundIndex > -1;
          } else {
            checked = selectedValue === item.id;
          }
          return <GeneralItem key={index} checked={checked} onPress={() => handleOnPress(item)} value={item.title} />;
        }}
        // onEndReached={handleLoadMore}
        // onEndReachedThreshold={0.5}
        // ListFooterComponent={
        //   isLoading ? (
        //     <View style={{ height: DEFAULT_ITEM_HEIGHT, justifyContent: 'center', alignItems: 'center' }}>
        //       <CText fontType="caption">Loading more...</CText>
        //     </View>
        //   ) : null
        // }
      />
    </View>
  );
};

export default GeneralList;

const styles = StyleSheet.create({ root: {} });
