import { StyleSheet, View, Image, ScrollView, FlatList } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { useFirestore } from '../../app/providers/firestore';
import { useMediaQuery } from 'react-responsive';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { View as RootView } from '../../components/Themed';
import LeftPanel from '../../components/responsive_containers/LeftPanel';
import RightPanel from '../../components/responsive_containers/RightPanel';
import EditItemSideMenu from '../../components/side_menu/EditItemSideMenu';
import Config from '../../res/constants/Config';
import { DEFAULT_GAP } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { FAB, IconButton, Text } from 'react-native-paper';
import { PrivateStackScreenProps } from '../../app/navigation/types';
import { FieldValues, useForm } from 'react-hook-form';
import CustomInput from '../../components/inputs/CustomInput';
import { IIngredient } from '../../models/domain/IIngredient';
import DummyCountries from '../../res/data/DummyCountries';
import useToggle from '../../hooks/useToggle';
import { useFocusEffect } from '@react-navigation/native';
import ConfirmDialog from '../../components/dialogs/confirm/ConfirmDialog';
import { FormType } from '../../utils/enums';
import { IMeta } from '../../models/IMeta';

const EditTranslationsScreen = ({ navigation, route }: PrivateStackScreenProps<'EditTranslations'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // #region STATES
  const [currentModel, setCurrentModel] = useState<IIngredient | undefined>(undefined);
  const [archivedView, toggleArchivedView] = useToggle(false);
  const [formType, setFormType] = useState<FormType>(FormType.NEW);
  const [ing, setIng] = useState<any[]>([]);
  const [confirmDialogVisibility, toggleConfirmDialogVisibility] = useToggle(false);
  const [tableView, toggleTableView] = useToggle(false);
  // #endregion
  // #region CUSTOM HOOKS
  const { handleGlobalDataCU, currentUser, handleListenGlobalCollectionChange } = useFirestore();
  const { translate, locale } = useLocalization();
  const colorScheme = useColorScheme();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{
    id?: number;
    key?: string;
    emoji: string;
    albanian: string;
    greek: string;
    english: string;
    italian: string;
    romanian: string;
    serbian: string;
    german: string;
    polish: string;
    russian: string;
    french: string;
    ukrainian: string;
    spanish: string;
    montenegrin: string;
    croatian: string;
  }>({
    values: {
      key: currentModel?.key,
      id: currentModel?.id,
      emoji: currentModel ? currentModel.emoji : '',
      albanian: currentModel ? currentModel.name.al : '',
      greek: currentModel ? currentModel.name.el : '',
      english: currentModel ? currentModel.name.en : '',
      italian: currentModel ? currentModel.name.it : '',
      romanian: currentModel ? currentModel.name.ro : '',
      serbian: currentModel ? currentModel.name.rs : '',
      german: currentModel ? currentModel.name.de : '',
      polish: currentModel ? currentModel.name.pl : '',
      russian: currentModel ? currentModel.name.ru : '',
      french: currentModel ? currentModel.name.fr : '',
      ukrainian: currentModel ? currentModel.name.ua : '',
      spanish: currentModel ? currentModel.name.es : '',
      montenegrin: currentModel ? currentModel.name.me : '',
      croatian: currentModel ? currentModel.name.hr : '',
    },
  });
  // #endregion
  // #region FUNCTIONS
  const handleGetTranslationDocument = (value: any) => {
    setFormType(FormType.EDIT);
    setCurrentModel(value);
  };

  const handleOnSubmit = (params: FieldValues) => {
    const {
      id,
      key,
      emoji,
      albanian,
      greek,
      english,
      italian,
      romanian,
      serbian,
      german,
      polish,
      russian,
      french,
      ukrainian,
      spanish,
      montenegrin,
      croatian,
    } = params;

    const meta: IMeta =
      formType === FormType.NEW ? { created: new Date().getTime(), isActive: true } : { ...currentModel?.meta, modified: new Date().getTime() };

    const model: IIngredient = {
      id: id || new Date().getTime(),
      key: key,
      emoji: emoji,
      meta,
      name: {
        al: albanian,
        el: greek,
        en: english,
        it: italian,
        ro: romanian,
        rs: serbian,
        de: german,
        pl: polish,
        ru: russian,
        fr: french,
        ua: ukrainian,
        es: spanish,
        me: montenegrin,
        hr: croatian,
      },
    };

    handleGlobalDataCU(model).then(() => {
      reset();
      setCurrentModel(undefined);
    });
  };

  const handleMoreMenuPress = (value: number) => {
    switch (value) {
      case 0:
        toggleArchivedView();
        break;
      default:
        break;
    }
  };

  // #endregion
  // #region EFFECTS
  useFocusEffect(
    useCallback(() => {
      if (currentUser) {
        const ingredients = handleListenGlobalCollectionChange()
          .where('meta.isActive', '==', !archivedView)
          .onSnapshot((querySnapshot) => {
            const temporary: any[] = [];
            querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
              temporary.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });
            setIng(temporary);
          });
        return () => {
          ingredients();
        };
      }
    }, [currentUser, archivedView])
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return (
          <View style={{ flexDirection: 'row', zIndex: 10 }}>
            {Number(formType) === FormType.EDIT && (
              <IconButton
                iconColor={theme.text}
                icon={archivedView ? 'archive-arrow-up-outline' : 'archive-arrow-down-outline'}
                onPress={() => toggleConfirmDialogVisibility(true)}
              />
            )}
          </View>
        );
      },
    });
  }, [locale, formType, archivedView]);

  // #endregion
  // #region MEMBERS
  const theme = Styles[colorScheme];
  // #endregion
  if (tableView) {
    return (
      <View style={{ flex: 1 }}>
        <FlatList
          data={ing}
          renderItem={({ item, index }) => (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ flex: 1 }}>{item.key}</Text>
              <Text style={{ flex: 1 }}>{item.name['al']}</Text>
              <Text style={{ flex: 1 }}>{item.name['al']}</Text>
              <Text style={{ flex: 1 }}>{item.name['al']}</Text>
              <Text style={{ flex: 1 }}>{item.name['al']}</Text>
            </View>
          )}
        />
      </View>
    );
  } else
    return (
      <RootView style={[styles.root, { padding: !isTabletOrMobile && Config.isWeb ? Spacing.regular : 0 }]}>
        <LeftPanel>
          <EditItemSideMenu
            isBackVisible={archivedView}
            menuItems={[{ id: 0, title: 'Archived', icon: 'archive-outline' }]}
            onMenuItemPress={handleMoreMenuPress}
            items={ing?.map((s: IIngredient) => ({
              id: s.id,
              key: s.key,
              ...s,
            }))}
            onPress={handleGetTranslationDocument}
            onBackPress={() => {
              toggleArchivedView();
              navigation.setParams({ type: FormType.NEW });
              setFormType(FormType.NEW);
              setCurrentModel(undefined);
            }}
          />
          {!archivedView && (
            <FAB
              // disabled={ingredients.length > 0}
              icon="plus"
              color={theme.onPrimary}
              style={[styles.fab, { backgroundColor: theme.primary }]}
              onPress={() => {
                // ingredients.forEach((item) => {
                //   const meta: IMeta = { created: new Date().getTime(), isActive: true };
                //   handleGlobalDataCU({ ...item, meta });
                // });
                navigation.setParams({ type: FormType.NEW });
                setFormType(FormType.NEW);
                setCurrentModel(undefined);
                // const ingredients = preparePayload(translations);
                // for (let i = 0; i < ingredients.length; i++) {
                //   handleGlobalDataCU(ingredients[i]);
                // }
              }}
            />
          )}
        </LeftPanel>

        <RightPanel onButtonClick={handleSubmit(handleOnSubmit)} buttonTitle={translate('general.save') as string}>
          <View
            style={{
              flex: 1,
              backgroundColor: theme.lightSelectedItem,
            }}
          >
            <ScrollView contentContainerStyle={{ flex: 1, margin: Spacing.regular, borderRadius: Spacing.defaultRadius, gap: DEFAULT_GAP }}>
              <CustomInput
                error={errors.emoji}
                required={true}
                control={control}
                name={'emoji'}
                placeholder={'Emoji'}
                iconProps={{ leftName: 'Star', isLeftIconly: true, showRight: false }}
              />
              {DummyCountries.map((item, index) => (
                <CustomInput
                  key={index}
                  error={
                    [
                      errors.albanian,
                      errors.greek,
                      errors.english,
                      errors.italian,
                      errors.romanian,
                      errors.serbian,
                      errors.german,
                      errors.polish,
                      errors.russian,
                      errors.french,
                      errors.ukrainian,
                      errors.spanish,
                      errors.montenegrin,
                      errors.croatian,
                    ][index]
                  }
                  required={true}
                  control={control}
                  name={item.language}
                  placeholder={`${translate('general.name')} ${item.language}`}
                  imageLeftIcon={<Image source={item.flag} style={{ height: 24, width: 24, borderRadius: 12 }} />}
                />
              ))}
            </ScrollView>
          </View>
        </RightPanel>

        <ConfirmDialog
          title={'Are you sure?'}
          message={`Attention! You are ${archivedView ? 'restoring' : 'removing'} the ingredient.`}
          visible={confirmDialogVisibility}
          dismiss={() => {
            toggleConfirmDialogVisibility();
          }}
          onConfirm={() => {
            if (currentModel) {
              const meta: IMeta = { ...currentModel.meta, isActive: archivedView, modified: new Date().getTime() };
              handleGlobalDataCU({ ...currentModel, meta }).then(() => {
                toggleConfirmDialogVisibility();
              });
            }
          }}
        />
      </RootView>
    );
};

export default EditTranslationsScreen;

const styles = StyleSheet.create({
  root: { flex: 1, flexDirection: 'row', gap: Spacing.small },
  fab: {
    position: 'absolute',
    margin: Spacing.big,
    right: 0,
    bottom: 0,
  },
});
