import { SectionList, StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useState } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import Spacing from '../../res/constants/Spacing';
import AllPurposeItemList from '../lists/AllPurposeItemList';
import SearchBar from '../search/SearchBar';
import { IconButton, Menu } from 'react-native-paper';
import { IGeneralItem } from '../../models/IGeneralItem';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import EmptyListComponent from '../general/EmptyListComponent';
import BillDialog from '../dialogs/print_bill/BillDialog';
import useToggle from '../../hooks/useToggle';
import { useFirestore } from '../../app/providers/firestore';
import OrderListHeader from '../orders/OrderListHeader';
import { IStaff } from '../../models/IStaff';
import { useFocusEffect } from '@react-navigation/native';
import collections from '../../utils/collections';
import ConfirmDialog from '../dialogs/confirm/ConfirmDialog';
import { ItemStatus } from '../../utils/enums';

interface Props {
  items: any[];
  onPress: (item: any) => void;
  menuItems?: IGeneralItem[];
  onMenuItemPress?: (value: number) => void;
  onBackPress?: () => void;
  isBackVisible?: boolean;
  emptyListMessage?: string;
  onTitleClick: (tablekey: string) => void;
}
const EditItemSideMenuRefactor: FC<Props> = ({ items = [], onPress, menuItems, onMenuItemPress, onBackPress, isBackVisible, emptyListMessage }) => {
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  const { currentUser, handleListenCollectionChange } = useFirestore();
  //#endregion
  //#region STATES
  const [query, setQuery] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [billDialogVisibility, toggleDialogVisibility] = useToggle(false);
  const [errorDialogVisibility, toggleErrorDialog] = useToggle(false);
  const [tableItems, setTableItems] = useState<any>();
  const [staffList, setStaffList] = useState<IStaff[]>([]);
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region FUNCTIONS
  const handleOnClick = useCallback((item: any) => {
    onPress(item);
    setSelectedItem(item.key);
  }, []);

  //#endregion
  //#region EFFETCS
  useFocusEffect(
    useCallback(() => {
      if (currentUser) {
        const staff = handleListenCollectionChange(currentUser.uid, collections.staff)
          .where('meta.status', '==', ItemStatus.ACTIVE)
          .onSnapshot((querySnapshot) => {
            const temporary: any[] = [];
            querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
              temporary.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });
            setStaffList(temporary);
          });

        return () => {
          staff();
        };
      }
    }, [currentUser])
  );
  //#endregion
  return (
    <View style={[styles.root, { backgroundColor: theme.lightSelectedItem }]}>
      <View style={{ flexDirection: 'row' }}>
        {isBackVisible && <IconButton iconColor={theme.text} icon="arrow-left" onPress={onBackPress} />}
        <SearchBar style={{ marginBottom: Spacing.regular, flex: 1 }} query={query} setQuery={setQuery} />

        {menuItems && menuItems.length > 0 && (
          <Menu
            contentStyle={{ backgroundColor: theme.background }}
            visible={menuVisible}
            onDismiss={() => {
              setMenuVisible(false);
            }}
            anchor={
              <IconButton
                iconColor={theme.text}
                icon="dots-vertical"
                onPress={() => {
                  setMenuVisible(true);
                }}
              />
            }
          >
            {menuItems?.map((item, index) => (
              <Menu.Item
                key={index}
                onPress={() => {
                  if (onMenuItemPress) {
                    onMenuItemPress(Number(item.id));
                    setMenuVisible(false);
                  }
                }}
                title={item.title}
                leadingIcon={item.icon}
              />
            ))}
          </Menu>
        )}
      </View>
      <View style={{ borderRadius: Spacing.defaultRadius, overflow: 'hidden', flex: 1, backgroundColor: 'white' }}>
        <SectionList
          ListEmptyComponent={() => <EmptyListComponent {...{ text: emptyListMessage }} />}
          contentContainerStyle={{ flexGrow: 1 }}
          bounces={false}
          sections={items}
          renderSectionHeader={({ section }: { section: any }) => (
            <OrderListHeader
              {...{ section, staffList }}
              onCloseClick={(value) => {
                setTableItems(value);
                toggleDialogVisibility();
              }}
              onError={() => toggleErrorDialog(true)}
            />
          )}
          renderItem={({ item }) => {
            const selected = selectedItem === item.key;
            return <AllPurposeItemList item={item} onPress={handleOnClick} selected={selected} />;
          }}
        />
      </View>

      <BillDialog
        title={translate('general.bill') as string}
        message={''}
        visible={billDialogVisibility}
        dismiss={toggleDialogVisibility}
        onConfirm={() => {}}
        tableItems={tableItems}
      />

      <ConfirmDialog
        title={translate('general.error').toString()}
        message={translate('general.staff_missing_error').toString()}
        visible={errorDialogVisibility}
        dismiss={() => toggleErrorDialog(false)}
        onConfirm={() => toggleErrorDialog(false)}
        showCancelButton={false}
      />
    </View>
  );
};

export default EditItemSideMenuRefactor;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Spacing.regular,
    borderRadius: Spacing.defaultRadius,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
