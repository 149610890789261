import { StyleSheet, View } from 'react-native';
import React, { FC, useRef, useState } from 'react';
import { Card, Divider, IconButton, Menu, Portal, Text, Modal, Button, MD3Colors, Badge } from 'react-native-paper';
import { User } from '../../../assets/icons';
import { AppImages } from '../../../assets/images';
import { ITable } from '../../models/ITable';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { useNavigation } from '@react-navigation/native';
import { FormType, UserRole } from '../../utils/enums';
import Spacing from '../../res/constants/Spacing';
import QRCode from 'react-native-qrcode-svg';
import { IUser } from '../../models/IUser';
import Config from '../../res/constants/Config';
import Layout from '../../res/constants/Layout';
import { captureView } from '../../captureView';
import { saveBase64Image } from '../../utils/saveBase64Image';
import { API_URL } from '@env';
import { useFirestore } from '../../app/providers/firestore';
import collections from '../../utils/collections';
import { IMeta } from '../../models/IMeta';
import { useAppSelector } from '../../hooks/hooks';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { IGeneralItem } from '../../models/IGeneralItem';

interface Props {
  item: ITable;
  currentUser?: IUser;
  tableOrders: any[];
  onClick: () => void;
}

const QR_SIZE = Config.isWeb && Layout.window.width > 800 ? 350 : Layout.window.width * 0.8;

const TableCard: FC<Props> = ({ item, currentUser, tableOrders, onClick }) => {
  const viewRef = useRef<any>(null);

  const onSaveViewAsJPEG = async () => {
    if (!viewRef.current) {
      console.error('Error: View reference is null');
      return;
    }

    try {
      const uri = await captureView(viewRef);
      await saveBase64Image(uri, `${item.key}.jpeg`);
    } catch (error) {
      console.error('Error saving view as JPEG:', error);
    }
  };

  //#region STATES
  const [menuVisibile, setMenuVisibile] = useState(false);
  const [dialogVisibile, setDialogVisibile] = useState(false);
  const currentSelectedUser: any = useAppSelector((state) => state.backOffice.selectedUser);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  const { handleDataCU } = useFirestore();
  const { translate } = useLocalization();
  const tabletype = translate('pages.table.type') as IGeneralItem[];

  //#endregion
  //#region FUNCTIONS
  const openMenu = () => setMenuVisibile(true);
  const closeMenu = () => setMenuVisibile(false);
  const showDialog = () => setDialogVisibile(true);
  const hideDialog = () => setDialogVisibile(false);

  const handleCreateTableCopy = () => {
    const meta: IMeta = { created: new Date().getTime() };

    const data = { ...item, key: undefined, name: `${item.name} Copy`, meta };
    handleDataCU(data, collections.table).then(() => console.debug('Copy created'));
  };

  const handleTargetUser = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser;
      } else {
        return currentUser;
      }
    }
  };

  //#endregion
  const theme = Styles[colorScheme];

  return (
    <Card style={{ backgroundColor: theme.background, margin: Spacing.minimal, width: 160 }} onPress={onClick}>
      <Card.Cover source={AppImages.table_cloth} style={{ height: 100, backgroundColor: theme.background }} />
      <Card.Title
        titleNumberOfLines={2}
        titleVariant="bodyLarge"
        title={item?.name}
        titleStyle={{ color: theme.text }}
        right={(props) => (
          <View>
            <Portal>
              <Modal
                visible={dialogVisibile}
                onDismiss={hideDialog}
                contentContainerStyle={{
                  width: QR_SIZE * 2.5,
                  backgroundColor: 'white',
                  alignSelf: 'center',
                  borderRadius: Spacing.bigRadius,
                }}
              >
                <View
                  ref={viewRef}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: Spacing.regular,
                    borderRadius: Spacing.bigRadius,
                    paddingBottom: Spacing.big,
                  }}
                >
                  <View style={{ alignItems: 'center', flex: 1 }}>
                    <Text
                      numberOfLines={2}
                      style={{ padding: Spacing.regular }}
                      variant="displayMedium"
                      onPress={() => {
                        setTimeout(() => {
                          onSaveViewAsJPEG();
                        }, 300);
                      }}
                    >
                      {handleTargetUser() && handleTargetUser()?.businessName}
                    </Text>
                    <Text variant="titleLarge" style={{ padding: Spacing.regular }}>
                      {`${item.name}, ${tabletype[item.type].title}`}
                    </Text>
                    <Text variant="titleLarge">Scan Here</Text>
                  </View>

                  <View style={{ alignItems: 'center', padding: Spacing.large }}>
                    {handleTargetUser() && (
                      <QRCode
                        // enableLinearGradient={true}
                        // linearGradient={[MATERIAL_COLORS.blue_grey_700, theme.primary]}
                        // logoSize={56}
                        // logo={AppImages.main_icon2}
                        // logoBorderRadius={Spacing.defaultRadius}
                        // logoMargin={Spacing.small}
                        size={QR_SIZE - 64}
                        value={`${API_URL}/menu/${handleTargetUser().uid}/${item.key}`}
                        logoBackgroundColor="white"
                      />
                    )}

                    {/* <Button
                      mode="text"
                      style={{ margin: Spacing.regular }}
                      onPress={() => {
                        setTimeout(() => {
                          onSaveViewAsJPEG();
                        }, 300);
                      }}
                    >
                      {item.name}
                    </Button> */}
                  </View>
                </View>
              </Modal>
            </Portal>

            <Menu
              contentStyle={{ backgroundColor: theme.background }}
              visible={menuVisibile}
              onDismiss={closeMenu}
              anchor={<IconButton iconColor={theme.text} {...props} icon="dots-vertical" onPress={openMenu} size={18} />}
            >
              {/* <Menu.Item
                onPress={() => {
                  handleCreateTableCopy();
                  closeMenu();
                }}
                title="Close Table"
                leadingIcon={'content-copy'}
              /> */}

              {/* <Menu.Item
                onPress={() => {
                  navigation.navigate('MenuDetailRefactor' as never, { business: currentUser?.uid, table: item.key } as never);
                  closeMenu();
                }}
                title="Menu"
                leadingIcon={'microsoft-xbox-controller-menu'}
                titleStyle={{ color: theme.text }}
              /> */}

              <Menu.Item
                onPress={() => {
                  navigation.navigate('EditTable' as never, { key: item.key, type: FormType.EDIT } as never);
                  closeMenu();
                }}
                title="Edit"
                leadingIcon={'circle-edit-outline'}
                titleStyle={{ color: theme.text }}
              />
              <Divider />
              <Menu.Item
                onPress={() => {
                  showDialog();
                  closeMenu();
                }}
                title="Qr Code"
                leadingIcon={'qrcode'}
                titleStyle={{ color: theme.text }}
              />
            </Menu>
          </View>
        )}
      />

      <Card.Content>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {tableOrders.length > 0 && (
            <View style={{ borderRadius: 12.5, height: 25, width: 25, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: 'white' }}>{tableOrders.length}</Text>
            </View>
          )}
          <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'flex-end' }}>
            <Text style={{ color: theme.text }} variant="bodyLarge">
              {item.size}
            </Text>
            <User size={18} set="bold" />
          </View>
        </View>
      </Card.Content>
    </Card>
  );
};

export default TableCard;

const styles = StyleSheet.create({ root: {} });
