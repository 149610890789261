import { Easing, StyleSheet, View, FlatList, TouchableOpacity } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { DEFAULT_ITEM_HEIGHT, DEFAULT_ITEM_HEIGHT_2 } from '../../../res/constants/Layout';
import Spacing from '../../../res/constants/Spacing';
import { IGeneralItem } from '../../../models/IGeneralItem';
import OptionListItem from './OptionListItem';
import { MATERIAL_COLORS } from '../../../res/constants/Colors';
import { Divider, IconButton } from 'react-native-paper';
import Styles from '../../../res/constants/Styles';
import useColorScheme from '../../../hooks/useColorScheme';
import Iconly from '../../iconly/mapIcons';
import { IconName } from '../../iconly/types';
import { Text } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';

type MultipleSelectProps = {
  multiple: true;
  value: any[];
  onChange: (value: IGeneralItem[]) => void;
};

type SingleSelectProps = {
  multiple?: false;
  value?: IGeneralItem;
  onChange: (value: IGeneralItem | undefined) => void;
};

type Props = {
  options: IGeneralItem[];
  placeHolder: string;
  icon?: IconName;
  error?: any;
} & (SingleSelectProps | MultipleSelectProps);

const SelectableList: FC<Props> = ({ multiple, options, placeHolder, value, onChange, icon, error }) => {
  //#region STATES
  const [isFocused, setIsFocused] = useState<boolean>(false);
  //#endregion
  //#region CUSTOM HOOKS
  const colorScheme = useColorScheme();
  //#endregion
  //#region FUNCTIONS
  const selectOption = useCallback(
    (option: IGeneralItem) => {
      if (multiple) {
        if (value.includes(option)) {
          onChange(value.filter((o) => o !== option));
        } else {
          onChange([...value, option]);
        }
      } else {
        onChange(option);
        setIsFocused(false);
      }
    },
    [value]
  );

  const isOptionSelected = useCallback(
    (option: IGeneralItem) => {
      return multiple ? value.includes(option) : option.id === value?.id;
    },
    [value]
  );

  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region ANIMATED VALUES
  const height = useSharedValue(0);
  //#endregion
  //#region ANIMATED STYLES
  const rStyle = useAnimatedStyle(() => {
    const display = height.value === 0 ? 'none' : 'flex';
    return { height: height.value, display };
  });
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    let maxHeight = 0;

    if (options.length > 5) {
      maxHeight = DEFAULT_ITEM_HEIGHT * 5 + Spacing.small * 2;
    } else {
      maxHeight = DEFAULT_ITEM_HEIGHT * options.length + Spacing.small * 2;
    }
    height.value = withTiming(isFocused ? maxHeight : 0, { easing: Easing.cubic });
  }, [isFocused]);
  //#endregion
  return (
    <View style={[styles.root, { borderWidth: 2, borderColor: error === undefined ? 'transparent' : MATERIAL_COLORS.red }]}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => setIsFocused((v) => !v)}
        style={[styles.titleContainer, { paddingStart: icon === undefined ? Spacing.regular : 0 }]}
      >
        {icon && (
          <View style={{ height: DEFAULT_ITEM_HEIGHT, width: DEFAULT_ITEM_HEIGHT_2, alignItems: 'center', justifyContent: 'center' }}>
            <Iconly name={icon} primaryColor={MATERIAL_COLORS.blue_grey} size={20} />
          </View>
        )}
        {multiple ? (
          <>
            {value.length === 0 ? (
              <Text style={[styles.title, { color: theme.placeholder, flex: 1, fontSize: 16, letterSpacing: 0.5 }]}>{placeHolder}</Text>
            ) : (
              <View style={{ padding: Spacing.small, flexWrap: 'wrap', flex: 1, flexDirection: 'row', gap: Spacing.minimal }}>
                {value.map((v, vIndex) => (
                  <View
                    key={vIndex}
                    style={{
                      paddingVertical: Spacing.minimal,
                      backgroundColor: theme.primary,
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderRadius: Spacing.regularRadius,
                    }}
                  >
                    <Text style={{ paddingStart: Spacing.small, flex: 1, color: theme.onPrimary }} variant="labelMedium">
                      {v.title}
                    </Text>
                    <TouchableOpacity style={{ paddingHorizontal: Spacing.micro }} onPress={() => selectOption(v)}>
                      <MaterialCommunityIcons name="close" size={15} color={theme.onPrimary} />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            )}
          </>
        ) : (
          <Text style={[styles.title, { color: value?.title ? theme.text : theme.placeholder }]}>{value?.title ?? placeHolder}</Text>
        )}
      </TouchableOpacity>
      <Animated.View style={rStyle}>
        <Divider />
        <FlatList
          style={{ margin: Spacing.small }}
          contentContainerStyle={{ flexGrow: 1 }}
          data={options}
          renderItem={({ item, index }) => {
            return <OptionListItem key={index} {...{ item, selectOption }} isOptionSelected={isOptionSelected(item)} />;
          }}
        />
      </Animated.View>
    </View>
  );
};

export default SelectableList;

const styles = StyleSheet.create({
  root: {
    borderRadius: Spacing.defaultRadius,
    overflow: 'hidden',
    backgroundColor: MATERIAL_COLORS.white,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: DEFAULT_ITEM_HEIGHT,
  },
  title: {
    flex: 1,
    fontSize: 16,
    letterSpacing: 0.5,
  },
});
