import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC, useEffect, useRef } from 'react';
import { IPrice } from '../../models/IPrice';
import { IconButton, Text } from 'react-native-paper';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import Spacing from '../../res/constants/Spacing';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

interface Props {
  currentPrice: number | null;
  options: IPrice[];
  size: number;
  onChange: (value: IPrice) => void;
  onAdd: () => void;
  onRemove: () => void;
}

const PriceSelection: FC<Props> = ({ currentPrice, options, size, onChange, onRemove, onAdd }) => {
  // #region REFERENCES
  const refs = useRef<Array<TouchableOpacity | null>>([]);
  //#endregion
  // #region STATES
  const animatedValue = useSharedValue(0);
  // #endregion
  // #region CUSTOM HOOKS
  // #endregion
  // #region FUNCTIONS
  const rStyle = useAnimatedStyle(() => {
    return { transform: [{ translateX: animatedValue.value }] };
  });

  const saveRef = (index: number) => (ref: TouchableOpacity | null) => {
    refs.current[index] = ref;
  };

  const handlePress = (item: IPrice, index: number) => {
    refs.current[index]?.measure((x, y, width, height, pageX, pageY) => {
      animatedValue.value = withTiming(x, { easing: Easing.cubic, duration: 200 }); // use relative X position
    });
  };

  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  const currentSelectedItem = options.find((i) => {
    if (currentPrice !== null) {
      return i.value === currentPrice;
    } else return false;
  });
  // #endregion
  return (
    <View style={[styles.root, { borderRadius: size }]}>
      <Animated.View style={[styles.animatedView, { height: size - Spacing.micro, width: size - Spacing.micro, borderRadius: size }, rStyle]} />

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <IconButton icon="minus" size={18} onPress={onRemove} containerColor="white" />

        {options.map((item, index) => (
          <TouchableOpacity
            activeOpacity={1}
            onLayout={() => handlePress(item, currentSelectedItem !== undefined ? currentSelectedItem.id : 0)}
            onPress={() => {
              handlePress(item, index);
              onChange(item);
            }}
            key={index}
            ref={saveRef(index)}
            style={{
              height: size - Spacing.micro,
              width: size - Spacing.micro,
              borderRadius: size,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text>{item.title.slice(0, 1)}</Text>
          </TouchableOpacity>
        ))}
        <IconButton icon="plus" size={18} onPress={onAdd} containerColor="white" />
      </View>
    </View>
  );
};

export default PriceSelection;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: Spacing.micro,
    backgroundColor: MATERIAL_COLORS.grey_100,
    borderColor: 'white',
    alignItems: 'center',
  },
  animatedView: {
    position: 'absolute',
    backgroundColor: MATERIAL_COLORS.grey_300,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,

    elevation: 6,
  },
});
