import { FlatList, ScrollView, StyleSheet, View, Image } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { useFirestore } from '../../app/providers/firestore';
import CText from '../../components/CText';
import ProductPicture from '../../components/images/ProductPicture';
import CustomCartIcon from '../../components/pressable/CustomCartIcon';
import RoundIconPressable from '../../components/pressable/RoundIconPressable';
import GeneralSwitch from '../../components/switch/GeneralSwitch';
import { IMenu } from '../../models/IMenu';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import Spacing from '../../res/constants/Spacing';
import collections from '../../utils/collections';
import Layout, { DEFAULT_GAP } from '../../res/constants/Layout';
import { View as RootView } from '../../components/Themed';
import { LinearGradient } from 'expo-linear-gradient';
import { useMediaQuery } from 'react-responsive';
import Config from '../../res/constants/Config';
import SuggestionListItem from '../../components/menu/SuggestionListItem';
import ChipItem from '../../components/menu/ChipItem';
import { IUser } from '../../models/IUser';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { IGeneralItem } from '../../models/IGeneralItem';
import { ITable } from '../../models/ITable';
import useToggle from '../../hooks/useToggle';
import AddArticle from '../../components/menu/AddArticle';
import { ActivityIndicator } from 'react-native-paper';
import AddMenuItem from '../../components/menu/AddMenuItem';

//#region CONSTANTS
const IMAGE_SIZE = 160;
//#endregion

const MenuDetailScreen = ({ navigation, route }: any) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  //#region REFERENCES
  const pagerRef = useRef<ScrollView | null>(null);
  //#endregion
  //#region STATES
  const [switchIndex, setSwitchIndex] = useState<number>(0);
  const [model, setModel] = useState<IMenu | undefined>(undefined);
  const [currentBusiness, setCurrentBusiness] = useState<IUser | undefined>(undefined);
  const [canOrderFromThisTable, toggleCanOrder] = useToggle(false);
  //#endregion
  //#region HOOKS
  const { handleGetDocument, handleGetUserDocument } = useFirestore();
  const { translate } = useLocalization();
  const insets = useSafeAreaInsets();
  const menuKey = route.params.menuKey;
  const business = route.params.business;
  const table = route.params.table;
  //#endregion
  //#region MEMBERS
  const allergens: IGeneralItem[] = translate('pages.menu.allergens') as [];
  //#endregion
  //#region EFFECTS
  const itemWidth = !isTabletOrMobile && Config.isWeb ? Layout.window.width : Layout.window.width;

  useEffect(() => {
    if (menuKey) {
      handleGetDocument(business, menuKey.toString(), collections.menu).then((value) => {
        setModel(value);
      });
      handleGetUserDocument(business).then((user: IUser) => {
        setCurrentBusiness(user);

        handleGetDocument(business, table, collections.table).then((table: ITable) => {
          if (user.orderPrivileges) {
            const canOrder = user.orderPrivileges.findIndex((i) => i === table?.category) > -1;
            toggleCanOrder(canOrder);
          }
        });
      });
    }
  }, [menuKey, business, table]);

  useEffect(() => {
    if (pagerRef.current) {
      const offset = switchIndex * itemWidth; // Calculate the offset based on the index and screen width
      pagerRef.current.scrollTo({ x: offset, animated: true });
    }
  }, [switchIndex]);

  //#endregion
  //#region FUNCTIONS
  const onRemoveIngredient = (item: any) => {
    if (model) {
      if (model.ingredients) {
        const temporaryIngredients = model.ingredients.map((obj) => ({ ...obj }));

        const ingredientIndex = temporaryIngredients.findIndex((i) => i.id === item.id);

        if (ingredientIndex > -1) {
          temporaryIngredients[ingredientIndex].removedByCustomer = true;
          setModel({ ...model, ingredients: temporaryIngredients });
        }
      }
    }
  };

  const onAddIngredient = (item: any) => {
    if (model) {
      if (model.ingredients) {
        const temporaryIngredients = model.ingredients.map((obj) => ({ ...obj }));

        const ingredientIndex = temporaryIngredients.findIndex((i) => i.id === item.id);

        if (ingredientIndex > -1) {
          if (temporaryIngredients[ingredientIndex].removedByCustomer) {
            temporaryIngredients[ingredientIndex].removedByCustomer = false;
            setModel({ ...model, ingredients: temporaryIngredients });
          }
        }
      }
    }
  };

  const renderItem = ({ item, index }: { item: string; index: number }) => {
    const i = item === 'Description' ? 0 : 1;
    switch (i) {
      case 0:
        return (
          <View
            key={index}
            style={{
              width: Layout.window.width - Spacing.regular * 2,
              padding: Spacing.regular,
              gap: DEFAULT_GAP,
            }}
          >
            <CText fontType="body2">{model?.description}</CText>
          </View>
        );
      case 1:
        return (
          <View
            key={index}
            style={{
              width: Layout.window.width - Spacing.regular * 2,
              padding: Spacing.regular,
              gap: DEFAULT_GAP,
            }}
          >
            <View style={{ flexDirection: 'row', gap: DEFAULT_GAP, flexWrap: 'wrap' }}>
              {model?.ingredients?.map((item, index) => {
                return (
                  <ChipItem
                    key={index}
                    {...{ item }}
                    onRemoveIngredient={() => onRemoveIngredient(item)}
                    onRestoreIngredient={() => onAddIngredient(item)}
                  />
                );
              })}
            </View>
          </View>
        );
      default:
        return null;
    }
  };
  //#endregion
  if (model === undefined || currentBusiness === undefined) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator />
      </View>
    );
  }

  const tabItems = model.description ? ['Description', 'Ingredients'] : ['Ingredients'];

  return (
    <RootView style={[styles.root, { paddingBottom: insets.bottom }]}>
      {/* Banner */}
      <LinearGradient
        colors={[MATERIAL_COLORS.lime_200, MATERIAL_COLORS.lime_500]}
        style={{ height: Layout.window.height * 0.33, backgroundColor: MATERIAL_COLORS.lime_300, width: '100%' }}
      >
        <SafeAreaView style={{ flex: 1, alignItems: 'center' }}>
          {/* TOP ICONS */}
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: Spacing.medium, width: '100%' }}>
            <RoundIconPressable
              name={'ChevronLeftCircle'}
              isIconly={true}
              onPress={() => {
                if (navigation.canGoBack()) {
                  navigation.pop();
                }
              }}
            />
            <View style={{ flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', flex: 1, justifyContent: 'flex-end' }}>
              <CustomCartIcon businessId={business} tableKey={table} />
            </View>
          </View>
          <View style={{ paddingHorizontal: Spacing.regular, flex: 1, alignItems: 'center' }}>
            <CText fontType="subtitle1">{model.name}</CText>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                marginTop: Spacing.medium,
                paddingHorizontal: Spacing.regular,
              }}
            >
              {model.allergens?.map((allergenId, aIndex) => {
                const foundIndex = allergens.findIndex((i) => i.id === allergenId);

                if (foundIndex > -1) {
                  const allergen = allergens[foundIndex];
                  return (
                    <View key={aIndex} style={{ paddingHorizontal: Spacing.minimal, alignItems: 'center', flexDirection: 'row' }}>
                      <Image source={require(`../../../assets/images/allergens/${allergen.icon}.svg`)} style={{ height: 24, width: 24 }} />
                      <CText style={{ marginStart: Spacing.minimal }} fontType="caption">
                        {allergen.title}
                      </CText>
                    </View>
                  );
                }
                return null;
              })}
            </View>
          </View>
        </SafeAreaView>
      </LinearGradient>
      {/* Product Image */}
      <ProductPicture
        source={model.image}
        size={IMAGE_SIZE}
        style={{ marginTop: -IMAGE_SIZE / 2, marginBottom: Spacing.regular }}
        setSource={() => {}}
      />

      {/* {canOrderFromThisTable && <AddMenuItem item={model} currency={currentBusiness.currency} />} */}
      {<AddMenuItem item={model} currency={currentBusiness.currency} />}

      <View style={{ alignSelf: 'flex-start', padding: Spacing.regular }}>
        <GeneralSwitch switchItems={tabItems} selectedId={switchIndex} setSelectedId={setSwitchIndex} />
        <ScrollView
          ref={pagerRef}
          scrollEventThrottle={16}
          decelerationRate={'fast'}
          pagingEnabled
          horizontal
          contentContainerStyle={{ flexGrow: 0, width: Layout.window.width - Spacing.regular * 2 }}
          nestedScrollEnabled={true}
          showsHorizontalScrollIndicator={false}
        >
          {tabItems.map((item, index) => renderItem({ item, index }))}
        </ScrollView>
      </View>
      {model.suggestion && (
        <View style={{ width: '100%', paddingHorizontal: Spacing.regular }}>
          <CText style={{ marginTop: Spacing.regular }} fontType="subtitle1">
            Suggestions
          </CText>
          <FlatList
            horizontal
            data={model.suggestion}
            contentContainerStyle={{ paddingVertical: Spacing.regular, gap: Spacing.medium }}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item, index }) => <SuggestionListItem key={index} {...{ item }} />}
          />
        </View>
      )}
    </RootView>
  );
};

export default MenuDetailScreen;

const styles = StyleSheet.create({ root: { flex: 1, alignItems: 'center' } });
