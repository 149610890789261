import { StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import React, { ReactNode, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ViewProps } from '../Themed';
import Animated, { interpolate, runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { OVERLAY } from '../../res/constants/Colors';
import Layout from '../../res/constants/Layout';

interface Props extends ViewProps {
  children?: ReactNode;
}

export type SideMenuViewRef = {
  present: () => void;
  dismiss: () => void;
  isOpen: boolean;
};

const WIDTH = Layout.window.width * 0.5;

const SideMenu = forwardRef<SideMenuViewRef, Props>(({ children }, ref) => {
  const innerView = useRef<Animated.View>(null);
  //#region STATES
  const [active, setActive] = useState<boolean>(false);
  //#endregion
  //#region VALUES
  const translateX = useSharedValue(WIDTH);
  //#endregion
  //#region ACTIONS
  const present = useCallback(() => {
    setActive(true);
    animate(0);
  }, []);

  const dismiss = useCallback(() => {
    animate(WIDTH);
  }, []);

  const isOpen = useMemo(() => {
    return active;
  }, [active]);

  useImperativeHandle(ref, () => ({ present, dismiss, isOpen }), [present, dismiss, isOpen]);
  //#endregion
  //#region FUNCTIONS
  const animate = (value: number) => {
    translateX.value = withTiming(value, { duration: 250 }, (finished) => {
      if (finished) {
        if (value === 0) runOnJS(setActive)(false);
      }
    });
  };

  const handlePress = (event: any) => {
    dismiss();
  };
  //#endregion
  //#region ANIMATED STYLE
  const innerStyle = useAnimatedStyle(() => {
    return { transform: [{ translateX: translateX.value }] };
  });
  const outerStyle = useAnimatedStyle(() => {
    const display = translateX.value === WIDTH ? 'none' : 'flex';
    const opacity = interpolate(translateX.value, [WIDTH, 0], [0, 1]);
    return { display, opacity };
  });
  //#endregion
  return (
    <TouchableWithoutFeedback style={{ ...StyleSheet.absoluteFillObject }} onPress={handlePress}>
      <Animated.View style={[styles.root, outerStyle]}>
        <Animated.View ref={innerView} style={[styles.inner, innerStyle]}>
          {children}
        </Animated.View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
});

export default SideMenu;

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: OVERLAY,
  },
  inner: { position: 'absolute', end: 0, width: WIDTH, backgroundColor: 'white', height: '100%' },
});
