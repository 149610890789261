import { StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { PrivateStackScreenProps } from '../../app/navigation/types';
import { View as RootView } from '../../components/Themed';
import { useFirestore } from '../../app/providers/firestore';
import useColorScheme from '../../hooks/useColorScheme';
import collections from '../../utils/collections';
import { ITable } from '../../models/ITable';
import { ActivityIndicator } from 'react-native-paper';
import Styles from '../../res/constants/Styles';
import { IMeta } from '../../models/IMeta';
import { IOrder } from '../../models/IOrder';
import { useMediaQuery } from 'react-responsive';
import OrderDetailComponentRefactor from '../../components/orders/OrderDetailComponentRefactor';
import { useAppSelector } from '../../hooks/hooks';
import { IMenu } from '../../models/IMenu';

const OrderDetailScreen = ({ navigation, route }: PrivateStackScreenProps<'OrderDetail'>) => {
  const key = route.params.key;
  //#region STATES
  const [order, setOrder] = useState<IOrder | undefined>(undefined);
  const [table, setTable] = useState<ITable | undefined>(undefined);
  //#endregion
  //#region HOOKS
  const department: number = useAppSelector((state) => state.departments.selectedDepartment);

  const { currentUser, handleGetDocument, handleDataCU, orders } = useFirestore();
  const colorScheme = useColorScheme();
  //#endregion
  //#region EFFECTS

  useEffect(() => {
    if (currentUser) {
      handleGetDocument(currentUser.uid, key, collections.orders).then((order: IOrder) => {
        if (order) {
          handleGetDocument(currentUser.uid, order.tableKey, collections.table).then((table) => {
            if (table) {
              if (department !== 0) {
                const filteredOrder = order.cartItems.filter((i) => i.subCategory === department);
                const orderData = { ...order, cartItems: filteredOrder };
                setOrder(orderData);
              } else {
                setOrder(order);
              }
              setTable(table);
            }
          });
        }
      });
    }
  }, [key, currentUser, order]);

  //#endregion
  //#region MEMBERS
  if (order === undefined || table === undefined) {
    return (
      <RootView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator />
      </RootView>
    );
  }

  const theme = Styles[colorScheme];

  //#endregion
  //#region FUNCTIONS
  const onSubmitRef = (duration?: number, isCompleted = false) => {
    if (order) {
      let meta: IMeta = { ...order.meta };

      // Find the order to be updated in the orders array
      const orderToUpdate = orders.find((o) => o.key === order.key);

      if (!orderToUpdate) {
        return; // If the order to update is not found in the orders array, abort the function
      }

      // Create a new cartItems array for the order to update, where each item is updated if it matches the current department
      const updatedItems = orderToUpdate.cartItems.map((item: IMenu) => {
        if (item.subCategory === department) {
          item.meta.isCompleted = isCompleted;
          item.meta.isPending = false;
          item.meta.modified = new Date().getTime();
          // for now, let's just return the item as is
          return item;
        } else if (department === 0) {
          item.meta.isCompleted = isCompleted;
          item.meta.isPending = false;
          item.meta.modified = new Date().getTime();
          // for now, let's just return the item as is
          return item;
        }
        return item; // if the item's subCategory does not match the current department, leave it unchanged
      });

      if (department === 0) {
        meta = { ...meta, modified: new Date().getTime(), isCompleted, isPending: false };
      }

      const data = {
        ...orderToUpdate,
        cartItems: updatedItems,
        meta,
        duration: duration || null,
      };

      handleDataCU(data, collections.orders).then(() => setOrder(undefined));
    }
  };

  //#endregion
  return (
    <RootView style={[styles.root, { backgroundColor: theme.lightBackground }]}>
      <OrderDetailComponentRefactor department={department} {...{ order, table }} onSubmit={onSubmitRef} />
    </RootView>
  );
};

export default OrderDetailScreen;

const styles = StyleSheet.create({ root: { flex: 1 } });
