import { StyleSheet, View, Image } from 'react-native';
import React, { FC } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Image2 } from '../../../assets/icons';

interface Props {
  source?: string | undefined | null;
  size: number;
  hasRadius?: boolean;
}

const ProfilePicture: FC<Props> = ({ source, size, hasRadius = false }) => {
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];

  return (
    <View
      style={[
        styles.root,
        {
          width: size,
          height: size,
          borderRadius: hasRadius ? size / 2 : 0,
          overflow: 'hidden',
          borderWidth: 1,
          borderColor: source !== undefined && source !== null ? 'transparent' : theme.icon,
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}
    >
      {source !== undefined && source !== null ? (
        <Image source={{ uri: source }} style={{ ...StyleSheet.absoluteFillObject, borderRadius: hasRadius ? size / 2 : 0 }} />
      ) : (
        <Image2 size={size - size * (20 / 100)} />
      )}
    </View>
  );
};

export default ProfilePicture;

const styles = StyleSheet.create({ root: {} });
