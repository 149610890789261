const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export const MATERIAL_COLORS = {
  red: '#f44336',
  red_50: '#ffebee',
  red_100: '#ffcdd2',
  red_200: '#ef9a9a',
  red_300: '#e57373',
  red_400: '#ef5350',
  red_500: '#f44336',
  red_600: '#e53935',
  red_700: '#d32f2f',
  red_800: '#c62828',
  red_900: '#b71c1c',
  red_a100: '#ff8a80',
  red_a200: '#ff5252',
  red_a400: '#ff1744',
  red_a700: '#d50000',

  pink: '#e91e63',
  pink_50: '#fce4ec',
  pink_100: '#f8bbd0',
  pink_200: '#f48fb1',
  pink_300: '#f06292',
  pink_400: '#ec407a',
  pink_500: '#e91e63',
  pink_600: '#d81b60',
  pink_700: '#c2185b',
  pink_800: '#ad1457',
  pink_900: '#880e4f',
  pink_a100: '#ff80ab',
  pink_a200: '#ff4081',
  pink_a400: '#f50057',
  pink_a700: '#c51162',

  purple: '#9c27b0',
  purple_50: '#f3e5f5',
  purple_100: '#e1bee7',
  purple_200: '#ce93d8',
  purple_300: '#ba68c8',
  purple_400: '#ab47bc',
  purple_500: '#9c27b0',
  purple_600: '#8e24aa',
  purple_700: '#7b1fa2',
  purple_800: '#6a1b9a',
  purple_900: '#4a148c',
  purple_a100: '#ea80fc',
  purple_a200: '#e040fb',
  purple_a400: '#d500f9',
  purple_a700: '#aa00ff',

  deep_purple: '#673ab7',
  deep_purple_50: '#ede7f6',
  deep_purple_100: '#d1c4e9',
  deep_purple_200: '#b39ddb',
  deep_purple_300: '#9575cd',
  deep_purple_400: '#7e57c2',
  deep_purple_500: '#673ab7',
  deep_purple_600: '#5e35b1',
  deep_purple_700: '#512da8',
  deep_purple_800: '#4527a0',
  deep_purple_900: '#311b92',
  deep_purple_a100: '#b388ff',
  deep_purple_a200: '#7c4dff',
  deep_purple_a400: '#651fff',
  deep_purple_a700: '#6200ea',

  indigo: '#3f51b5',
  indigo_50: '#e8eaf6',
  indigo_100: '#c5cae9',
  indigo_200: '#9fa8da',
  indigo_300: '#7986cb',
  indigo_400: '#5c6bc0',
  indigo_500: '#3f51b5',
  indigo_600: '#3949ab',
  indigo_700: '#303f9f',
  indigo_800: '#283593',
  indigo_900: '#1a237e',
  indigo_a100: '#8c9eff',
  indigo_a200: '#536dfe',
  indigo_a400: '#3d5afe',
  indigo_a700: '#304ffe',

  blue: '#2196f3',
  blue_50: '#e3f2fd',
  blue_100: '#bbdefb',
  blue_200: '#90caf9',
  blue_300: '#64b5f6',
  blue_400: '#42a5f5',
  blue_500: '#2196f3',
  blue_600: '#1e88e5',
  blue_700: '#1976d2',
  blue_800: '#1565c0',
  blue_900: '#0d47a1',
  blue_a100: '#82b1ff',
  blue_a200: '#448aff',
  blue_a400: '#2979ff',
  blue_a700: '#2962ff',

  light_blue: '#03a9f4',
  light_blue_50: '#e1f5fe',
  light_blue_100: '#b3e5fc',
  light_blue_200: '#81d4fa',
  light_blue_300: '#4fc3f7',
  light_blue_400: '#29b6f6',
  light_blue_500: '#03a9f4',
  light_blue_600: '#039be5',
  light_blue_700: '#0288d1',
  light_blue_800: '#0277bd',
  light_blue_900: '#01579b',
  light_blue_a100: '#80d8ff',
  light_blue_a200: '#40c4ff',
  light_blue_a400: '#00b0ff',
  light_blue_a700: '#0091ea',

  cyan: '#00bcd4',
  cyan_50: '#e0f7fa',
  cyan_100: '#b2ebf2',
  cyan_200: '#80deea',
  cyan_300: '#4dd0e1',
  cyan_400: '#26c6da',
  cyan_500: '#00bcd4',
  cyan_600: '#00acc1',
  cyan_700: '#0097a7',
  cyan_800: '#00838f',
  cyan_900: '#006064',
  cyan_a100: '#84ffff',
  cyan_a200: '#18ffff',
  cyan_a400: '#00e5ff',
  cyan_a700: '#00b8d4',

  teal: '#009688',
  teal_50: '#e0f2f1',
  teal_100: '#b2dfdb',
  teal_200: '#80cbc4',
  teal_300: '#4db6ac',
  teal_400: '#26a69a',
  teal_500: '#009688',
  teal_600: '#00897b',
  teal_700: '#00796b',
  teal_800: '#00695c',
  teal_900: '#004d40',
  teal_a100: '#a7ffeb',
  teal_a200: '#64ffda',
  teal_a400: '#1de9b6',
  teal_a700: '#00bfa5',

  green: '#4caf50',
  green_50: '#e8f5e9',
  green_100: '#c8e6c9',
  green_200: '#a5d6a7',
  green_300: '#81c784',
  green_400: '#66bb6a',
  green_500: '#4caf50',
  green_600: '#43a047',
  green_700: '#388e3c',
  green_800: '#2e7d32',
  green_900: '#1b5e20',
  green_a100: '#b9f6ca',
  green_a200: '#69f0ae',
  green_a400: '#00e676',
  green_a700: '#00c853',

  light_green: '#8bc34a',
  light_green_50: '#f1f8e9',
  light_green_100: '#dcedc8',
  light_green_200: '#c5e1a5',
  light_green_300: '#aed581',
  light_green_400: '#9ccc65',
  light_green_500: '#8bc34a',
  light_green_600: '#7cb342',
  light_green_700: '#689f38',
  light_green_800: '#558b2f',
  light_green_900: '#33691e',
  light_green_a100: '#ccff90',
  light_green_a200: '#b2ff59',
  light_green_a400: '#76ff03',
  light_green_a700: '#64dd17',

  lime: '#cddc39',
  lime_50: '#f9fbe7',
  lime_100: '#f0f4c3',
  lime_200: '#e6ee9c',
  lime_300: '#dce775',
  lime_400: '#d4e157',
  lime_500: '#cddc39',
  lime_600: '#c0ca33',
  lime_700: '#afb42b',
  lime_800: '#9e9d24',
  lime_900: '#827717',
  lime_a100: '#f4ff81',
  lime_a200: '#eeff41',
  lime_a400: '#c6ff00',
  lime_a700: '#aeea00',

  yellow: '#ffeb3b',
  yellow_50: '#fffde7',
  yellow_100: '#fff9c4',
  yellow_200: '#fff59d',
  yellow_300: '#fff176',
  yellow_400: '#ffee58',
  yellow_500: '#ffeb3b',
  yellow_600: '#fdd835',
  yellow_700: '#fbc02d',
  yellow_800: '#f9a825',
  yellow_900: '#f57f17',
  yellow_a100: '#ffff8d',
  yellow_a200: '#ffff00',
  yellow_a400: '#ffea00',
  yellow_a700: '#ffd600',

  amber: '#ffc107',
  amber_50: '#fff8e1',
  amber_100: '#ffecb3',
  amber_200: '#ffe082',
  amber_300: '#ffd54f',
  amber_400: '#ffca28',
  amber_500: '#ffc107',
  amber_600: '#ffb300',
  amber_700: '#ffa000',
  amber_800: '#ff8f00',
  amber_900: '#ff6f00',
  amber_a100: '#ffe57f',
  amber_a200: '#ffd740',
  amber_a400: '#ffc400',
  amber_a700: '#ffab00',

  orange: '#ff9800',
  orange_50: '#fff3e0',
  orange_100: '#ffe0b2',
  orange_200: '#ffcc80',
  orange_300: '#ffb74d',
  orange_400: '#ffa726',
  orange_500: '#ff9800',
  orange_600: '#fb8c00',
  orange_700: '#f57c00',
  orange_800: '#ef6c00',
  orange_900: '#e65100',
  orange_a100: '#ffd180',
  orange_a200: '#ffab40',
  orange_a400: '#ff9100',
  orange_a700: '#ff6d00',

  deep_orange: '#ff5722',
  deep_orange_50: '#fbe9e7',
  deep_orange_100: '#ffccbc',
  deep_orange_200: '#ffab91',
  deep_orange_300: '#ff8a65',
  deep_orange_400: '#ff7043',
  deep_orange_500: '#ff5722',
  deep_orange_600: '#f4511e',
  deep_orange_700: '#e64a19',
  deep_orange_800: '#d84315',
  deep_orange_900: '#bf360c',
  deep_orange_a100: '#ff9e80',
  deep_orange_a200: '#ff6e40',
  deep_orange_a400: '#ff3d00',
  deep_orange_a700: '#dd2c00',

  brown: '#795548',
  brown_50: '#efebe9',
  brown_100: '#d7ccc8',
  brown_200: '#bcaaa4',
  brown_300: '#a1887f',
  brown_400: '#8d6e63',
  brown_500: '#795548',
  brown_600: '#6d4c41',
  brown_700: '#5d4037',
  brown_800: '#4e342e',
  brown_900: '#3e2723',

  grey: '#9e9e9e',
  grey_50: '#fafafa',
  grey_100: '#f5f5f5',
  grey_200: '#eeeeee',
  grey_300: '#e0e0e0',
  grey_400: '#bdbdbd',
  grey_500: '#9e9e9e',
  grey_600: '#757575',
  grey_700: '#616161',
  grey_800: '#424242',
  grey_900: '#212121',

  blue_grey: '#607d8b',
  blue_grey_50: '#eceff1',
  blue_grey_100: '#cfd8dc',
  blue_grey_200: '#b0bec5',
  blue_grey_300: '#90a4ae',
  blue_grey_400: '#78909c',
  blue_grey_500: '#607d8b',
  blue_grey_600: '#546e7a',
  blue_grey_700: '#455a64',
  blue_grey_800: '#37474f',
  blue_grey_900: '#263238',

  white: '#ffffff',
  black: '#000000',
};

export const TRANSPARENT = 'transparent';
export const OVERLAY = 'rgba(0,0,0, 0.7)';
