import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC } from 'react';
import CountryPicker from '../../components/countries/CountryPicker';
import { View as RootView } from '../../components/Themed';
import Spacing from '../../res/constants/Spacing';
import { Text } from 'react-native-paper';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import ConfirmDialog from '../../components/dialogs/confirm/ConfirmDialog';
import collections from '../../utils/collections';
import { ItemStatus, UserRole } from '../../utils/enums';
import useToggle from '../../hooks/useToggle';
import { useFirestore } from '../../app/providers/firestore';

const SettingsScreen = ({}) => {
  //#region STATES
  const [confirmDialog, toggleConfirmDialog] = useToggle(false);
  //#endregion
  //#region HOOKS
  const { translate } = useLocalization();
  const { handleUpdateUser, currentUser } = useFirestore();
  //#endregion
  return (
    <RootView style={styles.root}>
      <View style={{ flexDirection: 'row', alignItems: 'center', gap: Spacing.regular }}>
        <CountryPicker showText={false} />
        <Text variant="titleSmall">{translate('general.language')}</Text>
      </View>

      <TouchableOpacity
        onPress={() => toggleConfirmDialog()}
        activeOpacity={1}
        style={{ flexDirection: 'row', alignItems: 'center', gap: Spacing.regular }}
      >
        <MaterialCommunityIcons name="delete" size={24} />
        <View style={{ flex: 1 }}>
          <Text variant="titleSmall">{'Account Delete'}</Text>
          <Text style={{ flex: 1, fontStyle: 'italic', color: 'red' }} variant="labelSmall">
            {"Your account and all it's data will be deleted within 2 weeks"}
          </Text>
        </View>
      </TouchableOpacity>

      <ConfirmDialog
        title={'Are you sure?'}
        message={`Attention! You are about to delete your account`}
        visible={confirmDialog}
        dismiss={() => toggleConfirmDialog()}
        onConfirm={() => {
          if (currentUser) handleUpdateUser(currentUser?.uid, { ...currentUser, role: UserRole.SIMPLE, requestedToDeleteAccount: true });
        }}
      />
    </RootView>
  );
};

export default SettingsScreen;

const styles = StyleSheet.create({ root: { flex: 1, padding: Spacing.regular, gap: Spacing.regular } });
