import { StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { PrivateStackScreenProps } from '../../../../app/navigation/types';
import EditItemSideMenu from '../../../../components/side_menu/EditItemSideMenu';
import Spacing from '../../../../res/constants/Spacing';
import { View as RootView } from '../../../../components/Themed';
import { useMediaQuery } from 'react-responsive';
import useLocalization from '../../../../app/providers/localization/LocalizationProvider';
import { LeftPanelRefactor } from '../../../../components/responsive_containers/LeftPanelRefactor';
import RightPanelRefactor from '../../../../components/responsive_containers/RightPanelRefactor';
import { IStaff } from '../../../../models/IStaff';
import { FormType, ItemStatus, UserRole } from '../../../../utils/enums';
import { IconButton } from 'react-native-paper';
import useToggle from '../../../../hooks/useToggle';
import { useFocusEffect } from '@react-navigation/native';
import collections from '../../../../utils/collections';
import { useFirestore } from '../../../../app/providers/firestore';
import { useAppSelector } from '../../../../hooks/hooks';
import StaffForm from '../StaffForm';
import BusinessPicker from '../../../../components/general/userPicker/BusinessPicker';

const EditStaffScreen = ({ navigation, route }: PrivateStackScreenProps<'EditStaff'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  //#region STATES
  const [staffList, setStaffList] = useState<IStaff[]>([]);
  const [currentModel, setCurrentModel] = useState<IStaff | undefined>(undefined);
  const [formType, setFormType] = useState<FormType>(FormType.NEW);
  const [archivedMode, toggleArchivedMode] = useToggle(false);
  const [confirmDialogVisibility, toggleConfirmDialog] = useToggle(false);
  //#endregion
  //#region CUSTOM HOOKS
  const { translate } = useLocalization();
  const { handleListenCollectionChange, currentUser } = useFirestore();
  const currentSelectedUser = useAppSelector((state) => state.backOffice.selectedUser);
  //#endregion
  //#region FUNCTIONS
  const handleClearObject = () => {
    setCurrentModel(undefined);
    setFormType(FormType.NEW);
    navigation.setParams({ type: FormType.NEW });
  };

  const handleMenuItemClick = (index: number) => {
    switch (index) {
      case 0:
        toggleArchivedMode();
        handleClearObject();
        break;

      default:
        break;
    }
  };

  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
        else return currentUser.uid;
      } else {
        return currentUser.uid;
      }
    }
  };

  const handleSelectCurrentModel = (item: IStaff) => {
    if (!isTabletOrMobile) {
      setCurrentModel(item);
      setFormType(FormType.EDIT);
      navigation.setParams({ type: FormType.EDIT });
    } else {
      navigation.navigate('StaffDetail', { key: item.key, type: FormType.EDIT, archivedMode: archivedMode });
    }
  };

  //#endregion
  //#region EFFECTS
  useFocusEffect(
    useCallback(() => {
      const uid = handleTargetUid();
      if (uid) {
        const staff = handleListenCollectionChange(uid, collections.staff)
          .where('meta.status', '==', archivedMode ? ItemStatus.INACTIVE : ItemStatus.ACTIVE)
          .onSnapshot((querySnapshot) => {
            const temporary: IStaff[] = [];
            querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
              temporary.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });
            setStaffList(temporary);
          });

        return () => {
          staff();
        };
      }
    }, [currentUser, currentSelectedUser, archivedMode])
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        {
          if (currentUser?.role === UserRole.BACKOFFICE)
            return (
              <BusinessPicker onBackClick={() => (navigation.canGoBack() ? navigation.pop() : navigation.navigate('Root', { screen: 'Profile' }))} />
            );
        }
      },
      headerRight: () =>
        currentModel !== undefined && <IconButton icon={archivedMode ? 'delete-restore' : 'delete'} onPress={() => toggleConfirmDialog()} />,
    });
  }, [navigation, formType, archivedMode, currentUser, currentSelectedUser]);
  //#endregion
  return (
    <RootView style={[styles.root, { padding: isTabletOrMobile ? 0 : Spacing.regular }]}>
      <LeftPanelRefactor
        showFab={!archivedMode}
        onFabPress={() => {
          handleClearObject();
          if (isTabletOrMobile) {
            navigation.navigate('StaffDetail', { key: undefined, type: FormType.NEW, archivedMode });
          }
        }}
      >
        <EditItemSideMenu
          isBackVisible={archivedMode}
          menuItems={[{ id: 0, title: translate('general.recycle_bin').toString(), icon: 'delete' }]}
          items={staffList}
          onPress={handleSelectCurrentModel}
          onMenuItemPress={handleMenuItemClick}
          onBackPress={() => {
            toggleArchivedMode();
            handleClearObject();
          }}
          currentSelectedItem={currentModel && currentModel?.key}
        />
      </LeftPanelRefactor>
      <RightPanelRefactor>
        <StaffForm {...{ currentModel, handleClearObject, formType, archivedMode, confirmDialogVisibility, toggleConfirmDialog }} />
      </RightPanelRefactor>
    </RootView>
  );
};

export default EditStaffScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    gap: Spacing.small,
  },
});
