import { StyleSheet, View, Image } from 'react-native';
import React, { FC } from 'react';
import { Star } from '../../../../assets/icons';
import { MATERIAL_COLORS } from '../../../res/constants/Colors';
import Spacing from '../../../res/constants/Spacing';
import { currencyResolver } from '../../../utils/common';
import CustomPressable from '../../pressable/CustomPressable';
import { Text } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import CText from '../../CText';
import { IMenu } from '../../../models/IMenu';
import { IUser } from '../../../models/IUser';
import { handleExtractMinimumPrice } from '../Common';

interface Props {
  item: IMenu;
  onItemPress: () => void;
  currentBusiness: IUser;
}

const ArticleListItem: FC<Props> = ({ item, onItemPress, currentBusiness }) => {
  return (
    <View style={styles.root}>
      <View style={styles.container}>
        <CustomPressable onPress={onItemPress} style={styles.pressable}>
          <Image
            source={{ uri: item.image }}
            style={{
              ...StyleSheet.absoluteFillObject,
            }}
            resizeMode="cover"
          />
          <LinearGradient colors={['rgba(0,0,0,0.5)', 'transparent']} style={{ ...StyleSheet.absoluteFillObject }} />
          <View style={{ flex: 1, padding: Spacing.medium }}>
            <CText fontType="overline" style={{ color: MATERIAL_COLORS.white, flex: 1, textAlign: 'right' }}>
              {item.name}
            </CText>
          </View>
          <View style={styles.footer}>
            <View style={{ flexDirection: 'row' }}>
              <Star set="bold" size={13} primaryColor={'white'} />
              <CText style={{ color: 'white', marginStart: Spacing.micro }} fontType="caption">
                {item.rating}
              </CText>
            </View>
            <Text variant="labelMedium" style={{ color: 'white', alignSelf: 'flex-end' }}>
              {`${currencyResolver(currentBusiness.currency)?.symbol} ${handleExtractMinimumPrice(item.price)}`}
            </Text>
          </View>
        </CustomPressable>
      </View>
    </View>
  );
};

export default ArticleListItem;

const styles = StyleSheet.create({
  root: {
    borderRadius: 16,
    margin: Spacing.micro,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  container: {
    height: 180,
    width: 140,
    borderRadius: 16,
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  pressable: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'space-between',
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Spacing.micro,
    paddingHorizontal: Spacing.medium,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'space-between',
  },
});
