import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { Button, Modal, Portal, Text } from 'react-native-paper';
import Config from '../../../res/constants/Config';
import Layout, { DEFAULT_GAP } from '../../../res/constants/Layout';
import Spacing from '../../../res/constants/Spacing';
import useColorScheme from '../../../hooks/useColorScheme';
import Styles from '../../../res/constants/Styles';

interface Props {
  title: string;
  message: string;
  visible: boolean;
  dismiss: () => void;
  onConfirm: () => void;
  showCancelButton?: boolean;
}

const SIZE = Config.isWeb && Layout.window.width > 800 ? 400 : Layout.window.width * 0.8;

const ConfirmDialog: FC<Props> = ({ title, message, visible, dismiss, onConfirm, showCancelButton = true }) => {
  //#region HOOOKS
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  return (
    <Portal>
      <Modal
        visible={visible}
        onDismiss={dismiss}
        contentContainerStyle={{
          width: SIZE,
          backgroundColor: theme.background,
          alignSelf: 'center',
          borderRadius: Spacing.defaultRadius,
        }}
      >
        <View style={{ padding: Spacing.regular }}>
          <Text style={{ alignSelf: 'center', color: theme.text }} variant="titleLarge">
            {title}
          </Text>

          <Text style={{ paddingVertical: Spacing.regular, color: theme.text }} variant="bodyMedium">
            {message}
          </Text>

          <View style={{ flexDirection: 'row', gap: Spacing.medium, justifyContent: 'flex-end' }}>
            {showCancelButton && (
              <Button textColor={theme.onPrimary} style={{ backgroundColor: theme.primary }} mode="contained-tonal" onPress={dismiss}>
                Cancel
              </Button>
            )}
            <Button onPress={onConfirm} textColor={theme.onPrimary} style={{ backgroundColor: theme.primary }} mode="contained-tonal">
              Ok
            </Button>
          </View>
        </View>
      </Modal>
    </Portal>
  );
};

export default ConfirmDialog;

const styles = StyleSheet.create({ root: {} });
