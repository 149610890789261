import { StyleSheet, Image, View } from 'react-native';
import React, { FC } from 'react';
import { ISuggestion } from '../../models/IMenu';
import { addCartItem, removeCartItem, selectCartItemsWithId } from '../../app/features/cart/cartSlice';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import Spacing from '../../res/constants/Spacing';
import CText from '../CText';
import CustomPressable from '../pressable/CustomPressable';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Badge } from 'react-native-paper';

interface Props {
  item: ISuggestion;
}
const SuggestionListItem: FC<Props> = ({ item }) => {
  //#region HOOKS
  const dispatch = useAppDispatch();
  const numberOfItems = useAppSelector((state) => selectCartItemsWithId(state, item.key!));
  //#endregion
  return (
    <View>
      {numberOfItems.length > 0 && (
        <View style={styles.badgeContainer}>
          <CustomPressable
            style={{ padding: Spacing.micro, alignItems: 'center', justifyContent: 'center', flex: 1 }}
            onPress={() => dispatch(removeCartItem(item))}
          >
            <CText fontType="caption" style={{ color: 'white' }}>
              X
            </CText>
          </CustomPressable>
        </View>
      )}
      <View style={styles.root}>
        <CustomPressable disabled={numberOfItems.length === 1} style={styles.container} onPress={() => dispatch(addCartItem(item))}>
          <View style={styles.leftContainer}>
            <CText style={{ fontSize: 11 }} fontType="overline">
              {item.name}
            </CText>
            <CText fontType="caption">{`$${item.price}`}</CText>
          </View>

          <View style={styles.imageContainer}>
            <Image source={{ uri: item.image }} style={{ ...StyleSheet.absoluteFillObject, marginVertical: Spacing.regular }} resizeMode="cover" />
          </View>
        </CustomPressable>
      </View>
    </View>
  );
};

export default SuggestionListItem;

const styles = StyleSheet.create({
  root: {
    width: 180,
    height: 140,
    borderRadius: 20,
    overflow: 'hidden',
    backgroundColor: MATERIAL_COLORS.grey_100,
    borderColor: MATERIAL_COLORS.grey_300,
    borderWidth: 1,
  },
  container: { flex: 1, justifyContent: 'space-between', flexDirection: 'row' },
  leftContainer: { flex: 1, justifyContent: 'space-between', padding: Spacing.medium },
  imageContainer: { width: '35%', backgroundColor: MATERIAL_COLORS.grey_300, alignItems: 'center', justifyContent: 'center' },
  badgeContainer: {
    width: 30,
    height: 30,
    position: 'absolute',
    zIndex: 1,
    top: -5,
    end: -5,
    overflow: 'hidden',
    borderRadius: 50,
  },
});
