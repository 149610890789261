import { StatusBar } from 'expo-status-bar';
import { StyleSheet } from 'react-native';
import Navigation from './src/app/navigation';
import { AuthenticationProvider } from './src/app/providers/authentication';
import React, { useCallback, useEffect } from 'react';
import useCachedResources from './src/hooks/useCachedResources';
import * as SplashScreen from 'expo-splash-screen';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { DefaultTheme, ThemeProvider } from '@react-navigation/native';
import { FirestoreProvider } from './src/app/providers/firestore';
import { bottomSheetfragmentContainerRef } from './src/utils/actions';
import { IconlyProvider } from './src/components/iconly/context';
import { MATERIAL_COLORS } from './src/res/constants/Colors';
import BottomSheetContainer from './src/components/dialogs/BottomSheetContainer';
import { Provider as PaperProvider } from 'react-native-paper';
import { Provider } from 'react-redux';
import { persistor, store } from './src/app/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizationProvider } from './src/app/providers/localization/LocalizationProvider';
import { LightTheme } from './src/res/constants/StylesRefactor';
import Config from './src/res/constants/Config';
import * as Sentry from 'sentry-expo';
import { StorageProvider } from './src/app/providers/firebase-storage/StorageProvider';

export default function App() {
  const isLoadingComplete = useCachedResources();

  async function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/register-service-worker.js');
        console.debug('ServiceWorker registration successful with scope: ', registration.scope);
      } catch (err) {
        console.error('ServiceWorker registration failed: ', err);
      }
    }
  }

  Sentry.init({
    dsn: 'https://29cc0cf757394403ad5f4471d9d1ac19@o4505295419736064.ingest.sentry.io/4505295474720768',
    enableInExpoDevelopment: true,
    release: 'amore-mio@' + require('./package.json').version,
    debug: __DEV__, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  });

  useEffect(() => {
    if (Config.isWeb) registerServiceWorker();
  }, []);

  const onLayoutRootView = useCallback(() => {
    if (isLoadingComplete) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.

      setTimeout(async () => {
        await SplashScreen.hideAsync();
      }, 300);
    }
  }, [isLoadingComplete]);

  if (!isLoadingComplete) {
    return null;
  } else
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider value={DefaultTheme}>
            <LocalizationProvider>
              <PaperProvider theme={LightTheme}>
                <SafeAreaProvider style={{ flex: 1 }} onLayout={onLayoutRootView}>
                  <IconlyProvider set="curved" primaryColor={MATERIAL_COLORS.blue_grey_800}>
                    <AuthenticationProvider>
                      <FirestoreProvider>
                        <StorageProvider>
                          <Navigation colorScheme={undefined} />
                          <BottomSheetContainer {...{ ref: bottomSheetfragmentContainerRef }} />
                          <StatusBar style="auto" />
                        </StorageProvider>
                      </FirestoreProvider>
                    </AuthenticationProvider>
                  </IconlyProvider>
                </SafeAreaProvider>
              </PaperProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
