import { Alert, StyleSheet, View } from 'react-native';
import React, { FC, useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { IStaff } from '../../../models/IStaff';
import Spacing from '../../../res/constants/Spacing';
import { Button, useTheme } from 'react-native-paper';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';
import { IMeta } from '../../../models/IMeta';
import collections from '../../../utils/collections';
import { FormType, ItemStatus, UserRole } from '../../../utils/enums';
import { useFirestore } from '../../../app/providers/firestore';
import { useAppSelector } from '../../../hooks/hooks';
import ConfirmDialog from '../../../components/dialogs/confirm/ConfirmDialog';
import useColorScheme from '../../../hooks/useColorScheme';
import Styles from '../../../res/constants/Styles';
import CustomInput from '../../../components/inputs/CustomInput';

interface Props {
  currentModel?: IStaff;
  formType: FormType;
  handleClearObject: () => void;
  archivedMode: boolean;
  confirmDialogVisibility: boolean;
  toggleConfirmDialog: () => void;
}
const StaffForm: FC<Props> = ({ currentModel, formType, handleClearObject, archivedMode, confirmDialogVisibility, toggleConfirmDialog }) => {
  // #region CUSTOM HOOKS
  const { handleDataUnAuthorized, currentUser } = useFirestore();
  const { translate } = useLocalization();
  const currentSelectedUser = useAppSelector((state) => state.backOffice.selectedUser);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IStaff>({ values: currentModel });
  // #endregion
  // #region FUNCTIONS

  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
        else Alert.alert('Error', 'You need to select a user');
      } else {
        return currentUser.uid;
      }
    }
  };

  const onSubmit = (params: FieldValues) => {
    const { name, phone } = params;
    const meta: IMeta =
      formType === FormType.NEW
        ? { created: new Date().getTime(), status: ItemStatus.ACTIVE }
        : { ...currentModel?.meta, modified: new Date().getTime() };

    const payload: IStaff = {
      key: currentModel?.key,
      meta,
      name,
      phone,
    };

    const uid = handleTargetUid();

    if (uid)
      handleDataUnAuthorized(uid, payload, collections.staff).then(() => {
        handleClearForm();
        handleClearObject();
      });
  };

  const handleClearForm = () => {
    const defaultValues = {
      name: '',
      phone: '',
      meta: {},
    };
    reset(defaultValues);
  };
  // #endregion
  // #region EFFECTS
  useEffect(() => {
    if (formType === FormType.NEW) {
      handleClearForm();
    }
  }, [formType]);
  // #endregion

  return (
    <View style={[styles.root]}>
      <View style={{ flex: 1, gap: Spacing.micro }}>
        <CustomInput
          error={errors.name}
          required={true}
          control={control}
          name={'name'}
          placeholder={translate('general.name') as string}
          iconProps={{ leftName: 'Paper', isLeftIconly: true, showRight: false }}
        />

        <CustomInput
          error={errors.name}
          required={true}
          control={control}
          name={'phone'}
          placeholder={translate('general.phone') as string}
          iconProps={{ leftName: 'Call', isLeftIconly: true, showRight: false }}
        />
      </View>

      <Button onPress={handleSubmit(onSubmit)} mode="contained">
        {translate('general.save') as string}
      </Button>

      <ConfirmDialog
        title={'Are you sure?'}
        message={`Attention! You are about to ${archivedMode ? 'restore' : 'remove'} this item.`}
        visible={confirmDialogVisibility}
        dismiss={() => toggleConfirmDialog()}
        onConfirm={() => {
          const uid = handleTargetUid();

          if (uid)
            handleDataUnAuthorized(
              uid,
              {
                ...currentModel,
                meta: { ...currentModel?.meta, modified: new Date().getTime(), status: archivedMode ? ItemStatus.ACTIVE : ItemStatus.INACTIVE },
              },
              collections.staff
            ).then(() => {
              toggleConfirmDialog();
              handleClearObject();
            });
        }}
      />
    </View>
  );
};

export default StaffForm;

const styles = StyleSheet.create({ root: { flex: 1 } });
