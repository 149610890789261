import { FlatList, StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import CText from '../../CText';
import { IGeneralItem } from '../../../models/IGeneralItem';
import CustomPressable from '../../pressable/CustomPressable';
import { DEFAULT_ITEM_HEIGHT } from '../../../res/constants/Layout';
import Spacing from '../../../res/constants/Spacing';
import Iconly from '../../iconly/mapIcons';

interface Props {
  data: IGeneralItem[];
  onPress: (index: number) => void;
}
const ProfileOptionsDialog: FC<Props> = ({ data, onPress }) => {
  return (
    <View style={styles.root}>
      <FlatList
        bounces={false}
        showsVerticalScrollIndicator={false}
        data={data}
        renderItem={({ item, index }) => (
          <CustomPressable
            onPress={() => {
              onPress(index);
            }}
            key={index}
            style={{
              height: DEFAULT_ITEM_HEIGHT,
              paddingHorizontal: Spacing.regular,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <CText fontType="body1" style={{ flex: 1 }}>
              {item.title}
            </CText>
            <Iconly name={item.icon!} size={20} />
          </CustomPressable>
        )}
      />
    </View>
  );
};

export default ProfileOptionsDialog;

const styles = StyleSheet.create({ root: {} });
