import { StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { PrivateStackScreenProps } from '../../../../app/navigation/types';
import { View as RootView } from '../../../../components/Themed';
import { useMediaQuery } from 'react-responsive';
import Spacing from '../../../../res/constants/Spacing';
import { LeftPanelRefactor } from '../../../../components/responsive_containers/LeftPanelRefactor';
import RightPanelRefactor from '../../../../components/responsive_containers/RightPanelRefactor';
import EditItemSideMenu from '../../../../components/side_menu/EditItemSideMenu';
import { FormType, ItemStatus, UserRole } from '../../../../utils/enums';
import { IMenu } from '../../../../models/IMenu';
import useToggle from '../../../../hooks/useToggle';
import MenuForm from '../MenuForm';
import useLocalization from '../../../../app/providers/localization/LocalizationProvider';
import { useFirestore } from '../../../../app/providers/firestore';
import { useAppSelector } from '../../../../hooks/hooks';
import { useFocusEffect } from '@react-navigation/native';
import collections from '../../../../utils/collections';
import { IconButton } from 'react-native-paper';
import BusinessPicker from '../../../../components/general/userPicker/BusinessPicker';
import CurrencyDialog from '../../../../components/dialogs/currency/CurrencyDialog';

const EditMenuScreen = ({ navigation, route }: PrivateStackScreenProps<'EditMenu'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // #region STATES
  const [menuList, setMenuList] = useState<IMenu[]>([]);
  const [currentModel, setCurrentModel] = useState<IMenu | undefined>(undefined);
  const [formType, setFormType] = useState<FormType>(FormType.NEW);
  const [archivedMode, toggleArchivedMode] = useToggle(false);
  const [confirmDialogVisibility, toggleConfirmDialog] = useToggle(false);
  const [currencyDialogVisibility, setCurrencyDialogVisibility] = React.useState(false);

  // #endregion
  // #region CUSTOM HOOKS
  const { translate } = useLocalization();
  const { handleListenCollectionChange, currentUser } = useFirestore();
  const currentSelectedUser = useAppSelector((state) => state.backOffice.selectedUser);

  // #endregion
  // #region FUNCTIONS

  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
        else return currentUser.uid;
      } else {
        return currentUser.uid;
      }
    } else return '';
  };

  const handleClearObject = () => {
    setCurrentModel(undefined);
    setFormType(FormType.NEW);
    navigation.setParams({ type: FormType.NEW });
  };

  const handleMenuItemClick = (index: number) => {
    switch (index) {
      case 0:
        toggleArchivedMode();
        handleClearObject();
        break;
      case 1:
        setCurrencyDialogVisibility(true);
        break;
      case 2:
        navigation.navigate('Menu', { business: handleTargetUid(), table: '0' });
        break;
      default:
        break;
    }
  };

  const handleSelectCurrentModel = (item: IMenu) => {
    if (!isTabletOrMobile) {
      setCurrentModel(item);
      setFormType(FormType.EDIT);
      navigation.setParams({ type: FormType.EDIT });
    } else {
      navigation.navigate('EditMenuDetail', { key: item.key, type: FormType.EDIT, archivedMode: archivedMode });
    }
  };

  // #endregion
  // #region EFFECTS

  useFocusEffect(
    useCallback(() => {
      const uid = handleTargetUid();
      if (uid) {
        const staff = handleListenCollectionChange(uid, collections.menu)
          .where('meta.status', '==', archivedMode ? ItemStatus.INACTIVE : ItemStatus.ACTIVE)
          .onSnapshot((querySnapshot) => {
            const temporary: IMenu[] = [];
            querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
              temporary.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });
            setMenuList(temporary);
          });

        return () => {
          staff();
        };
      }
    }, [currentUser, currentSelectedUser, archivedMode])
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        {
          if (currentUser?.role === UserRole.BACKOFFICE)
            return (
              <BusinessPicker onBackClick={() => (navigation.canGoBack() ? navigation.pop() : navigation.navigate('Root', { screen: 'Profile' }))} />
            );
        }
      },
      headerRight: () =>
        currentModel !== undefined && <IconButton icon={archivedMode ? 'delete-restore' : 'delete'} onPress={() => toggleConfirmDialog()} />,
    });
  }, [navigation, formType, archivedMode, currentUser, currentSelectedUser]);

  // #endregion

  return (
    <RootView style={[styles.root, { padding: isTabletOrMobile ? 0 : Spacing.regular }]}>
      <LeftPanelRefactor
        showFab={!archivedMode}
        onFabPress={() => {
          handleClearObject();
          if (isTabletOrMobile) {
            navigation.navigate('EditMenuDetail', { key: undefined, type: FormType.NEW, archivedMode });
          }
        }}
      >
        <EditItemSideMenu
          isBackVisible={archivedMode}
          menuItems={[
            { id: 0, title: translate('pages.menu.archived').toString(), icon: 'archive-outline' },
            { id: 1, title: translate('general.currency').toString(), icon: 'cash' },
            { id: 2, title: translate('general.preview').toString(), icon: 'eye' },
          ]}
          items={menuList}
          onPress={handleSelectCurrentModel}
          onMenuItemPress={handleMenuItemClick}
          onBackPress={() => {
            toggleArchivedMode();
            handleClearObject();
          }}
          currentSelectedItem={currentModel && currentModel?.key}
        />
      </LeftPanelRefactor>
      <RightPanelRefactor>
        <MenuForm {...{ currentModel, handleClearObject, formType, archivedMode, confirmDialogVisibility, toggleConfirmDialog }} />
      </RightPanelRefactor>

      <CurrencyDialog visible={currencyDialogVisibility} setVisible={setCurrencyDialogVisibility} uid={handleTargetUid()} />
    </RootView>
  );
};

export default EditMenuScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    gap: Spacing.small,
  },
});
