import { ScrollView, StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useState } from 'react';
import SearchBar from '../search/SearchBar';
import Spacing from '../../res/constants/Spacing';
import IngredientItem from './IngredientItem';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { useFocusEffect } from '@react-navigation/native';
import { useFirestore } from '../../app/providers/firestore';

interface Props {
  selectedItems?: any[];
  setSelectedItems: (value: any) => void;
}
const Ingredients: FC<Props> = ({ selectedItems, setSelectedItems }) => {
  const [query, setQuery] = useState<string>('');

  const { ingredients } = useFirestore();
  const { locale } = useLocalization();

  const filteredData = ingredients.filter((item: any) => {
    if (item.name[locale]) return item.name[locale].toLowerCase().includes(query.toLowerCase());
  });

  return (
    <View style={styles.root}>
      <SearchBar query={query} setQuery={setQuery} />
      <View style={styles.scrollViewContainer}>
        <ScrollView nestedScrollEnabled contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap', padding: Spacing.medium }}>
          {filteredData.map((item, index) => {
            let selected = false;
            if (selectedItems) {
              const foundIndex = selectedItems?.findIndex((i) => i.id === item.id);
              selected = foundIndex > -1;
            }
            return (
              <IngredientItem
                item={item}
                key={index}
                selected={selected}
                onPress={() => {
                  setSelectedItems(item);
                }}
              />
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
};

export default Ingredients;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: MATERIAL_COLORS.white,
    borderRadius: Spacing.defaultRadius,
  },
  scrollViewContainer: {
    flex: 1, // make the ScrollView container take up all available space
  },
});
