import { FlatList, StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import Spacing from '../../res/constants/Spacing';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import AllPurposeItemList from '../lists/AllPurposeItemList';
import SearchBar from '../search/SearchBar';
import { IconButton, Menu, Text } from 'react-native-paper';
import { IGeneralItem } from '../../models/IGeneralItem';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

interface Props {
  items: any[];
  onPress: (item: any) => void;
  menuItems?: IGeneralItem[];
  onMenuItemPress?: (value: number) => void;
  onBackPress?: () => void;
  isBackVisible?: boolean;
  currentSelectedItem?: string;
}
const EditItemSideMenu: FC<Props> = ({
  items = [],
  onPress,
  menuItems,
  onMenuItemPress,
  onBackPress,
  isBackVisible,
  currentSelectedItem = undefined,
}) => {
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { locale, translate } = useLocalization();
  //#endregion
  //#region STATES
  const [query, setQuery] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<string | undefined>(currentSelectedItem);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  //#endregion
  //#region MEMBERS
  const filteredItems = items.filter((item: any) => {
    let nameValidation = false;

    if (typeof item.name === 'string') {
      nameValidation = item.name.toLowerCase().includes(query.toLowerCase());
    } else {
      if (item.name && item.name[locale] === undefined) nameValidation = false;
      else nameValidation = item.name && item?.name[locale]?.toLowerCase().includes(query.toLowerCase());
    }
    const descriptionValidation = item.description?.toLowerCase().includes(query.toLowerCase());
    return nameValidation || descriptionValidation;
  });
  const theme = Styles[colorScheme];
  //#endregion
  //#region FUNCTIONS
  const handleOnClick = useCallback(
    (item: any) => {
      onPress(item);
      setSelectedItem(item.key);
    },
    [onPress]
  );
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    setSelectedItem(currentSelectedItem);
  }, [currentSelectedItem]);
  //#endregion
  return (
    <View style={[styles.root, { backgroundColor: theme.lightSelectedItem }]}>
      <View style={{ flexDirection: 'row' }}>
        {isBackVisible && <IconButton iconColor={theme.text} icon="arrow-left" onPress={onBackPress} />}
        <SearchBar style={{ marginBottom: Spacing.regular, flex: 1 }} query={query} setQuery={setQuery} />

        {menuItems && menuItems.length > 0 && (
          <Menu
            contentStyle={{ backgroundColor: theme.background }}
            visible={menuVisible}
            onDismiss={() => {
              setMenuVisible(false);
            }}
            anchor={
              <IconButton
                iconColor={theme.text}
                icon="dots-vertical"
                onPress={() => {
                  setMenuVisible(true);
                }}
              />
            }
          >
            {menuItems?.map((item, index) => (
              <Menu.Item
                key={index}
                onPress={() => {
                  if (onMenuItemPress) {
                    onMenuItemPress(Number(item.id));
                    setMenuVisible(false);
                  }
                }}
                title={item.title}
                leadingIcon={item.icon}
              />
            ))}
          </Menu>
        )}
      </View>
      <View style={{ borderRadius: Spacing.defaultRadius, overflow: 'hidden', flex: 1, backgroundColor: 'white' }}>
        <FlatList
          ListEmptyComponent={() => (
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: theme.text }} variant="titleMedium">
                {translate('general.no_data').toString()}
              </Text>
            </View>
          )}
          contentContainerStyle={{ flexGrow: 1 }}
          ItemSeparatorComponent={() => <View style={{ height: 1, width: '95%', backgroundColor: MATERIAL_COLORS.grey_200, alignSelf: 'center' }} />}
          data={filteredItems}
          renderItem={({ item, index }) => {
            const selected = selectedItem === item.key;
            return <AllPurposeItemList key={index} item={item} onPress={handleOnClick} selected={selected} />;
          }}
        />
      </View>
    </View>
  );
};

export default EditItemSideMenu;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Spacing.regular,
    borderRadius: Spacing.defaultRadius,
  },
});
