import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { SimpleUserScreenProps } from '../../app/navigation/types';
import { Text } from 'react-native-paper';
import Spacing from '../../res/constants/Spacing';

const HomeScreen = ({ navigation, route }: SimpleUserScreenProps<'Home'>) => {
  return (
    <View style={styles.root}>
      <Text variant="headlineMedium">Welcome friend!</Text>
      <Text variant="headlineSmall">We will contact you soon to activate your account.</Text>
    </View>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: Spacing.regular,
  },
});
