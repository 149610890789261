import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../../models/IUser';

interface InitialState {
  selectedUser: IUser | undefined; //NOTE: Expo router wont allow to pass objects, so if the current user is a back office than we save the selected user
}

const initialState: InitialState = {
  selectedUser: undefined,
};

export const backOfficeSlice = createSlice({
  name: 'backOffice',
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },

    clearSelectedUser: (state) => {
      state.selectedUser = undefined;
    },
  },
});

export const { setSelectedUser, clearSelectedUser } = backOfficeSlice.actions;
export default backOfficeSlice.reducer;
