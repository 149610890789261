import { StyleSheet, View, Image, ScrollView } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { View as RootView } from '../../components/Themed';
import Header from '../../components/menu-detail/Header';
import { LinearGradient } from 'expo-linear-gradient';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { useFirestore } from '../../app/providers/firestore';
import { IUser } from '../../models/IUser';
import { ITable } from '../../models/ITable';
import collections from '../../utils/collections';
import { IMenu } from '../../models/IMenu';
import useToggle from '../../hooks/useToggle';
import { ActivityIndicator, Text } from 'react-native-paper';
import Spacing from '../../res/constants/Spacing';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { IGeneralItem } from '../../models/IGeneralItem';
import ProductPicture from '../../components/images/ProductPicture';
import AddArticle from '../../components/menu/AddArticle';
import GeneralSwitch from '../../components/switch/GeneralSwitch';
import Layout, { DEFAULT_GAP } from '../../res/constants/Layout';
import ChipItem from '../../components/menu/ChipItem';
import { useMediaQuery } from 'react-responsive';
import Config from '../../res/constants/Config';

// #region CONSTANTS
const IMAGE_SIZE = 160;
// #endregion

const MenuDetailScreenRefactor = ({ navigation, route }: any) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // #region REFERENCES
  const pagerRef = useRef<ScrollView | null>(null);
  // #endregion
  // #region MEMBERS
  const itemWidth = !isTabletOrMobile && Config.isWeb ? Layout.window.width : Layout.window.width;
  const businessId = route.params.business;
  const tableKey = route.params.table;
  const menuKey = route.params.menuKey;
  // # endregion
  // #region STATES
  const [switchIndex, setSwitchIndex] = useState<number>(0);
  const [currentMenu, setCurrentMenu] = useState<IMenu | undefined>(undefined);
  const [currentBusiness, setCurrentBusiness] = useState<IUser | undefined>(undefined); // We need current business here to get their currency
  const [canOrderFromThisTable, toggleCanOrder] = useToggle(false); //NOTE: Various businesses can have various options about ordering
  // #endregion
  // #region CUSTOM HOOKS
  const { handleGetDocument, handleGetUserDocument } = useFirestore();
  const { translate } = useLocalization();

  const allergens: IGeneralItem[] = translate('pages.menu.allergens') as [];

  // #endregion
  // #region FUNCTIONS
  const onRemoveIngredient = (item: any) => {
    if (currentMenu) {
      if (currentMenu.ingredients) {
        const temporaryIngredients = currentMenu.ingredients.map((obj) => ({ ...obj }));

        const ingredientIndex = temporaryIngredients.findIndex((i) => i.id === item.id);

        if (ingredientIndex > -1) {
          temporaryIngredients[ingredientIndex].removedByCustomer = true;
          setCurrentMenu({ ...currentMenu, ingredients: temporaryIngredients });
        }
      }
    }
  };

  const onAddIngredient = (item: any) => {
    if (currentMenu) {
      if (currentMenu.ingredients) {
        const temporaryIngredients = currentMenu.ingredients.map((obj) => ({ ...obj }));

        const ingredientIndex = temporaryIngredients.findIndex((i) => i.id === item.id);

        if (ingredientIndex > -1) {
          if (temporaryIngredients[ingredientIndex].removedByCustomer) {
            temporaryIngredients[ingredientIndex].removedByCustomer = false;
            setCurrentMenu({ ...currentMenu, ingredients: temporaryIngredients });
          }
        }
      }
    }
  };

  const renderItem = ({ item, index }: { item: string; index: number }) => {
    switch (index) {
      case 0:
        return (
          <View
            key={index}
            style={{
              width: Layout.window.width - Spacing.regular * 2,
              padding: Spacing.regular,
              gap: DEFAULT_GAP,
            }}
          >
            <View style={{ flexDirection: 'row', gap: DEFAULT_GAP, flexWrap: 'wrap' }}>
              {currentMenu?.ingredients?.map((item, index) => {
                return (
                  <ChipItem
                    key={index}
                    {...{ item }}
                    onRemoveIngredient={() => onRemoveIngredient(item)}
                    onRestoreIngredient={() => onAddIngredient(item)}
                  />
                );
              })}
            </View>
          </View>
        );
      case 1:
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            key={index}
            style={{
              width: Layout.window.width - Spacing.regular * 2,
              padding: Spacing.regular,
              flex: 1,
            }}
          >
            <Text style={{ flex: 1 }} variant="bodyMedium">
              {currentMenu?.description}
            </Text>
          </ScrollView>
        );
      case 2:
        return (
          <ScrollView
            showsVerticalScrollIndicator={false}
            key={index}
            style={{
              width: Layout.window.width - Spacing.regular * 2,
              padding: Spacing.regular,
              flex: 1,
            }}
          >
            <Text style={{ flex: 1 }} variant="bodyMedium">
              {currentMenu?.info}
            </Text>
          </ScrollView>
        );
      default:
        return null;
    }
  };
  // #endregion
  // #region EFFECTS
  useEffect(() => {
    if (menuKey) {
      handleGetDocument(businessId, menuKey.toString(), collections.menu).then((value) => {
        setCurrentMenu(value);
      });
      handleGetUserDocument(businessId).then((user: IUser) => {
        setCurrentBusiness(user);

        handleGetDocument(businessId, tableKey, collections.table).then((table: ITable) => {
          if (user.orderPrivileges) {
            const canOrder = user.orderPrivileges.findIndex((i) => i === table?.category) > -1;
            toggleCanOrder(canOrder);
          }
        });
      });
    }
  }, [menuKey, businessId, tableKey]);

  useEffect(() => {
    if (pagerRef.current) {
      const offset = switchIndex * itemWidth; // Calculate the offset based on the index and screen width
      pagerRef.current.scrollTo({ x: offset, animated: true });
    }
  }, [switchIndex]);
  // #endregion

  const tabItems = ['Ingredients', 'Description', 'INFO'];

  if (currentMenu === undefined || currentBusiness === undefined) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator />
      </View>
    );
  } else
    return (
      <RootView style={styles.root}>
        {/* Cover */}
        <LinearGradient
          colors={[MATERIAL_COLORS.lime_200, MATERIAL_COLORS.lime_500]}
          style={{ height: '33%', backgroundColor: MATERIAL_COLORS.lime_300, width: '100%' }}
        >
          <Header {...{ businessId, tableKey, currentMenu }} />

          {/* Alergens */}
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '80%', alignSelf: 'center' }}>
            {currentMenu.allergens?.map((allergenId, aIndex) => {
              const foundIndex = allergens.findIndex((i) => i.id === allergenId);

              if (foundIndex > -1) {
                const allergen = allergens[foundIndex];
                return (
                  <View
                    key={aIndex}
                    style={{ paddingHorizontal: Spacing.minimal, alignItems: 'center', flexDirection: 'row', margin: Spacing.micro }}
                  >
                    <Image source={require(`../../../assets/images/allergens/${allergen.icon}.svg`)} style={{ height: 24, width: 24 }} />
                    <Text style={{ marginStart: Spacing.minimal }} variant="labelMedium">
                      {allergen.title}
                    </Text>
                  </View>
                );
              }
              return null;
            })}
          </View>
        </LinearGradient>

        <ProductPicture
          source={currentMenu.image}
          size={IMAGE_SIZE}
          style={{ marginTop: -IMAGE_SIZE / 2, marginBottom: Spacing.regular }}
          setSource={() => {}}
        />

        {canOrderFromThisTable && <AddArticle {...{ currentMenu }} currency={currentBusiness.currency} />}

        <View style={{ padding: Spacing.regular, flex: 1, marginTop: Spacing.regular }}>
          <GeneralSwitch switchItems={tabItems} selectedId={switchIndex} setSelectedId={setSwitchIndex} />
          <ScrollView
            ref={pagerRef}
            scrollEventThrottle={16}
            decelerationRate={'fast'}
            pagingEnabled
            horizontal
            contentContainerStyle={{ flexGrow: 0, width: Layout.window.width - Spacing.regular * 2 }}
            nestedScrollEnabled={true}
            showsHorizontalScrollIndicator={false}
          >
            {tabItems.map((item, index) => renderItem({ item, index }))}
          </ScrollView>
        </View>
      </RootView>
    );
};

export default MenuDetailScreenRefactor;

const styles = StyleSheet.create({ root: { flex: 1, alignItems: 'center' } });
