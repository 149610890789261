import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Iconly from '../../components/iconly/mapIcons';
import HomeScreen from '../../screens/private/HomeScreen';
import ProfileScreen from '../../screens/private/ProfileScreen';
import { PrivateParamList } from './types';
import { useFirestore } from '../providers/firestore';
import { View } from 'react-native';
import { Badge, IconButton } from 'react-native-paper';
import useLocalization from '../providers/localization/LocalizationProvider';
import { UserRole } from '../../utils/enums';
import ActivityIndicatorContainer from '../../components/progress_views/ActivityIndicatorContainer';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import StatsScreen from '../../screens/private/StatsScreen';
import AdministrationScreen from '../../screens/private/AdministrationScreen';
import MenuDetailScreenRefactor from '../../screens/public/MenuDetailScreenRefactor';
import MenuScreen from '../../screens/public/MenuScreen';

const BottomTab = createBottomTabNavigator<PrivateParamList>();

const PrivateNavigator = () => {
  const { translate } = useLocalization();
  const { orders, currentUser } = useFirestore();
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  if (currentUser === undefined) return <ActivityIndicatorContainer />;

  return (
    <BottomTab.Navigator initialRouteName="Home">
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }) => ({
          headerTitle: currentUser?.role !== UserRole.BACKOFFICE ? (translate('pages.home.title') as string) : '',
          tabBarIcon: ({ color }) => <TabBarIcon name="Home" primaryColor={color} />,
          headerRight: () => {
            if (orders && orders.length > 0 && currentUser.role !== UserRole.BACKOFFICE) {
              return (
                <View style={{}}>
                  <Badge style={{ position: 'absolute', end: 5, top: 5, backgroundColor: theme.error, color: 'white' }}>
                    {orders.filter((i) => i.meta.isPending).length}
                  </Badge>
                  <IconButton iconColor={theme.text} icon={'bell'} onPress={() => navigation.navigate('Orders')} />
                </View>
              );
            }
          },
        })}
      />
      <BottomTab.Screen
        name="Stats"
        component={StatsScreen}
        options={{
          headerTitle: currentUser?.role !== UserRole.BACKOFFICE ? 'Stats' : '',
          tabBarIcon: ({ color }) => <TabBarIcon name="Chart" primaryColor={color} />,
        }}
      />

      {currentUser.role === UserRole.ACCOUNT && (
        <BottomTab.Screen
          name="Administration"
          component={AdministrationScreen}
          options={{
            title: 'Administration',
            tabBarIcon: ({ color }) => <TabBarIcon name="Category" primaryColor={color} />,
            headerShown: true,
          }}
        />
      )}
      <BottomTab.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: translate('pages.profile.title') as string,
          tabBarIcon: ({ color }) => <TabBarIcon name="User" primaryColor={color} />,
          headerShown: false,
        }}
      />
    </BottomTab.Navigator>
  );
};

function TabBarIcon(props: { name: React.ComponentProps<typeof Iconly>['name']; primaryColor: string }) {
  return <Iconly set="curved" size={24} {...props} />;
}

export default PrivateNavigator;
