import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import CText from '../CText';
import useColorScheme from '../../hooks/useColorScheme';
import { IMenu } from '../../models/IMenu';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { addCartItem, removeCartItem, selectCartItemsWithId } from '../../app/features/cart/cartSlice';
import Styles from '../../res/constants/Styles';
import Layout from '../../res/constants/Layout';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { IconButton } from 'react-native-paper';
import Config from '../../res/constants/Config';
import { Currency } from '../../utils/enums';
import { currencyResolver } from '../../utils/common';
import { handleExtractMinimumPrice } from './Common';

interface Props {
  item: IMenu;
  currency?: Currency;
}
const CONTAINER_WIDTH = Config.isWeb ? 250 : Layout.window.width * 0.5;
const CONTAINER_HEIGHT = 45;
const BORDER_WIDTH = 4;
const AddMenuItem: FC<Props> = ({ item, currency }) => {
  //#region HOOKS
  const colorScheme = useColorScheme();
  const dispatch = useAppDispatch();
  const numberOfItems = useAppSelector((state) => selectCartItemsWithId(state, item.key!));
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  return (
    <View style={styles.root}>
      <View
        style={{
          borderWidth: BORDER_WIDTH,
          width: CONTAINER_WIDTH * 0.65,
          borderRadius: CONTAINER_HEIGHT / 2,
          borderColor: 'white',
          justifyContent: 'center',
          paddingEnd: CONTAINER_WIDTH * 0.2,
        }}
      >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <CText numberOfLines={1} ellipsizeMode="tail" fontType="subtitle1">
            {`${currencyResolver(currency)?.symbol} ${handleExtractMinimumPrice(item.price)}`}
          </CText>
        </View>
      </View>
      <View
        style={{
          borderWidth: BORDER_WIDTH,
          width: CONTAINER_WIDTH * 0.6,
          marginStart: -CONTAINER_WIDTH * 0.25,
          borderRadius: CONTAINER_HEIGHT / 2,
          borderColor: 'white',
          backgroundColor: MATERIAL_COLORS.grey_400,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <IconButton iconColor={theme.text} icon={'minus'} size={20} onPress={() => dispatch(removeCartItem(item))} />
        <CText style={{ textAlign: 'center', flex: 1 }} fontType="subtitle1">
          {numberOfItems.length}
        </CText>
        <IconButton
          iconColor={theme.text}
          icon={'plus'}
          size={20}
          onPress={() => {
            const price = typeof item.price === 'number' ? item.price : item.price[0].value;
            // const price = item.price[0].value;
            dispatch(addCartItem({ ...item, price }));
          }}
        />
      </View>
    </View>
  );
};

export default AddMenuItem;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    backgroundColor: MATERIAL_COLORS.grey_200,
    width: CONTAINER_WIDTH,
    borderRadius: CONTAINER_HEIGHT / 2,
    height: CONTAINER_HEIGHT,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
});
