import { Platform } from 'react-native';
import { AuthenticationProvider as Web } from './AuthenticationProvider.web';
import { AuthenticationProvider as Mob } from './AuthenticationProvider.mobile';
import { default as useWeb } from './AuthenticationProvider.web';
import { default as useMob } from './AuthenticationProvider.mobile';

export const AuthenticationProvider = Platform.select({
  web: () => Web,
  default: () => Mob,
})();

export const useAuth = Platform.select({
  web: () => useWeb,
  default: () => useMob,
})();
