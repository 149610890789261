export const sizes = {
  small: "16px",
  medium: "24px",
  large: "32px",
  xlarge: "48px",
};

export const strokes = {
  light: "1px",
  regular: "1.5px",
  bold: "2px",
};
