import { StyleSheet, TextInput, View } from 'react-native';
import React, { FC } from 'react';
import { View as RootView, ViewProps } from '../../components/Themed';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { CloseSquare, Search } from '../../../assets/icons';
import Config from '../../res/constants/Config';
import { DEFAULT_ITEM_HEIGHT } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import { materialColorProvider } from '../../utils/common';
import CustomPressable from '../pressable/CustomPressable';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

interface Props extends ViewProps {
  query: string;
  setQuery: (item: string) => void;
}
const SearchBar: FC<Props> = ({ query, setQuery, ...restOfProps }) => {
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  return (
    <RootView style={[restOfProps.style, styles.root]}>
      <View style={styles.iconContainer}>
        <Search />
      </View>
      <TextInput
        value={query}
        onChangeText={setQuery}
        placeholderTextColor={theme.placeholder}
        placeholder={translate('general.search').toString()}
        style={[styles.input, { color: theme.text, borderWidth: 0 }]}
      />
      {query !== '' && (
        <View style={{ width: DEFAULT_ITEM_HEIGHT, height: DEFAULT_ITEM_HEIGHT }}>
          <CustomPressable style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }} onPress={() => setQuery('')}>
            <CloseSquare set="bold" size={15} />
          </CustomPressable>
        </View>
      )}
    </RootView>
  );
};

export default SearchBar;

const styles = StyleSheet.create({
  root: {
    borderRadius: Spacing.defaultRadius,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  iconContainer: { height: DEFAULT_ITEM_HEIGHT, width: DEFAULT_ITEM_HEIGHT, alignItems: 'center', justifyContent: 'center' },
  input: {
    flex: 1,
    fontSize: 16,
    letterSpacing: 0.5,
    height: DEFAULT_ITEM_HEIGHT,
    ...(Config.isWeb && { outlineStyle: 'none' }),
  },
});
