export enum UserRole {
  SIMPLE,
  BACKOFFICE,
  MANAGER,
  DEVELOPER,
  ACCOUNT,
  INACTIVE,
}

export enum FormType {
  NEW,
  EDIT,
  VIEW,
}

export enum Orientation {
  HORIZONTAL,
  VERTICAL,
}

export enum AuthType {
  LOGIN,
  REGISTER,
}

export enum TableType {
  INDOOR,
  OUTDOOR,
}

export enum Currency {
  DOLLAR,
  EURO,
  LEK,
}

export enum ItemStatus {
  ACTIVE,
  INACTIVE,
  PENDING,
  COMPLETED,
}

export const enumToList = (enumObj: any) => {
  return Object.keys(enumObj)
    .filter((key) => !isNaN(Number(key))) // filter out the numeric keys
    .map((key) => {
      return {
        id: Number(key),
        title: enumObj[key].charAt(0).toUpperCase() + enumObj[key].slice(1).toLowerCase(),
      };
    });
};
