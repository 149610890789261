import { firestore, messaging } from './firebase_config';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { Messaging, getToken, onMessage } from 'firebase/messaging';
import { WEB_VAPID_KEY } from '@env';
import { Alert } from 'react-native';

export const FCM_TOKEN_COLLECTION = 'fcmTokens';
export const FCM_TOKEN_KEY = 'fcmToken'; // key for storing FCM token in Firestore

// Requests permissions to show notifications.
async function requestNotificationsPermissions(uid: string) {
  console.debug('Requesting notifications permission...');
  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    console.debug('Notification permission granted.');
    // Notification permission granted.
    await saveMessagingDeviceToken(uid);
  } else {
    console.debug('Unable to get permission to notify.');
  }
}

// Saves the messaging device token to Cloud Firestore.
export async function saveMessagingDeviceToken(uid: string) {
  try {
    const msg: Messaging | boolean = await messaging();

    if (msg) {
      const fcmToken = await getToken(msg, { vapidKey: WEB_VAPID_KEY });
      if (fcmToken) {
        // console.log('Got FCM device token:', fcmToken);
        // Save device token to Firestore
        const tokenRef = doc(firestore, 'users', uid);

        await updateDoc(tokenRef, {
          fcmTokens: arrayUnion(fcmToken),
        });
        // This will fire when a message is received while the app is in the foreground.
        // When the app is in the background, firebase-messaging-sw.js will receive the message instead.
        onMessage(msg, async (message) => {
          console.debug('New foreground notification from Firebase Messaging!', message.data);

          if (message.data) {
            const registration = await navigator.serviceWorker.getRegistration();
            if (registration) {
              registration.showNotification(message.data.title, { body: message.data.body });
            }
          }
        });
      } else {
        // Need to request permissions to show notifications.
        requestNotificationsPermissions(uid);
      }
    } else {
      Alert.alert('Error', 'Unable to get permissions', [
        {
          text: 'Cancel',
          onPress: () => console.debug('Cancel Pressed'),
          style: 'cancel',
        },
        { text: 'OK', onPress: () => console.debug('OK Pressed') },
      ]);
    }
  } catch (error) {
    console.error('Unable to get messaging token.', error);
  }
}
