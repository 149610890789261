import { View } from 'react-native';
import React, { FC } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import EditMenuScreen from '../../screens/private/menu/web/EditMenuScreen';
import MenuScreen from '../../screens/public/MenuScreen';
import PrivateNavigator from './PrivateNavigator';
import { PrivateStackParamList } from './types';
import MenuDetailScreen from '../../screens/public/MenuDetailScreen';
import CartScreen from '../../screens/public/CartScreen';
import EditTableScreen from '../../screens/private/table/web/EditTableScreen';
import EditStaffScreen from '../../screens/private/staff/web/EditStaffScreen';
import CustomCartIcon from '../../components/pressable/CustomCartIcon';
import Spacing from '../../res/constants/Spacing';
import OrderDetailScreen from '../../screens/private/OrderDetailScreen';
import OrderStatusScreen from '../../screens/public/OrderStatusScreen';
import useLocalization from '../providers/localization/LocalizationProvider';
import { useFirestore } from '../providers/firestore';
import SettingsScreen from '../../screens/private/SettingsScreen';
import CountryPicker from '../../components/countries/CountryPicker';
import EditTranslationsScreen from '../../screens/private/EditTranslationsScreen';
import OrdersScreenRefactor from '../../screens/private/OrdersScreenRefactor';
import { HeaderTitle } from './common';
import StaffDetailScreen from '../../screens/private/staff/mobile/StaffDetailScreen';
import TableDetailScreen from '../../screens/private/table/mobile/TableDetailScreen';
import EditMenuDetailScreen from '../../screens/private/menu/mobile/EditMenuDetailScreen';
import AccountScreen from '../../screens/private/AccountScreen';
import MenuDetailScreenRefactor from '../../screens/public/MenuDetailScreenRefactor';
import EditSponsorScreen from '../../screens/private/sponsors/web/EditSponsorScreen';
import SponsorDetailScreen from '../../screens/private/sponsors/mobile/SponsorDetailScreen';

interface Props {}
const RootStack = createNativeStackNavigator<PrivateStackParamList>();
const RootNavigator: FC<Props> = ({}) => {
  const { translate } = useLocalization();
  const { currentUser } = useFirestore();

  return (
    <RootStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <RootStack.Screen name="Root" component={PrivateNavigator} />
      <RootStack.Group screenOptions={{ presentation: 'modal', headerShown: true }}>
        <RootStack.Screen
          name="EditMenu"
          component={EditMenuScreen}
          options={({ navigation, route }) => ({
            headerTitle: HeaderTitle(route.params.type, translate, 'menu', currentUser?.role),
          })}
        />
        <RootStack.Screen
          name="EditMenuDetail"
          component={EditMenuDetailScreen}
          options={({ navigation, route }) => ({
            headerTitle: 'Detail',
          })}
        />
        <RootStack.Screen
          name="EditTable"
          component={EditTableScreen}
          options={({ navigation, route }) => ({
            headerTitle: HeaderTitle(route.params.type, translate, 'table', currentUser?.role),
          })}
        />
        <RootStack.Screen
          name="TableDetail"
          component={TableDetailScreen}
          options={({ navigation, route }) => ({
            headerTitle: 'Detail',
          })}
        />
        <RootStack.Screen
          name="EditStaff"
          component={EditStaffScreen}
          options={({ route }) => ({
            title: HeaderTitle(route.params.type, translate, 'staff', currentUser?.role),
          })}
        />
        <RootStack.Screen
          name="StaffDetail"
          component={StaffDetailScreen}
          options={({ route }) => ({
            title: 'Staff Detail',
          })}
        />
        <RootStack.Screen
          name="EditSponsor"
          component={EditSponsorScreen}
          options={({ route }) => ({
            title: HeaderTitle(route.params.type, translate, 'staff', currentUser?.role),
          })}
        />
        <RootStack.Screen
          name="SponsorDetail"
          component={SponsorDetailScreen}
          options={({ route }) => ({
            title: 'Sponsor Detail',
          })}
        />
        <RootStack.Screen
          name="EditTranslations"
          component={EditTranslationsScreen}
          options={({ navigation, route }) => ({
            title: 'Translations',
          })}
        />
        <RootStack.Screen
          name="Menu"
          component={MenuScreen}
          options={({ navigation, route }) => ({
            title: 'Menu',
            headerRight: () => {
              return (
                <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: Spacing.regular }}>
                  <CountryPicker showText={false} />
                  <CustomCartIcon businessId={route.params.business} tableKey={route.params.table} />
                </View>
              );
            },
          })}
        />
        <RootStack.Screen
          name="MenuDetail"
          component={MenuDetailScreen}
          options={({ navigation, route }) => ({
            title: 'Menu',
            headerShown: false,
          })}
        />
        <RootStack.Screen
          name="MenuDetailRefactor"
          component={MenuDetailScreenRefactor}
          options={({ navigation, route }) => ({
            title: 'Menu',
            headerShown: false,
          })}
        />
        <RootStack.Screen name="Cart" component={CartScreen} options={{ headerShown: true, title: translate('pages.check_out.title').toString() }} />
        <RootStack.Screen
          name="Orders"
          component={OrdersScreenRefactor}
          options={{
            headerShown: true,
            title: translate('pages.orders.title').toString(),
          }}
        />
        <RootStack.Screen
          name="OrderDetail"
          component={OrderDetailScreen}
          options={{
            headerShown: true,
            title: 'Order Detail',
          }}
        />
        <RootStack.Screen
          name="OrderStatus"
          component={OrderStatusScreen}
          options={({ navigation, route }) => ({
            title: translate('pages.order_status.title').toString(),
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="Settings"
          component={SettingsScreen}
          options={({ navigation, route }) => ({
            title: translate('pages.settings.title').toString(),
            headerShown: true,
          })}
        />
        <RootStack.Screen
          name="Account"
          component={AccountScreen}
          options={({ navigation, route }) => ({
            title: 'Account',
            headerShown: true,
          })}
        />
      </RootStack.Group>
    </RootStack.Navigator>
  );
};

export default RootNavigator;
