import { createSlice } from '@reduxjs/toolkit';
import { IGeneralItem } from '../../../models/IGeneralItem';

interface InitialState {
  selectedDepartment: number;
}

const initialState: InitialState = {
  selectedDepartment: 0,
};

export const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setSelectedDepartment: (state, action) => {
      state.selectedDepartment = action.payload;
    },

    clearSelectedDepartment: (state) => {
      state.selectedDepartment = 0;
    },
  },
});

export const { setSelectedDepartment, clearSelectedDepartment } = departmentsSlice.actions;
export default departmentsSlice.reducer;
