import { FlatList, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { useRef } from 'react';
import { PublicStackScreenProps } from '../../app/navigation/types';
import { View as RootView } from '../../components/Themed';
import { Divider, IconButton, Text } from 'react-native-paper';
import { AppImages } from '../../../assets/images';
import Spacing from '../../res/constants/Spacing';
import { useMediaQuery } from 'react-responsive';
import Config from '../../res/constants/Config';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import Layout from '../../res/constants/Layout';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import Title from '../../components/landing/Title';
import LandingAnimatedItem from '../../components/landing/LandingAnimatedItem';
import SideMenu, { SideMenuViewRef } from '../../components/expandable/SideMenu';
import MenuItems from '../../components/landing/MenuItems';
import CountryPicker from '../../components/countries/CountryPicker';
import ProfilePicture from '../../components/images/ProfilePicture';

const HEADERHEIGHT = 80;
const FOOTER_HEIGHT = 180;
export interface ILandingModel {
  key: number;
  headerId?: number; //For scrolling on click
  question?: string;
  title?: string;
  subtitle?: string;
  isHeader?: boolean;
  backgroundColor: string;
}

const LandingPageScreen = ({ navigation }: PublicStackScreenProps<'LandingPage'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  //#region REFERENCES
  const flatListRef = useRef<FlatList<ILandingModel>>(null);
  const sideRef = useRef<SideMenuViewRef | null>(null);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { translate, locale } = useLocalization();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  const items = translate('pages.landing.items') as ILandingModel[];

  //#endregion
  //#region FUNCTIONS

  const renderSectionHeader = ({ section }: { section: ILandingModel }) => (
    <TouchableOpacity activeOpacity={1} onPress={() => onHeaderPress(section.headerId)} style={{ height: HEADERHEIGHT }}>
      <View style={{ flex: 1, backgroundColor: section.backgroundColor, alignItems: 'center', justifyContent: 'center' }}>
        <Title title={section.question} />
      </View>
    </TouchableOpacity>
  );

  const onHeaderPress = (index?: number) => {
    if (index === 6) {
      flatListRef.current?.scrollToEnd({ animated: true });
      return;
    }
    if (index) {
      const offset = index * (Layout.window.height - HEADERHEIGHT);
      flatListRef.current?.scrollToOffset({ offset, animated: true });
    } else {
      flatListRef.current?.scrollToOffset({ offset: 0, animated: true });
    }
  };

  const handleOnMenuItemPress = (index: number) => {
    switch (index) {
      case 0:
        navigation.navigate('Login');
        break;
      case 2:
        sideRef.current?.dismiss();
        flatListRef.current?.scrollToEnd();
        break;
      case 3:
        navigation.navigate('TermsAndConditions');
        break;
      default:
        break;
    }
  };
  //#endregion

  return (
    <RootView style={styles.root}>
      <View style={[styles.header, { backgroundColor: '#FFF5F8', height: HEADERHEIGHT }]}>
        <Image source={AppImages.main_icon2} style={{ height: 48, width: 48, marginStart: Spacing.regular }} />
        <Text style={{ marginStart: Spacing.regular, flex: 1, color: MATERIAL_COLORS.blue_grey_800 }} variant="titleMedium">
          {'Menu\nAmore Mio'}
        </Text>
        <View
          style={{
            flexDirection: isTabletOrMobile ? 'row' : 'row-reverse',
            alignItems: 'center',
            paddingHorizontal: !isTabletOrMobile ? Spacing.regular : 0,
          }}
        >
          <CountryPicker showText={false} />
          {isTabletOrMobile || !Config.isWeb ? (
            <IconButton iconColor={theme.text} icon={'menu'} onPress={() => sideRef.current?.present()} />
          ) : (
            <MenuItems onPress={handleOnMenuItemPress} />
          )}
        </View>
      </View>

      <FlatList
        ref={flatListRef}
        initialNumToRender={3}
        ListFooterComponent={() => (
          <View style={{ height: FOOTER_HEIGHT, backgroundColor: '#eaf2fb', flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              <View style={{ alignItems: 'flex-start', paddingHorizontal: Spacing.medium, gap: Spacing.regular }}>
                <Text variant="titleSmall">DAMEN OUTSOURCING SERVICES SRL, Sat Livada, Comuna Livada, Nr. 655, Judet Arad, Romania</Text>
                <Text style={{ textDecorationLine: 'underline' }} variant="bodyMedium">
                  Email: info@menuamoremio.com
                </Text>
              </View>
            </View>
          </View>
        )}
        data={items}
        renderItem={({ item }) =>
          item.isHeader ? renderSectionHeader({ section: item }) : <LandingAnimatedItem item={item} height={HEADERHEIGHT} />
        }
        keyExtractor={(item) => item.key.toString()}
        stickyHeaderIndices={items.filter((item) => item.isHeader).map((item) => item.key)}
      />
      <SideMenu ref={sideRef}>
        <View style={{ alignItems: 'center', paddingVertical: Spacing.big }}>
          <ProfilePicture source={AppImages.main_icon2} size={100} hasRadius={true} />
          <Text style={{ marginTop: Spacing.medium }} variant="titleMedium">
            Menu Amore Mio
          </Text>
        </View>
        <Divider />
        <MenuItems onPress={handleOnMenuItemPress} />

        <View style={{ alignItems: 'center', padding: Spacing.regular }}>
          <Text variant="titleSmall" style={{ textAlign: 'center' }}>
            DAMEN OUTSOURCING SERVICES SRL
          </Text>
        </View>
      </SideMenu>
    </RootView>
  );
};

export default LandingPageScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  header: {
    height: HEADERHEIGHT,
    backgroundColor: MATERIAL_COLORS.grey_50,
    alignItems: 'center',
    flexDirection: 'row',
  },
});
