import { StyleSheet, Text, View } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { PrivateStackScreenProps } from '../../../../app/navigation/types';
import MenuForm from '../MenuForm';
import { useFocusEffect } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import { useFirestore } from '../../../../app/providers/firestore';
import { useAppSelector } from '../../../../hooks/hooks';
import useToggle from '../../../../hooks/useToggle';
import Styles from '../../../../res/constants/Styles';
import collections from '../../../../utils/collections';
import { FormType, UserRole } from '../../../../utils/enums';
import useColorScheme from '../../../../hooks/useColorScheme';
import { IMenu } from '../../../../models/IMenu';
import Spacing from '../../../../res/constants/Spacing';

interface Props {}
const EditMenuDetailScreen = ({ navigation, route }: PrivateStackScreenProps<'EditMenuDetail'>) => {
  // #region MEMBERS
  const key: string | undefined = route.params.key;
  const formType: FormType = route.params.type;
  const archivedMode: boolean = route.params.archivedMode;
  // #endregion
  // #region STATES
  const [currentModel, setCurrentModel] = useState<IMenu | undefined>(undefined);
  const [confirmDialogVisibility, toggleConfirmDialog] = useToggle(false);
  const [loading, toggleLoading] = useToggle(false);
  // #endregion
  // #region CUSTOM HOOKS
  const { handleGetDocument, currentUser } = useFirestore();
  const currentSelectedUser = useAppSelector((state) => state.backOffice.selectedUser);
  const colorScheme = useColorScheme();
  const localTheme = Styles[colorScheme];

  // #endregion
  // #region FUNCTIONS
  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
        else return currentUser.uid;
      } else {
        return currentUser.uid;
      }
    }
  };

  const handleClearObject = () => {
    setCurrentModel(undefined);
    navigation.navigate('EditMenu', { key, type: FormType.EDIT });
  };

  // #endregion
  // #region EFFECTS
  useFocusEffect(
    useCallback(() => {
      const uid = handleTargetUid(); // FIXME: Nuk eshte ok, duhet pare
      if (uid === undefined || key === undefined) return;

      toggleLoading(true);

      handleGetDocument(uid, key, collections.menu).then((value) => {
        setCurrentModel(value);
        toggleLoading(false);
      });
    }, [currentUser, currentSelectedUser, key])
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        currentModel !== undefined && <IconButton icon={archivedMode ? 'delete-restore' : 'delete'} onPress={() => toggleConfirmDialog()} />,
    });
  }, [currentModel, archivedMode, key]);
  // #endregion

  return (
    <View style={[styles.root, { backgroundColor: localTheme.lightSelectedItem }]}>
      <MenuForm {...{ currentModel, formType, archivedMode, confirmDialogVisibility, toggleConfirmDialog }} handleClearObject={handleClearObject} />
    </View>
  );
};

export default EditMenuDetailScreen;

const styles = StyleSheet.create({ root: { flex: 1, padding: Spacing.regular } });
