import { StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { IMenu } from '../../models/IMenu';
import { Currency } from '../../utils/enums';
import { IPrice } from '../../models/IPrice';
import Spacing from '../../res/constants/Spacing';
import PriceSelection from './PriceSelection';
import { handleExtractMinimumPrice } from './Common';
import { Text } from 'react-native-paper';
import { currencyResolver } from '../../utils/common';
import { addCartItem, removeCartItem, selectCartItemsWithId } from '../../app/features/cart/cartSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import AddMenuItem from './AddMenuItem';

const CONTAINER_HEIGHT = 45;

interface Props {
  currentMenu: IMenu;
  currency?: Currency;
}

const AddArticle: FC<Props> = ({ currentMenu, currency }) => {
  // #region STATES
  const [currentPrice, setCurrentPrice] = useState(handleExtractMinimumPrice(currentMenu.price));
  // #endregion
  // #region CUSTOM HOOKS
  const dispatch = useAppDispatch();
  const numberOfItems = useAppSelector((state) => selectCartItemsWithId(state, currentMenu.key!));
  // #endregion
  // #region FUNCTIONS
  const resolvePrice = () => {
    let temporary: IPrice[] = [];
    if (typeof currentMenu.price === 'number' || typeof currentMenu.price === 'string') {
      temporary.push({ id: 0, title: 'M', value: Number(currentMenu.price) });
    } else {
      temporary = currentMenu.price;
    }
    return temporary.sort((a: IPrice, b: IPrice) => a.value - b.value);
  };

  const handleOnChangeSize = useCallback((value: IPrice) => {
    setCurrentPrice(value.value);
  }, []);
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <View style={styles.root}>
      {resolvePrice().length > 1 ? (
        <>
          <Text variant="titleLarge">{currency ? `${currencyResolver(currency)?.symbol} ${currentPrice}` : currentPrice}</Text>
          <PriceSelection
            currentPrice={currentPrice}
            options={resolvePrice()}
            size={CONTAINER_HEIGHT}
            onChange={handleOnChangeSize}
            onAdd={() => dispatch(addCartItem({ ...currentMenu, price: currentPrice }))}
            onRemove={() => dispatch(removeCartItem({ ...currentMenu, price: currentPrice }))}
          />
        </>
      ) : (
        <AddMenuItem item={currentMenu} currency={currency} />
      )}
    </View>
  );
};

export default AddArticle;

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    padding: Spacing.micro,
    borderRadius: CONTAINER_HEIGHT / 2,
    gap: Spacing.regular,
  },
});
