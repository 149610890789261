import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { Button, Checkbox, Text } from 'react-native-paper';
import CustomPressable from '../../pressable/CustomPressable';
import Spacing from '../../../res/constants/Spacing';
import RadioButton from '../../radio_buttons/RadioButton';

interface Props {
  selected: boolean;
  index: number;
  setSelected: (value: number) => void;
  title: string;
}
const CurrencyListItem: FC<Props> = ({ selected, index, setSelected, title }) => {
  return (
    <CustomPressable style={styles.root} onPress={() => setSelected(index)}>
      <RadioButton checked={selected} />
      <Text style={{ marginStart: Spacing.regular }} variant="bodyLarge">
        {title}
      </Text>
    </CustomPressable>
  );
};

export default CurrencyListItem;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingVertical: Spacing.medium,
  },
});
