import { SectionList, StyleSheet, View, FlatList } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useFirestore } from '../../app/providers/firestore';
import { IMenu } from '../../models/IMenu';
import useColorScheme from '../../hooks/useColorScheme';
import collections from '../../utils/collections';
import { View as RootView } from '../../components/Themed';
import CText from '../../components/CText';
import Spacing from '../../res/constants/Spacing';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { IUser } from '../../models/IUser';
import ArticleListItem from '../../components/menu/articles/ArticleListItem';

const MenuScreen = ({ navigation, route }: any) => {
  //#region MEMBERS
  const business = route.params.business;
  const table = route.params.table;
  //#endregion
  //#region STATES
  const [menu, setMenu] = useState<IMenu[]>();
  const [currentBusiness, setCurrentBusiness] = useState<IUser | undefined>(undefined);
  //#endregion
  //#region HOOKS
  const { handleGetCollection, handleGetUserDocument } = useFirestore();
  const { translate } = useLocalization();
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    handleGetCollection(business, collections.menu).then((value) => setMenu(value));
    handleGetUserDocument(business).then(setCurrentBusiness);
  }, [business]);
  //#endregion
  //#region ACTIONS
  if (menu === undefined || currentBusiness === undefined) {
    return null;
  }
  //#endregion
  //#region FUNCTIONS

  const handleGetMenuCategoryTitle = (id?: number) => {
    if (id !== undefined && id !== null) {
      return menuCategory[id].title;
    } else return '';
  };

  //#endregion
  //#region MEMBERS

  const menuCategory = translate('pages.menu.category') as { id: number; title: string }[];

  const groupedItems = menu.reduce((acc, item) => {
    const title = handleGetMenuCategoryTitle(item.categoryId);

    const groupIndex = acc.findIndex((group) => group.title === title);

    if (groupIndex === -1) {
      acc.push({ title, data: [item] });
    } else {
      acc[groupIndex].data.push(item);
    }

    return acc;
  }, [] as Array<{ title: string; data: IMenu[] }>);

  //#endregion

  return (
    <RootView style={styles.root}>
      <SectionList
        showsVerticalScrollIndicator={false}
        sections={groupedItems.sort((a, b) => {
          const order = [
            menuCategory[35].title,
            menuCategory[34].title,
            menuCategory[32].title,
            menuCategory[36].title,
            menuCategory[7].title, // Salad
            menuCategory[60].title, // Bruschette
            menuCategory[0].title, // Antipasti
            menuCategory[61].title, // Risotti
            menuCategory[6].title, // Pasta
            menuCategory[1].title, // Carni
            menuCategory[2].title,
            menuCategory[3].title,
            menuCategory[30].title, // Sushi
            menuCategory[4].title,
            menuCategory[29].title,
            menuCategory[20].title,
            menuCategory[21].title,
            menuCategory[22].title,
            menuCategory[5].title,
            menuCategory[49].title,
            menuCategory[50].title,
            menuCategory[14].title,
            menuCategory[15].title,
            menuCategory[16].title,
            menuCategory[17].title,
            menuCategory[51].title,
            menuCategory[8].title,
            menuCategory[9].title,
            menuCategory[46].title,
            menuCategory[47].title,
            menuCategory[48].title,
            menuCategory[37].title,
            menuCategory[33].title,
            menuCategory[31].title,
            menuCategory[11].title,
            menuCategory[52].title,
            menuCategory[53].title,
            menuCategory[12].title,
            menuCategory[23].title,
            menuCategory[10].title,
            menuCategory[24].title,
            menuCategory[25].title,
            menuCategory[26].title,
            menuCategory[13].title,
            menuCategory[18].title,
            menuCategory[19].title,
            menuCategory[27].title,
            menuCategory[28].title,
            menuCategory[38].title,
            menuCategory[41].title,
            menuCategory[44].title,
            menuCategory[42].title,
            menuCategory[40].title,
            menuCategory[49].title,
            menuCategory[39].title,
            menuCategory[43].title,
            menuCategory[45].title,
            menuCategory[62].title,
          ];
          return order.indexOf(a.title) - order.indexOf(b.title);
        })}
        keyExtractor={(item, index) => item.key! + index}
        renderItem={() => null}
        renderSectionHeader={({ section: { title, data } }) => {
          return (
            <View style={{ padding: Spacing.medium }}>
              <CText fontType="header5">{title}</CText>
              <FlatList
                horizontal
                data={data}
                renderItem={({ item, index }) => {
                  return (
                    <ArticleListItem
                      key={index}
                      item={item}
                      onItemPress={() => {
                        navigation.navigate('MenuDetailRefactor', { business, table, menuKey: item.key! });
                      }}
                      currentBusiness={currentBusiness}
                    />
                  );
                }}
                showsHorizontalScrollIndicator={false}
              />
            </View>
          );
        }}
      />
    </RootView>
  );
};

export default MenuScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
