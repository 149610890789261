import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import React, { FC } from 'react';

interface Props {}
const ActivityIndicatorContainer: FC<Props> = ({}) => {
  return (
    <View style={styles.root}>
      <ActivityIndicator />
    </View>
  );
};

export default ActivityIndicatorContainer;

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
