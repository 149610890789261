import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import CustomPressable from './CustomPressable';
import CText from '../CText';
import useColorScheme from '../../hooks/useColorScheme';
import { ViewProps } from '../Themed';
import { DEFAULT_ITEM_HEIGHT } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import Iconly from '../iconly/mapIcons';
import { IconName } from '../iconly/types';
import Styles from '../../res/constants/Styles';

interface Props extends ViewProps {
  title: string;
  onPress: () => void;
  iconName?: IconName;
}
const SaveButton: FC<Props> = ({ title, onPress, iconName, ...restOfProps }) => {
  //#region HOOKS
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  return (
    <View style={[styles.root, { backgroundColor: theme.primary }, restOfProps.style]}>
      <CustomPressable style={styles.button} onPress={onPress}>
        <View style={styles.container}>
          {iconName && <Iconly name={iconName} primaryColor={theme.onPrimary} />}
          <CText style={{ color: theme.onPrimary, marginStart: Spacing.regular }} fontType="button">
            {title}
          </CText>
        </View>
      </CustomPressable>
    </View>
  );
};

export default SaveButton;

const styles = StyleSheet.create({
  root: {
    borderRadius: Spacing.defaultRadius,
    overflow: 'hidden',
    height: DEFAULT_ITEM_HEIGHT,
  },
  button: { alignItems: 'center', flex: 1, justifyContent: 'center' },
  container: { flexDirection: 'row', alignItems: 'center' },
});
