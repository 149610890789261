import React from 'react';
import { ColorSchemeName } from 'react-native';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { useAuth } from '../providers/authentication';
import { linking } from './linking';
import RootNavigator from './RootNavigator';
import PublicNavigator from './PublicNavigator';
import { useFirestore } from '../providers/firestore';
import { UserRole } from '../../utils/enums';
import SimpleUserNavigator from './SimpleUserNavigator';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  const { user } = useAuth();
  const { currentUser } = useFirestore();

  return (
    <NavigationContainer linking={linking} theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      {user ? currentUser?.role === UserRole.SIMPLE ? <SimpleUserNavigator /> : <RootNavigator /> : <PublicNavigator />}
    </NavigationContainer>
  );
}
