import { FlatList, StyleSheet, View, Image } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Buy } from '../../../assets/icons';
import { selectCartTotal, removeCartItem, clearCart } from '../../app/features/cart/cartSlice';
import CText from '../../components/CText';
import CustomPressable from '../../components/pressable/CustomPressable';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { IMenu } from '../../models/IMenu';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { DEFAULT_GAP } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import { Orientation } from '../../utils/enums';
import { View as RootView } from '../../components/Themed';
import { SafeAreaView } from 'react-native-safe-area-context';
import GeneralSeparator from '../../components/separator/GeneralSeparator';
import CheckOutDialog from '../../components/dialogs/check_out/CheckOutDialog';
import { useFirestore } from '../../app/providers/firestore';
import collections from '../../utils/collections';
import { IMeta } from '../../models/IMeta';
import { IOrder } from '../../models/IOrder';
import { IUser } from '../../models/IUser';
import { currencyResolver } from '../../utils/common';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

const CartScreen = ({ navigation, route }: any) => {
  //#region STATES
  const [checkOutVisible, setCheckOutVisible] = useState<boolean>(false);
  const [currentBusiness, setCurrentBusiness] = useState<IUser | undefined>(undefined);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  const dispatch = useAppDispatch();
  const cartItems: IMenu[] = useAppSelector((state) => state.cart.items);
  const total: number = useAppSelector(selectCartTotal);
  const [groupedItemsInCart, setGroupedItemsInCart] = useState<any[]>([]);
  const { handleGetDocument, handleDataUnAuthorized, handleGetUserDocument, loading } = useFirestore();
  const business = route.params.business;
  const table = route.params.table;
  const { translate } = useLocalization();
  useMemo(() => {
    const groupedItems = cartItems.reduce((accumulator: any, currentValue: any) => {
      accumulator[currentValue.key] = accumulator[currentValue.key] || [];
      accumulator[currentValue.key].push(currentValue);
      return accumulator;
    }, {});

    setGroupedItemsInCart(groupedItems);
  }, [cartItems]);

  //#endregion
  //#region EFFECTS
  useEffect(() => {
    if (business) {
      handleGetUserDocument(business).then(setCurrentBusiness);
    }
  }, [business]);
  //#endregion

  //#region ACTIONS
  if (currentBusiness === undefined) return null;

  //#endregion
  //#region FUNCTIONS
  const handleOnBuyClick = () => {
    handleGetDocument(business, table, collections.table).then((value) => {
      if (value === undefined) return;

      if (value.category === 2) {
        setCheckOutVisible(true);
      } else {
        const meta: IMeta = { created: new Date().getTime(), isActive: true, isPending: true, isCompleted: false };

        const payload: IOrder = {
          customerName: '',
          customerPhone: '',
          customerAddress: '',
          meta,
          tableKey: table,
          cartItems,
        };

        handleDataUnAuthorized(business, payload, collections.orders).then((value) => {
          dispatch(clearCart());
          navigation.navigate('OrderStatus' as never, { key: value, uid: business } as never);
        });
      }
    });
  };
  //#endregion
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: theme.primary }} edges={['bottom']}>
      <RootView style={styles.root}>
        <FlatList
          ItemSeparatorComponent={() => <GeneralSeparator orientation={Orientation.HORIZONTAL} size={'90%'} color={MATERIAL_COLORS.grey_300} />}
          data={Object.entries(groupedItemsInCart).map(([key, data]) => ({ key, data }))}
          keyExtractor={(item) => item?.key?.toString()}
          contentContainerStyle={{ gap: DEFAULT_GAP }}
          renderItem={({ item, index }) => {
            return (
              <View key={index} style={{ flexDirection: 'row', alignItems: 'center', padding: Spacing.medium }}>
                <CText fontType="body2">{`${item.data.length} x`}</CText>
                <Image source={{ uri: item.data[0].image }} style={{ height: 50, width: 50, borderRadius: 25, marginHorizontal: Spacing.regular }} />
                <CText style={{ flex: 1, paddingHorizontal: Spacing.regular }} fontType="body2">
                  {item.data[0].name}
                </CText>
                <CustomPressable
                  style={{ paddingVertical: Spacing.regular, minWidth: 60, alignItems: 'center', justifyContent: 'center' }}
                  onPress={() => dispatch(removeCartItem(item.data[0]))}
                >
                  <CText style={{ color: theme.primary }} fontType="body2">
                    {translate('general.remove')}
                  </CText>
                </CustomPressable>
              </View>
            );
          }}
        />
        {cartItems.length !== 0 && (
          <View style={{ backgroundColor: theme.primary, padding: Spacing.regular, flexDirection: 'row', alignItems: 'center' }}>
            <CText style={{ color: theme.onPrimary, flex: 1 }} fontType="header6">{`${translate('general.total')}: ${
              currencyResolver(currentBusiness.currency)?.symbol
            } ${total}`}</CText>

            <View style={{ backgroundColor: 'rgba(255,255,255,0.3)', borderRadius: 100, overflow: 'hidden' }}>
              <CustomPressable disabled={loading} style={{ padding: Spacing.medium }} onPress={handleOnBuyClick}>
                <Buy set="bold" primaryColor={theme.onPrimary} />
              </CustomPressable>
            </View>
          </View>
        )}

        <CheckOutDialog
          visible={checkOutVisible}
          setVisible={() => setCheckOutVisible((v) => !v)}
          tableKey={table}
          business={business}
          cartItems={cartItems}
        />
      </RootView>
    </SafeAreaView>
  );
};

export default CartScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
