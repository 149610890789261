import { Alert, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC, useEffect, useState } from 'react';
import { IMenu } from '../../../models/IMenu';
import { FormType, ItemStatus, UserRole } from '../../../utils/enums';
import ProductPicture from '../../../components/images/ProductPicture';
import Spacing from '../../../res/constants/Spacing';
import { Button, Text } from 'react-native-paper';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useFirestore } from '../../../app/providers/firestore';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';
import { useAppSelector } from '../../../hooks/hooks';
import ConfirmDialog from '../../../components/dialogs/confirm/ConfirmDialog';
import collections from '../../../utils/collections';
import { IMeta } from '../../../models/IMeta';
import GeneralSwitch from '../../../components/switch/GeneralSwitch';
import Ingredients from '../../../components/edit_menu/Ingredients';
import ExpandableListContainer from '../../../components/expandable/ExpandableListContainer';
import SelectableList from '../../../components/general/selectable/SelectableList';
import CustomInput from '../../../components/inputs/CustomInput';
import { DEFAULT_GAP } from '../../../res/constants/Layout';
import { IGeneralItem } from '../../../models/IGeneralItem';
import useColorScheme from '../../../hooks/useColorScheme';
import Styles from '../../../res/constants/Styles';
import PriceDialog from '../../../components/dialogs/price/PriceDialog';
import useToggle from '../../../hooks/useToggle';
import { MATERIAL_COLORS } from '../../../res/constants/Colors';

interface Props {
  currentModel?: IMenu;
  formType: FormType;
  handleClearObject: () => void;
  archivedMode: boolean;
  confirmDialogVisibility: boolean;
  toggleConfirmDialog: () => void;
}
const MenuForm: FC<Props> = ({ currentModel, formType, handleClearObject, archivedMode, confirmDialogVisibility, toggleConfirmDialog }) => {
  // #region STATES
  const [switchIndex, setSwitchIndex] = useState<number>(0);
  const [priceDialogVisibility, togglePriceDialog] = useToggle(false);
  const [markedPrice, setMarkedPrice] = useState<{ id: number; title: string; value: number } | undefined>(undefined);
  const [priceDialogType, setPriceDialogType] = useState<FormType>(FormType.NEW);
  // #endregion
  // #region CUSTOM HOOKS
  const { handleDataUnAuthorized, currentUser } = useFirestore();
  const { translate } = useLocalization();
  const colorScheme = useColorScheme();
  const currentSelectedUser = useAppSelector((state) => state.backOffice.selectedUser);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
    trigger,
  } = useForm<IMenu>({ defaultValues: currentModel });

  // #endregion
  // #region MEMBERS
  const options = translate('pages.menu.options') as string[];
  const recipient = translate('pages.menu.recipient') as any[];
  const allergens: IGeneralItem[] = translate('pages.menu.allergens') as [];
  const menuCategory = translate('pages.menu.category') as { id: number; title: string }[];
  const theme = Styles[colorScheme];
  // #endregion
  // #region FUNCTIONS
  const handleOnCategoryGetName = (id?: number) => {
    if (id === undefined) return undefined;
    const foundIndex = menuCategory.findIndex((i) => i.id === id);

    if (foundIndex > -1) {
      return menuCategory[foundIndex].title;
    } else {
      return undefined;
    }
  };

  const handleOnSubCategoryGetName = (id?: number) => {
    if (id === undefined) return undefined;
    const foundIndex = recipient.findIndex((i) => i.id === id);

    if (foundIndex > -1) {
      return recipient[foundIndex].title;
    } else {
      return undefined;
    }
  };

  const handleGetAllergenName = () => {
    let temporary: any[] = [];

    const ids = watch('allergens');

    if (ids) {
      temporary = [...ids];

      const result = allergens.filter((a) => temporary.includes(a.id));

      return result;
    } else return [];
  };

  const handleAddIngredient = (item: any) => {
    const ing = watch('ingredients');

    let temporary: any[] = [];

    if (ing !== undefined && ing !== null) {
      temporary = ing.map((obj) => ({ ...obj }));
    }

    const foundIndex = temporary.findIndex((i) => i.id === item.id);

    if (foundIndex > -1) {
      temporary.splice(foundIndex, 1);
    } else {
      temporary.push(item);
    }

    setValue('ingredients', temporary);
  };

  const handleGetSponsor = () => {
    return [{ id: 0, title: 'Aquila' }];
  };

  const renderItem = ({ index }: { index: number }) => {
    switch (index) {
      case 0:
        return (
          <ScrollView key={index} contentContainerStyle={{ gap: DEFAULT_GAP }} style={{ flex: 1 }}>
            <CustomInput
              error={errors.name}
              required={true}
              control={control}
              name={'name'}
              placeholder={translate('general.name').toString()}
              iconProps={{ leftName: 'Paper', isLeftIconly: true, showRight: false }}
            />

            <Controller
              rules={{ required: true }}
              control={control}
              name={'categoryId'}
              render={({ field: { value, onChange } }) => (
                <ExpandableListContainer
                  error={errors.categoryId}
                  hasSearch={true}
                  title={watch('category') || handleOnCategoryGetName(value)}
                  items={menuCategory}
                  placeholder={translate('general.category').toString()}
                  selectedId={value}
                  onSelectItem={(value) => {
                    onChange(value.id);
                  }}
                  iconProps={{ leftName: 'Category', isLeftIconly: true, rightName: 'sort', isRightIconly: false }}
                />
              )}
            />

            <Controller
              rules={{ required: true }}
              control={control}
              name={'subCategory'}
              render={({ field: { value, onChange } }) => (
                <ExpandableListContainer
                  error={errors.subCategory}
                  hasSearch={true}
                  title={handleOnSubCategoryGetName(value)}
                  items={recipient}
                  placeholder={translate('general.department').toString()}
                  selectedId={value}
                  onSelectItem={(value) => {
                    onChange(value.id);
                  }}
                  iconProps={{ leftName: 'Category', isLeftIconly: true, rightName: 'sort', isRightIconly: false }}
                />
              )}
            />

            <SelectableList
              icon="InfoCircle"
              options={allergens}
              value={handleGetAllergenName()}
              placeHolder={translate('general.allergens_title').toString()}
              multiple={true}
              onChange={(value) => {
                setValue(
                  'allergens',
                  value.map((i) => Number(i.id))
                );
              }}
            />

            <Controller
              rules={{ required: true }}
              control={control}
              name={'price'}
              render={({ field: { value, onChange } }) => (
                <View
                  style={{
                    backgroundColor: theme.background,
                    borderRadius: Spacing.defaultRadius,
                    borderWidth: 1,
                    borderColor: errors.price ? theme.error : 'transparent',
                  }}
                >
                  {value?.length > 0 && (
                    <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row', gap: Spacing.micro, padding: Spacing.regular }}>
                      {value.map((item: { id: number; title: string; value: number }, index: React.Key | null | undefined) => (
                        <TouchableOpacity
                          onPress={() => {
                            setMarkedPrice(item);
                            setPriceDialogType(FormType.EDIT);
                            togglePriceDialog();
                          }}
                          key={index}
                          style={{
                            backgroundColor: MATERIAL_COLORS.light_blue_50,
                            paddingHorizontal: Spacing.small,
                            paddingVertical: Spacing.minimal,
                            borderRadius: Spacing.defaultRadius,
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Text variant="labelMedium">{`${item.title}: ${item.value}`}</Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  )}
                  <Button
                    onPress={() => {
                      togglePriceDialog();
                      setPriceDialogType(FormType.NEW);
                    }}
                  >
                    Add a new price
                  </Button>
                </View>
              )}
            />
          </ScrollView>
        );
      case 1:
        return (
          <View
            key={index}
            style={{
              flex: 1,
            }}
          >
            <Ingredients selectedItems={watch('ingredients')} setSelectedItems={handleAddIngredient} />
          </View>
        );
      case 2:
        return (
          <View
            key={index}
            style={{
              flex: 1,
            }}
          >
            <CustomInput
              error={errors.description}
              required={false}
              control={control}
              name={'description'}
              placeholder={translate('general.description').toString()}
              flex={1}
              iconProps={{ leftName: 'Document', isLeftIconly: true, showRight: false }}
            />
          </View>
        );
      case 3:
        return (
          <View
            key={index}
            style={{
              flex: 1,
            }}
          >
            <CustomInput
              error={errors.info}
              required={false}
              control={control}
              name={'info'}
              placeholder={'Info'}
              flex={1}
              iconProps={{ leftName: 'Document', isLeftIconly: true, showRight: false }}
            />
          </View>
        );
      default:
        return null;
    }
  };

  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
        else Alert.alert('Error', 'You need to select a user');
      } else {
        return currentUser.uid;
      }
    }
  };

  const onSubmit: SubmitHandler<any> = async (params) => {
    if (switchIndex < 2) {
      setSwitchIndex((v) => v + 1);
    } else {
      const { name, categoryId, subCategory, image, price, description, canBeSuggested, ingredients, suggestion, allergens, info } = params;

      const meta: IMeta =
        formType === FormType.NEW
          ? { created: new Date().getTime(), status: ItemStatus.ACTIVE }
          : { ...currentModel?.meta, modified: new Date().getTime() };

      const payload: IMenu = {
        key: currentModel?.key,
        name: name,
        category: null,
        categoryId: categoryId,
        subCategory: subCategory != null ? subCategory : null,
        image: image || null,
        price: price,
        description: description || null,
        meta,
        suggestion: suggestion || null,
        canBeSuggested: canBeSuggested || null,
        ingredients: ingredients || null,
        allergens: allergens || null,
        info: info || null,
      };

      const uid = handleTargetUid();

      if (uid)
        handleDataUnAuthorized(uid, payload, collections.menu).then(() => {
          handleClearForm();
          handleClearObject();
          setSwitchIndex(0);
        });
    }
  };

  const handleClearForm = () => {
    const defaultValues = {
      name: '',
      phone: '',
      meta: {},
    };
    reset(defaultValues);
  };

  const setNewSwitchIndex = async (index: number) => {
    const validated = await trigger();

    if (validated) setSwitchIndex(index);
    else Alert.alert('Error', 'Please enter all the data required.');
  };

  const handleOnChangePrice = (newValue: { id: number; title: string; value: number }) => {
    let currentPrices = getValues('price');

    if (!Array.isArray(currentPrices)) {
      currentPrices = [];
    }

    // find the index of the item with the same id as the new value
    const itemIndex = currentPrices.findIndex((item: { id: number }) => item.id === newValue.id);

    if (itemIndex !== -1) {
      // if found, replace the existing value with the new value
      currentPrices[itemIndex] = newValue;
    } else {
      // if not found, add the new value to the list
      currentPrices.push(newValue);
    }

    setValue('price', currentPrices);
  };

  const handleOnDeletePrice = (currentValue: { id: number; title: string; value: number }) => {
    let currentPrices = getValues('price');

    if (!Array.isArray(currentPrices)) {
      currentPrices = [];
    } else {
      // Assuming that 'id' is the unique identifier of the price item
      currentPrices = currentPrices.filter((price) => price.id !== currentValue.id);
    }

    setValue('price', currentPrices);
    togglePriceDialog();
  };
  // #endregion
  // #region EFFECTS
  useEffect(() => {
    if (formType === FormType.NEW) {
      handleClearForm();
    }
  }, [formType]);

  useEffect(() => {
    let prices = [];

    if (currentModel?.price) {
      if (typeof currentModel.price === 'number') {
        const priceObject = { id: 0, title: 'Medium', value: currentModel.price };
        prices.push(priceObject);
      } else {
        prices = currentModel.price;
      }
    }
    reset({ ...currentModel, price: prices });
  }, [currentModel]);
  // #endregion

  return (
    <View style={styles.root}>
      <View style={{ flex: 1, gap: Spacing.micro }}>
        <ProductPicture
          setSource={(value) => setValue('image', value)}
          canChangePicture={true}
          source={watch('image')}
          size={120}
          style={{ marginVertical: Spacing.small, alignSelf: 'center' }}
        />
        <View style={{ padding: Spacing.small }}>
          <GeneralSwitch switchItems={options} selectedId={switchIndex} setSelectedId={setNewSwitchIndex} />
        </View>
        {renderItem({ index: switchIndex })}
      </View>

      <Button style={{ marginTop: Spacing.regular }} onPress={handleSubmit(onSubmit)} mode="contained">
        {switchIndex < 2 ? translate('general.next').toString() : translate('general.save').toString()}
      </Button>

      <ConfirmDialog
        title={'Are you sure?'}
        message={`Attention! You are about to ${archivedMode ? 'restore' : 'remove'} this item.`}
        visible={confirmDialogVisibility}
        dismiss={() => toggleConfirmDialog()}
        onConfirm={() => {
          const uid = handleTargetUid();

          if (uid)
            handleDataUnAuthorized(
              uid,
              {
                ...currentModel,
                meta: { ...currentModel?.meta, modified: new Date().getTime(), status: archivedMode ? ItemStatus.ACTIVE : ItemStatus.INACTIVE },
              },
              collections.menu
            ).then(() => {
              toggleConfirmDialog();
              handleClearObject();
            });
        }}
      />
      <PriceDialog
        onDeleteItem={handleOnDeletePrice}
        type={priceDialogType}
        value={markedPrice}
        visible={priceDialogVisibility}
        setVisible={() => {
          setMarkedPrice(undefined);
          togglePriceDialog();
        }}
        onAddNewItem={handleOnChangePrice}
      />
    </View>
  );
};

export default MenuForm;

const styles = StyleSheet.create({ root: { flex: 1 } });
