import { Image, ImageBackground, StyleSheet, View } from 'react-native';
import React, { FC, useState } from 'react';
import Carousel from 'react-native-reanimated-carousel';
import CText from '../CText';
import { AppImages } from '../../../assets/images';
import Spacing from '../../res/constants/Spacing';
import Layout, { RIGHT_SIDE_BAR_WIDTH } from '../../res/constants/Layout';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { useSharedValue, withSpring } from 'react-native-reanimated';
import IndicatorItem from './IndicatorItem';

interface Props {}

interface IData {
  title: string;
  body?: string;
  footer?: string;
  image: string;
}
const DATA: IData[] = [
  {
    title: "Food is not just fuel, it's information. It talks to your DNA and tells it what to do.",
    image: 'https://cdn.dribbble.com/users/1012997/screenshots/14086465/hunger-station-dribbble_4x.png',
  },
  {
    title: 'Food is our common ground, a universal experience.',
    image:
      'https://media.istockphoto.com/id/1261447673/vector/man-eat-salad-bowl-of-lettuce-green-vegan-organic-fresh-healthy-food-meal-breakfast.jpg?s=612x612&w=0&k=20&c=OvXqyh14XPmHdqyHJKoXchXdNBzR463COXDiFijDlpg=',
  },
  {
    title: 'Eating is a necessity, but cooking is an art.',
    image:
      'https://media.istockphoto.com/id/1212576552/vector/healthy-lifestyle-food-diet-and-weight-loss-banner-with-a-dish-of-salad-table-set-smartphone.jpg?s=612x612&w=0&k=20&c=fiSaTza8KSigRd92EoBwYel2yHR3CdowEiyxadmrWXc=',
  },
];

const INDICATOR_SIZE = 20;

const InitialOnBoarding: FC<Props> = ({}) => {
  //#region STATES
  const [carouselIndex, setIndex] = useState<number>(0);
  //#endregion
  //#region ANIMATED VALUES
  //#endregion
  return (
    <ImageBackground style={styles.root} source={AppImages.background_on_boarding}>
      <Carousel
        loop
        width={Layout.window.width - RIGHT_SIDE_BAR_WIDTH}
        height={Layout.window.height / 2}
        data={DATA}
        autoPlay={true}
        scrollAnimationDuration={1500}
        onSnapToItem={setIndex}
        renderItem={({ item, index }) => (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 4,
                },
                shadowOpacity: 0.3,
                shadowRadius: 4.65,
                borderRadius: Spacing.bigRadius,
                overflow: 'hidden',
                backgroundColor: MATERIAL_COLORS.white,
              }}
            >
              <Image
                source={{ uri: item.image }}
                style={{
                  width: 250,
                  height: 250,
                }}
              />
            </View>

            <CText
              fontType="header5"
              style={{ color: MATERIAL_COLORS.white, marginTop: Spacing.big, textAlign: 'center', maxWidth: Layout.window.width * 0.3 }}
            >
              {item.title}
            </CText>
            <CText fontType="body2" style={{ color: MATERIAL_COLORS.white, marginTop: Spacing.regular }}>
              {item.title}
            </CText>
          </View>
        )}
      />
      <View style={{ flexDirection: 'row', gap: 10, marginTop: Spacing.regular }}>
        {DATA.map((_item, index) => {
          return <IndicatorItem key={index} current={index === carouselIndex} />;
        })}
      </View>
    </ImageBackground>
  );
};

export default InitialOnBoarding;

const styles = StyleSheet.create({
  root: {
    backgroundColor: MATERIAL_COLORS.light_blue,
    justifyContent: 'center',
    alignItems: 'center',
    height: Layout.window.height,
    width: Layout.window.width * 0.75,
  },
});
