import { eachMinuteOfInterval } from 'date-fns';

export function fiveMinutesInterval(start: number, end: number, step: number): number[] {
  const intervals: number[] = [];

  for (let i = start; i <= end; i += step) {
    intervals.push(i);
  }

  return intervals;
}
