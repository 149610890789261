import { FlatList, StyleSheet, Text, View } from 'react-native';
import React, { FC, useState } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Button, Menu } from 'react-native-paper';
import { fiveMinutesInterval } from '../../res/data/lists';
import Layout from '../../res/constants/Layout';
import { MATERIAL_COLORS } from '../../res/constants/Colors';

interface Props {
  error: boolean;
  buttonText: string;
  handleOnItemClicked: (value: number) => void;
}
const OrderDurationPicker: FC<Props> = ({ error, buttonText, handleOnItemClicked }) => {
  // #region STATES
  const [menuVisibility, showMenu] = useState<boolean>(false);
  // #endregion
  // #region CUSTOM HOOKS
  const colorScheme = useColorScheme();
  // #endregion
  // #region FUNCTIONS
  const handleDismissMenu = () => showMenu(false);
  const handlePresentMenu = () => showMenu(true);
  const handleMenuItemClick = (item: number) => {
    handleOnItemClicked(item);
    showMenu(false);
  };
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  const theme = Styles[colorScheme];
  // #endregion
  return (
    <View style={styles.root}>
      <Menu
        contentStyle={{ backgroundColor: theme.background }}
        onDismiss={handleDismissMenu}
        visible={menuVisibility}
        anchor={
          <Button mode="text" textColor={error ? MATERIAL_COLORS.red : theme.primary} onPress={handlePresentMenu}>
            {buttonText}
          </Button>
        }
      >
        <FlatList
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            height: Layout.window.height * 0.4,
          }}
          data={fiveMinutesInterval(5, 120, 5)}
          renderItem={({ item, index }) => (
            <Menu.Item key={index} onPress={() => handleMenuItemClick(item)} title={`${item} min`} titleStyle={{ color: theme.text }} />
          )}
        />
      </Menu>
    </View>
  );
};

export default OrderDurationPicker;

const styles = StyleSheet.create({ root: {} });
