import React, { FC } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from '../../screens/public/LoginScreen';
import MenuScreen from '../../screens/public/MenuScreen';
import RegisterScreen from '../../screens/public/RegisterScreen';
import { PublicParamList } from './types';
import MenuDetailScreen from '../../screens/public/MenuDetailScreen';
import CartScreen from '../../screens/public/CartScreen';
import CustomCartIcon from '../../components/pressable/CustomCartIcon';
import OrderStatusScreen from '../../screens/public/OrderStatusScreen';
import { View } from 'react-native';
import CountryPicker from '../../components/countries/CountryPicker';
import Spacing from '../../res/constants/Spacing';
import useLocalization from '../providers/localization/LocalizationProvider';
import LandingPageScreen from '../../screens/public/LandingPageScreen';
import MenuDetailScreenRefactor from '../../screens/public/MenuDetailScreenRefactor';
import TermsAndConditionsScreen from '../../screens/public/TermsAndConditionsScreen';

interface Props {}
const PublicStack = createNativeStackNavigator<PublicParamList>();
const PublicNavigator: FC<Props> = ({}) => {
  const { translate } = useLocalization();
  return (
    <PublicStack.Navigator screenOptions={{ headerShown: false }} initialRouteName="LandingPage">
      <PublicStack.Screen
        name="LandingPage"
        component={LandingPageScreen}
        options={{
          headerShown: false,
          title: 'Menu Amore Mio',
        }}
      />
      <PublicStack.Screen name="Login" component={LoginScreen} />
      <PublicStack.Screen name="Register" component={RegisterScreen} />
      <PublicStack.Screen
        name="Menu"
        component={MenuScreen}
        options={({ navigation, route }) => ({
          title: 'Menu',
          headerShown: true,
          headerRight: () => {
            return (
              <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: Spacing.regular }}>
                <CountryPicker showText={false} />
                <CustomCartIcon businessId={route.params.business} tableKey={route.params.table} />
              </View>
            );
          },
        })}
      />
      <PublicStack.Screen
        name="MenuDetail"
        component={MenuDetailScreen}
        options={({ navigation, route }) => ({
          title: 'Menu',
        })}
      />
      <PublicStack.Screen
        name="MenuDetailRefactor"
        component={MenuDetailScreenRefactor}
        options={({ navigation, route }) => ({
          title: 'Menu',
        })}
      />
      <PublicStack.Screen
        name="Cart"
        component={CartScreen}
        options={() => ({
          title: 'Roni',
          headerShown: true,
          headerRight: () => {
            if (__DEV__) {
              return (
                <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: Spacing.regular }}>
                  <CountryPicker showText={false} />
                </View>
              );
            } else return null;
          },
        })}
      />
      <PublicStack.Screen
        name="OrderStatus"
        component={OrderStatusScreen}
        options={() => ({
          title: translate('pages.order_status.title').toString(),
          headerShown: true,
          headerRight: () => {
            if (__DEV__) {
              return (
                <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: Spacing.regular }}>
                  <CountryPicker showText={false} />
                </View>
              );
            } else return null;
          },
        })}
      />
      <PublicStack.Screen
        name="TermsAndConditions"
        component={TermsAndConditionsScreen}
        options={() => ({
          headerShown: true,
          title: translate('general.terms-and-conditions').toString(),
        })}
      />
    </PublicStack.Navigator>
  );
};

export default PublicNavigator;
