import { StyleSheet, View } from 'react-native';
import React, { FC, useState } from 'react';
import { View as RootView } from '../../components/Themed';
import Config from '../../res/constants/Config';
import Layout, { DEFAULT_GAP, RIGHT_SIDE_BAR_WIDTH } from '../../res/constants/Layout';
import InitialOnBoarding from '../../components/onBoarding/InitialOnBoarding';
import { AuthType } from '../../utils/enums';
import Header from '../../components/authentication/Header';
import Spacing from '../../res/constants/Spacing';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import Footer from '../../components/authentication/Footer';
import { useForm, FieldValues } from 'react-hook-form';
import { IRegisterBusinessDTO } from '../../models/dto/IRegisterBusinessDTO';
import CustomInput from '../../components/inputs/CustomInput';
import SaveButton from '../../components/pressable/SaveButton';
import { useAuth } from '../../app/providers/authentication';
import CText from '../../components/CText';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

interface Props {}
const RegisterScreen: FC<Props> = ({}) => {
  //#region STATES
  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
  //#endregion
  //#region HOOKS
  const { translate } = useLocalization();
  const { signUpWithEmailAndPassword, error } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegisterBusinessDTO>({ defaultValues: undefined });
  //#endregion
  //#region FUNCTIONS
  const onSubmit = async (params: FieldValues) => {
    signUpWithEmailAndPassword(params);
  };
  //#endregion

  return (
    <RootView style={styles.root}>
      {Config.isWeb && Layout.window.width > 800 && <InitialOnBoarding />}
      <View
        style={{
          flex: 1,
          justifyContent: 'space-between',
          maxWidth: Config.isWeb && Layout.window.width > 800 ? RIGHT_SIDE_BAR_WIDTH : undefined,
        }}
      >
        {/* Title */}
        <Header type={AuthType.REGISTER} />
        {/* Form */}
        <View
          style={{
            padding: Spacing.regular,
            margin: Spacing.regular,
            gap: DEFAULT_GAP,
            backgroundColor: MATERIAL_COLORS.grey_100,
            borderRadius: Spacing.defaultRadius,
          }}
        >
          <CustomInput
            error={errors.displayName}
            required={true}
            control={control}
            name={'displayName'}
            placeholder={translate('authentication.name_surname').toString()}
            iconProps={{ isLeftIconly: true, leftName: 'User', showRight: false }}
          />
          <CustomInput
            error={errors.businessName}
            required={true}
            control={control}
            name={'businessName'}
            placeholder={translate('authentication.business_name').toString()}
            iconProps={{ isLeftIconly: true, leftName: 'Work', showRight: false }}
          />
          <CustomInput
            error={errors.email}
            required={true}
            control={control}
            name={'email'}
            placeholder={'Email'}
            iconProps={{ isLeftIconly: true, leftName: 'Message', showRight: false }}
          />
          <CustomInput
            error={errors.phone}
            required={true}
            control={control}
            name={'phone'}
            placeholder={translate('authentication.phone').toString()}
            keyboardTypeOptions="number-pad"
            iconProps={{ isLeftIconly: true, leftName: 'Call', showRight: false }}
          />
          <CustomInput
            error={errors.password}
            required={true}
            control={control}
            name={'password'}
            placeholder={translate('authentication.password').toString()}
            iconProps={{
              leftName: 'Password',
              isLeftIconly: true,
              showRight: true,
              rightName: isPasswordVisible ? 'Hide' : 'Show',
              isRightIconly: true,
              rightDisabled: false,
              leftDisabled: true,
            }}
            onRightIconPress={() => setPasswordVisibility((value) => !value)}
            passwordVisibility={!isPasswordVisible}
          />
          <CustomInput
            error={errors.repeatPassword}
            required={true}
            control={control}
            name={'repeatPassword'}
            placeholder={translate('authentication.repeat_password').toString()}
            iconProps={{
              leftName: 'Lock',
              isLeftIconly: true,
              showRight: false,
              leftDisabled: true,
            }}
            onRightIconPress={() => setPasswordVisibility((value) => !value)}
            passwordVisibility={!isPasswordVisible}
          />
          <CText style={{ color: MATERIAL_COLORS.red }} fontType="caption">
            {error?.code}
          </CText>
          <SaveButton
            title={translate('authentication.register').toString()}
            onPress={handleSubmit(onSubmit)}
            style={{ marginTop: Spacing.regular }}
          />
        </View>

        {/* Footer */}
        <Footer type={AuthType.REGISTER} />
      </View>
    </RootView>
  );
};

export default RegisterScreen;

const styles = StyleSheet.create({ root: { flex: 1, flexDirection: 'row' } });
