import { StyleSheet, View } from 'react-native';
import React, { FC, useEffect, useState } from 'react';
import { PrivateStackScreenProps } from '../../app/navigation/types';
import { useFirestore } from '../../app/providers/firestore';
import { IUser } from '../../models/IUser';
import { View as RootView } from '../../components/Themed';
import { Divider, Text, Button } from 'react-native-paper';
import Spacing from '../../res/constants/Spacing';
import ProfilePicture from '../../components/images/ProfilePicture';
import ExpandableListContainer from '../../components/expandable/ExpandableListContainer';
import { enumToList, UserRole } from '../../utils/enums';
import { IGeneralItem } from '../../models/IGeneralItem';
import LabelSelector from '../../components/general/selectable/label-selector/LabelSelector';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { useMediaQuery } from 'react-responsive';

const AccountScreen = ({ navigation, route }: PrivateStackScreenProps<'Account'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // #region MEMBERS
  const userId = route.params.userId;
  const roleList = enumToList(UserRole);
  // #endregion
  // #region STATES
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [userRole, setUserRole] = useState<UserRole>(0);
  const [orderPrivileges, setOrderPrivileges] = useState<IGeneralItem[]>([]);
  // #endregion
  // #region CUSTOM HOOKS
  const { handleGetUserDocument, handleUpdateUser } = useFirestore();
  const { translate } = useLocalization();
  const tableCategory = translate('pages.table.category') as IGeneralItem[];
  // #endregion
  // #region FUNCTIONS
  const handleGetUserRole = () => {
    const foundIndex = roleList.findIndex((role) => role.id === userRole);
    if (foundIndex > -1) {
      return roleList[foundIndex].title;
    } else return '';
  };

  const handleSetSelectedObject = (item: IGeneralItem) => {
    const id = Number(item.id);

    setUserRole(id);
  };

  const handleUpdateTheUser = () => {
    handleUpdateUser(userId, { ...user, role: userRole, orderPrivileges: orderPrivileges.map((i) => i.id) });
  };

  const handleUpdateOrderPrivileges = (items: IGeneralItem[]) => setOrderPrivileges(items);
  // #endregion
  // #region EFFECTS
  useEffect(() => {
    handleGetUserDocument(userId).then((value: IUser) => {
      if (value) {
        setUser(value);
        if (value.role) setUserRole(value.role);
        if (value.orderPrivileges) {
          const filteredItems = tableCategory.filter((item) => value.orderPrivileges.includes(Number(item.id)));
          setOrderPrivileges(filteredItems);
        }
      }
    });
  }, []);
  // #endregion

  if (!user) return null;

  return (
    <RootView style={styles.root}>
      {/* Account detail */}
      <View style={{ flexDirection: 'row', alignItems: 'center', gap: Spacing.small }}>
        <ProfilePicture size={64} source={user?.photoURL} hasRadius={true} />
        <View style={{ flex: 1 }}>
          <Text variant="titleMedium">{user?.businessName}</Text>
          <Text variant="titleSmall">{user?.displayName ? user.displayName : user?.email}</Text>
          {/* {user?.metadata.creationTime && (
            <Text variant="labelMedium">{`Created: ${format(new Date(user.metadata.creationTime), 'MMM dd, yyyy')}`}</Text>
          )}
          {user?.metadata.lastSignInTime && (
            <Text variant="labelMedium">{`Last sign in: ${format(new Date(user.metadata.lastSignInTime), 'MMM dd, yyyy')}`}</Text>
          )} */}
        </View>
      </View>
      <Divider style={{ marginTop: Spacing.regular }} />
      <View style={{ gap: Spacing.regular, flex: 1, width: isTabletOrMobile ? '100%' : '50%' }}>
        <ExpandableListContainer
          title={handleGetUserRole()}
          items={roleList}
          placeholder={'Role'}
          selectedId={userRole}
          onSelectItem={handleSetSelectedObject}
          iconProps={{ leftName: 'Category', isLeftIconly: true, rightName: 'sort', isRightIconly: false }}
        />

        <View style={{ gap: Spacing.small }}>
          <Text style={{ marginHorizontal: Spacing.regular }} variant="titleMedium">
            Enable order from:
          </Text>
          <LabelSelector
            enableSearch={false}
            multiple={true}
            value={orderPrivileges}
            options={tableCategory}
            onChange={handleUpdateOrderPrivileges}
          />
        </View>
      </View>
      <Button onPress={handleUpdateTheUser} mode="contained">
        Submit
      </Button>
    </RootView>
  );
};

export default AccountScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Spacing.regular,
    alignItems: 'center',
  },
});
