import React, { createContext, useContext, useMemo } from 'react';
import { ReactNode } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../config/web/firebase_config';
import * as ImageManipulator from 'expo-image-manipulator';

const Context = createContext<{ uploadImage: (base64: any, path: string) => Promise<string> }>({
  uploadImage: () => Promise.reject('Upload function not yet initialized'),
});

export interface IStorageProviderProps {
  children: ReactNode;
}



// Utility function to convert base64 to Blob
const base64ToBlob = (base64: string): Blob => {
  const byteString = atob(base64.split(',')[1]);
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const StorageProvider = ({ children }: IStorageProviderProps) => {
  const uploadImage = async (base64: string, path: string) => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        // Convert the base64 string to a Blob
        const blob = base64ToBlob(base64);

        // Create a local URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Compress the image
        const compressedImage = await ImageManipulator.manipulateAsync(
          blobUrl,
          [{ resize: { width: 400 } }], // Example resize, change dimensions as needed
          { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG } // Adjust compression settings as needed
        );

        // Fetch the compressed image Blob
        const response = await fetch(compressedImage.uri);
        const compressedBlob = await response.blob();

        // Revoke the local URL to free memory
        URL.revokeObjectURL(blobUrl);

        // Create a reference to the file we want to upload
        const storageRef = ref(storage, path);

        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, compressedBlob);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Calculate and log the progress as a percentage
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            // Handle unsuccessful uploads
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const memoValue = useMemo(() => ({ uploadImage }), []);

  return <Context.Provider value={memoValue}>{children}</Context.Provider>;
};

export default function useStorage() {
  return useContext(Context);
}
