import { StyleSheet } from 'react-native';
import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { SimpleUserParamList } from './types';
import useLocalization from '../providers/localization/LocalizationProvider';
import HomeScreen from '../../screens/simple_user/HomeScreen';
import Iconly from '../../components/iconly/mapIcons';

const BottomTab = createBottomTabNavigator<SimpleUserParamList>();

const SimpleUserNavigator = () => {
  const { translate } = useLocalization();

  return (
    <BottomTab.Navigator initialRouteName="Home">
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation, route }) => ({
          title: translate('pages.home.title') as string,
          tabBarIcon: ({ color }) => <TabBarIcon name="Home" primaryColor={color} />,
        })}
      />
    </BottomTab.Navigator>
  );
};

function TabBarIcon(props: { name: React.ComponentProps<typeof Iconly>['name']; primaryColor: string }) {
  return <Iconly set="curved" size={24} {...props} />;
}

export default SimpleUserNavigator;

const styles = StyleSheet.create({ root: {} });
