import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';
import React, { FC, useRef } from 'react';
import ExpandableView, { ExpandableViewRef } from './ExpandableView';
import CText from '../CText';
import { View as RootView } from '../Themed';
import useColorScheme from '../../hooks/useColorScheme';
import { maximumExpandableHeight } from '../../utils/common';
import { IGeneralItem } from '../../models/IGeneralItem';
import CustomPressable from '../pressable/CustomPressable';
import GeneralIcon from '../pressable/GeneralIcon';
import Styles from '../../res/constants/Styles';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { DEFAULT_GAP, DEFAULT_ITEM_HEIGHT } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import GeneralList from '../lists/GeneralList';
import { IGeneralIcon } from '../../models/domain/IGeneralIcon';

interface Props extends ViewProps {
  items: any[];
  placeholder: string;
  title?: string | undefined;
  selectedId: number | string | undefined;
  onSelectItem: (value: IGeneralItem) => void;
  style?: StyleProp<ViewStyle>;
  hasSearch?: boolean;
  iconProps: IGeneralIcon;
  error?: any;
}
const ExpandableListContainer: FC<Props> = ({
  items,
  placeholder,
  iconProps,
  title,
  selectedId,
  onSelectItem,
  style,
  hasSearch = false,
  error = undefined,
}) => {
  //#region REFERENCES
  const expRef = useRef<ExpandableViewRef | null>(null);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  return (
    <RootView style={[styles.root, style, { borderColor: error !== undefined ? MATERIAL_COLORS.red : 'transparent' }]}>
      <CustomPressable
        style={styles.container}
        onPress={() => {
          if (!expRef.current?.isOpen) {
            expRef.current?.present(maximumExpandableHeight(items, hasSearch));
          } else {
            expRef.current.dismiss();
          }
        }}
      >
        <View style={{ alignItems: 'center', flex: 1, flexDirection: 'row', backgroundColor: MATERIAL_COLORS.white }}>
          <GeneralIcon iconName={iconProps.leftName} isIconly={iconProps.isLeftIconly} />
          <CText fontType="body1" style={{ color: title === undefined ? theme.placeholder : theme.text, flex: 1 }}>
            {title === undefined ? placeholder : title}
          </CText>
          <GeneralIcon iconName={iconProps.rightName} isIconly={iconProps.isRightIconly} />
        </View>
      </CustomPressable>
      <ExpandableView ref={expRef}>
        <GeneralList
          hasSearch={hasSearch}
          height={maximumExpandableHeight(items, hasSearch)}
          items={items}
          selectedValue={selectedId}
          onPress={(value) => {
            onSelectItem(value);
            expRef.current?.dismiss();
          }}
        />
      </ExpandableView>
    </RootView>
  );
};

export default ExpandableListContainer;

const styles = StyleSheet.create({
  root: {
    borderRadius: Spacing.small,
    overflow: 'hidden',
    gap: DEFAULT_GAP,
    borderWidth: 1,
  },
  container: {
    height: DEFAULT_ITEM_HEIGHT,
  },
});
