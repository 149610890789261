import { KeyboardTypeOptions, StyleProp, StyleSheet, TextInput, View, ViewProps, ViewStyle } from 'react-native';
import React, { FC } from 'react';
import { Control, Controller, FieldError, FieldName } from 'react-hook-form';
import GeneralIcon from '../pressable/GeneralIcon';
import { View as RootView } from '../Themed';
import { format } from 'date-fns';
import useColorScheme from '../../hooks/useColorScheme';
import Spacing from '../../res/constants/Spacing';
import Config from '../../res/constants/Config';
import { DEFAULT_ITEM_HEIGHT, DEFAULT_ITEM_HEIGHT_2 } from '../../res/constants/Layout';
import { materialColorProvider } from '../../utils/common';
import Styles from '../../res/constants/Styles';
import { IGeneralIcon } from '../../models/domain/IGeneralIcon';

interface Props extends ViewProps {
  control: Control<any, any>;
  name: FieldName<any>;
  placeholder: string;
  required?: boolean;
  error?: FieldError;
  passwordVisibility?: boolean;
  flex?: number;
  clickable?: boolean;
  style?: StyleProp<ViewStyle>;
  isDateInput?: boolean;
  isTimeInput?: boolean;
  onPress?: () => void;
  keyboardTypeOptions?: KeyboardTypeOptions;
  iconProps?: IGeneralIcon;
  onLeftIconPress?: () => void;
  onRightIconPress?: () => void;
  imageLeftIcon?: any;
}
const CustomInput: FC<Props> = ({
  control,
  name,
  placeholder,
  required = false,
  error,
  passwordVisibility = false,
  flex,
  clickable = false,
  style,
  isDateInput = false,
  isTimeInput = false,
  keyboardTypeOptions,
  iconProps,
  onLeftIconPress,
  onRightIconPress,
  imageLeftIcon = undefined,
}) => {
  //#region HOOKS
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region FUNCTIONS
  const inputValue = (value: string) => {
    if (value) {
      if (isDateInput) {
        return format(new Date(value), 'dd MMM, yyyy');
      } else if (isTimeInput) {
        return format(new Date(value), 'HH:mm');
      } else {
        return value.toString();
      }
    } else return '';
  };
  //#endregion
  return (
    <RootView
      lightColor={'white'}
      darkColor={materialColorProvider('blue_grey', '900')}
      style={[
        {
          flex,
          borderWidth: 1,
          overflow: 'hidden',
          backgroundColor: theme.background,
          borderRadius: Spacing.defaultRadius,
          borderColor: error ? materialColorProvider('red') : 'transparent',
        },
        style,
      ]}
    >
      <View style={[styles.root]}>
        {imageLeftIcon ? (
          <View style={{ height: DEFAULT_ITEM_HEIGHT, width: DEFAULT_ITEM_HEIGHT_2, alignItems: 'center', justifyContent: 'center' }}>
            {imageLeftIcon}
          </View>
        ) : (
          <GeneralIcon
            iconName={iconProps?.leftName}
            isIconly={iconProps?.isLeftIconly}
            disabled={iconProps?.leftDisabled}
            onPress={onLeftIconPress}
          />
        )}
        <Controller
          rules={{ required }}
          control={control}
          name={name}
          render={({ field: { onChange, value, onBlur } }) => (
            <TextInput
              onBlur={onBlur}
              editable={!clickable}
              onChangeText={onChange}
              value={inputValue(value)}
              placeholder={placeholder}
              multiline={flex ? true : false}
              keyboardType={keyboardTypeOptions}
              secureTextEntry={passwordVisibility}
              placeholderTextColor={theme.placeholder}
              textAlignVertical={flex ? 'top' : 'center'}
              pointerEvents={clickable ? 'none' : 'auto'}
              style={[styles.input, { paddingTop: flex ? Spacing.regular : 0, color: theme.text }]}
            />
          )}
        />
        {iconProps?.showRight && (
          <GeneralIcon
            onPress={onRightIconPress}
            isIconly={iconProps.isRightIconly}
            iconName={iconProps.rightName}
            disabled={iconProps.rightDisabled}
          />
        )}
      </View>
    </RootView>
  );
};

export default CustomInput;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: DEFAULT_ITEM_HEIGHT,
    overflow: 'hidden',
  },
  input: {
    flex: 1,
    fontSize: 16,
    letterSpacing: 0.5,
    ...(Config.isWeb && { outlineStyle: 'none' }),
  },
});
