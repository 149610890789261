import { G, Path } from 'react-native-svg';
import * as React from 'react';
import withIcon from '../../../src/components/iconly/withIcon';

type Props = {
  opacity?: string;
  color?: string;
  secondaryColor?: string;
  set?: string;
  strokeWidth?: string | number;
};

const Camera = ({ color, secondaryColor, strokeWidth, opacity, set }: Props) => {
  const Bold = () => (
    <G transform="translate(2 3)">
      <Path
        d="M15.64,18H4.36A4.362,4.362,0,0,1,0,13.645V7.711A4.362,4.362,0,0,1,4.36,3.356a.2.2,0,0,0,.191-.12l.059-.12.083-.175c.261-.55.557-1.173.737-1.533A2.433,2.433,0,0,1,7.64,0h4.71a2.44,2.44,0,0,1,2.22,1.408c.157.315.4.82.627,1.308l.142.3.1.22a.225.225,0,0,0,.2.12A4.362,4.362,0,0,1,20,7.711v5.934A4.362,4.362,0,0,1,15.64,18ZM10,6.4A3.922,3.922,0,0,0,7.21,7.562a3.867,3.867,0,0,0-1.14,2.767A3.924,3.924,0,0,0,10,14.254a3.9,3.9,0,0,0,2.77-1.148,3.915,3.915,0,0,0,.01-5.554A3.9,3.9,0,0,0,10,6.4Zm5.61-.239a.9.9,0,1,0,.911.909A.906.906,0,0,0,15.61,6.163ZM10,12.756a2.423,2.423,0,0,1-2.43-2.427v-.01a2.337,2.337,0,0,1,.7-1.7A2.43,2.43,0,1,1,10,12.756Z"
        fill={color}
      />
    </G>
  );
  const Bulk = () => (
    <G transform="translate(2 3)">
      <Path
        d="M1.15,6.942A3.931,3.931,0,0,1,0,4.165,3.871,3.871,0,0,1,1.14,1.4a3.926,3.926,0,1,1,.01,5.544ZM2.2,2.457a2.334,2.334,0,0,0-.7,1.7v.01A2.423,2.423,0,0,0,3.93,6.593,2.444,2.444,0,0,0,6.36,4.165,2.433,2.433,0,0,0,2.2,2.457ZM8.64.909a.9.9,0,1,1,.9.9A.9.9,0,0,1,8.64.909Z"
        transform="translate(6.07 6.163)"
        fill={color}
      />
      <Path
        d="M15.44,3.236l-.1-.22c-.27-.569-.58-1.229-.77-1.608A2.442,2.442,0,0,0,12.35,0H7.64A2.428,2.428,0,0,0,5.43,1.408c-.2.4-.54,1.119-.82,1.708l-.06.12a.2.2,0,0,1-.19.12A4.361,4.361,0,0,0,0,7.711v5.933A4.361,4.361,0,0,0,4.36,18H15.64A4.368,4.368,0,0,0,20,13.645V7.711a4.368,4.368,0,0,0-4.36-4.355A.227.227,0,0,1,15.44,3.236Z"
        fill={secondaryColor}
        opacity={opacity}
      />
    </G>
  );
  const Light = () => (
    <G transform="translate(2 3)">
      <Path
        d="M14.888,3.36h0a.962.962,0,0,1-.87-.551C13.731,2.2,13.366,1.424,13.15,1A1.675,1.675,0,0,0,11.6,0c-.012,0-4.684,0-4.7,0A1.676,1.676,0,0,0,5.35,1c-.215.423-.58,1.2-.867,1.808a.963.963,0,0,1-.87.551h0A3.612,3.612,0,0,0,0,6.972v5.936A3.613,3.613,0,0,0,3.613,16.52H14.888A3.612,3.612,0,0,0,18.5,12.908V6.972A3.612,3.612,0,0,0,14.888,3.36Z"
        transform="translate(0.75 0.75)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      />
      <Path
        d="M0,3.182A3.179,3.179,0,1,0,3.182,0,3.19,3.19,0,0,0,0,3.182Z"
        transform="translate(6.821 7.152)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      />
      <Path
        d="M.9,2a1.085,1.085,0,0,1-.3-.078A1.008,1.008,0,0,1,.282,1.7,1.021,1.021,0,0,1,0,1,.971.971,0,0,1,.082.607,1.018,1.018,0,0,1,.318.265,1.144,1.144,0,0,1,.622.07,1.023,1.023,0,0,1,1.7.285a.962.962,0,0,1,.19.261L1.917.6A.974.974,0,0,1,2,1a1.016,1.016,0,0,1-.292.705A1,1,0,0,1,1.1,2L1,2Z"
        transform="translate(14 6)"
        fill={color}
      />
    </G>
  );

  const Broken = () => (
    <G transform="translate(2 3)">
      <Path
        d="M8.58,18a.749.749,0,1,1,0-1.5h7.059A2.864,2.864,0,0,0,18.5,13.642V7.713a2.864,2.864,0,0,0-2.862-2.859,1.718,1.718,0,0,1-1.548-.979c-.314-.665-.655-1.385-.859-1.788a.93.93,0,0,0-.889-.588H7.653a.928.928,0,0,0-.884.589c-.2.391-.526,1.084-.8,1.663l-.059.124a1.719,1.719,0,0,1-1.548.979A2.864,2.864,0,0,0,1.5,7.713v5.929A2.864,2.864,0,0,0,4.363,16.5h.745a.749.749,0,1,1,0,1.5H4.363A4.365,4.365,0,0,1,0,13.642V7.713A4.365,4.365,0,0,1,4.363,3.355a.211.211,0,0,0,.192-.121l.059-.124c.279-.592.614-1.3.816-1.7A2.425,2.425,0,0,1,7.646,0h4.7a2.426,2.426,0,0,1,2.221,1.409c.209.411.557,1.147.841,1.751.071.148.146.2.229.2A4.365,4.365,0,0,1,20,7.713v5.929A4.365,4.365,0,0,1,15.638,18ZM10,14.254a3.928,3.928,0,0,1-3.74-5.121.75.75,0,0,1,1.43.454,2.383,2.383,0,0,0-.115.733A2.435,2.435,0,0,0,10,12.756h0a2.419,2.419,0,0,0,1.724-.723,2.376,2.376,0,0,0,.7-1.7A2.438,2.438,0,0,0,10,7.895h0a2.475,2.475,0,0,0-.529.057.748.748,0,1,1-.327-1.461A3.836,3.836,0,0,1,10,6.4h0a3.936,3.936,0,0,1,3.928,3.931,3.857,3.857,0,0,1-1.139,2.762A3.91,3.91,0,0,1,10,14.254Zm4.923-6.5a.745.745,0,0,1-.22-.528.7.7,0,0,1,.06-.279,1.081,1.081,0,0,1,.16-.25,1.055,1.055,0,0,1,.25-.161.807.807,0,0,1,.57,0,.832.832,0,0,1,.24.161.88.88,0,0,1,.17.25.7.7,0,0,1,.059.279.718.718,0,0,1-.229.528.8.8,0,0,1-.24.17.7.7,0,0,1-.29.051A.743.743,0,0,1,14.92,7.752Z"
        fill={color}
      />
    </G>
  );

  const TwoTone = () => (
    <G transform="translate(2 3)">
      <Path
        d="M14.888,3.36h0a.962.962,0,0,1-.87-.551C13.731,2.2,13.366,1.424,13.15,1A1.675,1.675,0,0,0,11.6,0c-.012,0-4.684,0-4.7,0A1.676,1.676,0,0,0,5.35,1c-.215.423-.58,1.2-.867,1.808a.963.963,0,0,1-.87.551h0A3.612,3.612,0,0,0,0,6.972v5.936A3.613,3.613,0,0,0,3.613,16.52H14.888A3.612,3.612,0,0,0,18.5,12.908V6.972A3.612,3.612,0,0,0,14.888,3.36Z"
        transform="translate(0.75 0.75)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      />
      <Path
        d="M0,3.182A3.179,3.179,0,1,0,3.182,0,3.19,3.19,0,0,0,0,3.182Z"
        transform="translate(6.821 7.152)"
        fill="none"
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
      <Path
        d="M.9,2a1.085,1.085,0,0,1-.3-.078A1.008,1.008,0,0,1,.282,1.7,1.021,1.021,0,0,1,0,1,.971.971,0,0,1,.082.607,1.018,1.018,0,0,1,.318.265,1.144,1.144,0,0,1,.622.07,1.023,1.023,0,0,1,1.7.285a.962.962,0,0,1,.19.261L1.917.6A.974.974,0,0,1,2,1a1.016,1.016,0,0,1-.292.705A1,1,0,0,1,1.1,2L1,2Z"
        transform="translate(14 6)"
        fill={secondaryColor}
        opacity={opacity}
      />
    </G>
  );

  const Curved = () => (
    <G transform="translate(2.5 3.042)">
      <Path
        d="M6.291,3.145A3.145,3.145,0,1,0,3.145,6.291,3.146,3.146,0,0,0,6.291,3.145Z"
        transform="translate(6.648 6.311)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      />
      <Path
        d="M9,17.158c8.045,0,9-2.411,9-7.634,0-3.661-.484-5.62-3.534-6.462a1.927,1.927,0,0,1-.842-.534c-.406-.445-.7-1.813-1.684-2.226A14.475,14.475,0,0,0,6.06.3C5.1.7,4.782,2.083,4.376,2.529a1.921,1.921,0,0,1-.842.534C.484,3.9,0,5.863,0,9.525,0,14.748.958,17.158,9,17.158Z"
        transform="translate(0.789 0)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      />
      <Path
        d="M.5.5H.5"
        transform="translate(14.209 5.458)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
      />
    </G>
  );

  switch (set) {
    case 'bold':
      return <Bold />;
    case 'bulk':
      return <Bulk />;
    case 'broken':
      return <Broken />;
    case 'two-tone':
      return <TwoTone />;
    case 'curved':
      return <Curved />;
    default:
      return <Light />;
  }
};
Camera.displayName = 'IconlyCamera';

export default withIcon(Camera);
