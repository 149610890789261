import { Currency, UserRole } from '../utils/enums';
import { IGeneralItem } from './IGeneralItem';
import { IUserMetadata } from './IUserMetadata';

export interface IUser {
  displayName: string | null;
  email: string | null;
  emailVerified: boolean;
  isAnonymous: boolean;
  metadata: IUserMetadata;
  phoneNumber: string | null;
  photoURL: string | null;
  uid: string;
  fcmTokens?: string;
  role?: UserRole;
  businessName?: string;
  currency?: Currency;
  orderPrivileges: number[];
}

export const userMapper = (user: any) => {
  const metadata: IUserMetadata = {
    creationTime: user.metadata.creationTime,
    lastSignInTime: user.metadata.lastSignInTime,
  };

  return {
    displayName: user.displayName || null,
    email: user.email,
    emailVerified: user.emailVerified,
    isAnonymous: user.isAnonymous,
    metadata,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    uid: user.uid,
  };
};
