import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Avatar, Text } from 'react-native-paper';
import { DEFAULT_GAP } from '../../res/constants/Layout';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { useFirestore } from '../../app/providers/firestore';

interface Props {
  item: any;
}
const OrderListItem: FC<Props> = ({ item }) => {
  const colorScheme = useColorScheme();
  const { locale } = useLocalization();
  const { ingredients } = useFirestore();
  const theme = Styles[colorScheme];

  if (!ingredients || ingredients.length === 0) return null;

  const handleGetIngredient = (id: number) => {
    if (!ingredients || ingredients.length === 0) return;

    const foundIndex = ingredients.findIndex((i) => i.id === id);

    if (foundIndex === -1) return;

    return ingredients[foundIndex];
  };

  return (
    <View style={styles.root}>
      <Text style={{ color: theme.text }} variant="bodyLarge">{`${item.data.length} x`}</Text>
      {item.data[0].image ? (
        <Avatar.Image size={48} source={{ uri: item.data[0].image }} style={[styles.image, { backgroundColor: theme.primary }]} />
      ) : (
        <Avatar.Icon size={48} icon={'image'} style={[styles.image, { backgroundColor: theme.primary }]} />
      )}

      <View style={{ flex: 1 }}>
        <Text style={{ color: theme.text }} variant="bodyMedium">
          {item.data[0].name}
        </Text>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1, gap: DEFAULT_GAP }}>
          {item.data[0].ingredients
            ?.filter((i: any) => i.removedByCustomer)
            .map(
              (
                item: {
                  name: any;
                  title: string;
                  id: number;
                },
                index: number
              ) => {
                return (
                  <Text
                    key={index}
                    variant="labelSmall"
                    style={{
                      color: 'red',
                      backgroundColor: MATERIAL_COLORS.teal_100,
                      paddingVertical: Spacing.minimal,
                      paddingHorizontal: Spacing.small,
                      borderRadius: Spacing.defaultRadius,
                    }}
                  >
                    {`${handleGetIngredient(item?.id)?.emoji} ${handleGetIngredient(item?.id)?.name[locale]}`}
                    {/* {ingredient ? `${ingredient.emoji} ${ingredient.name[locale]}` : 'Translation missing'} */}
                  </Text>
                );
              }
            )}
        </View>
      </View>
    </View>
  );
};

export default OrderListItem;

const styles = StyleSheet.create({
  root: { flexDirection: 'row', alignItems: 'center', padding: Spacing.medium },
  image: { marginHorizontal: Spacing.regular },
  title: { flex: 1, paddingHorizontal: Spacing.regular },
});
