import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { Tooltip, IconButton } from 'react-native-paper';
import { MATERIAL_COLORS } from '../../../../res/constants/Colors';

interface Props {
  item: { id: number; icon: any; toolTip: string };
  selected: boolean;
  setCurrentSelectedItem: (value: { id: number; icon: any; toolTip: string }) => void;
}
const Item: FC<Props> = ({ item, selected, setCurrentSelectedItem }) => {
  // #region STATES
  // #endregion
  // #region CUSTOM HOOKS
  // #endregion
  // #region FUNCTIONS
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <Tooltip enterTouchDelay={10} leaveTouchDelay={10} title={item.toolTip}>
      <IconButton
        animated={true}
        icon={item.icon}
        selected={selected}
        size={24}
        onPress={() => {
          setCurrentSelectedItem(item);
        }}
      />
    </Tooltip>
  );
};

export default Item;

const styles = StyleSheet.create({ root: {} });
