import { StyleSheet, View } from 'react-native';
import React, { useCallback, useState } from 'react';
import { useFirestore } from '../../app/providers/firestore';
import { useFocusEffect } from '@react-navigation/native';
import collections from '../../utils/collections';
import { IOrder } from '../../models/IOrder';
import { PrivateScreenProps } from '../../app/navigation/types';
import BusinessPicker from '../../components/general/userPicker/BusinessPicker';
import { UserRole } from '../../utils/enums';
import { useAppSelector } from '../../hooks/hooks';
import { format, startOfDay } from 'date-fns';
import { View as RootView } from '../../components/Themed';
import Spacing from '../../res/constants/Spacing';
import { DocumentData } from 'firebase/firestore';
import { firebase } from '../../app/config/web/firebase_config';
import SectionListSideMenu from '../../components/side_menu/SectionListSideMenu';
import Config from '../../res/constants/Config';
import { useMediaQuery } from 'react-responsive';

const StatsScreen = ({ navigation }: PrivateScreenProps<'Stats'>) => {
  const [orders, setOrders] = useState<any[]>([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const { currentUser } = useFirestore();

  const currentSelectedUser: any = useAppSelector((state) => state.backOffice.selectedUser);

  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
      } else {
        return currentUser.uid;
      }
    }
  };

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions({
        headerLeft: () => {
          return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>{currentUser?.role === UserRole.BACKOFFICE && <BusinessPicker />}</View>
          );
        },
      });

      fetchData();
    }, [currentSelectedUser, reload])
  );

  const fetchData = async () => {
    setLoading(true);

    try {
      const pageSize = 30;
      const collectionRef = firebase
        .firestore()
        .collection(collections.data)
        .doc(handleTargetUid())
        .collection(collections.orders)
        .orderBy('meta.created', 'desc')
        .limit(pageSize);

      const snapshot = await collectionRef.get();
      const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];

      const fetchedData = snapshot.docs.map((doc) => doc.data() as IOrder);
      setOrders(fetchedData);
      setLastVisible(lastVisibleDoc);
    } catch (error) {
      console.error('Error retrieving initial data:', error);
    }

    setLoading(false);
  };

  const loadMoreData = async () => {
    if (loading || !lastVisible) return;

    setLoading(true);

    try {
      const pageSize = 30;
      const collectionRef = firebase.firestore().collection(collections.data).doc(handleTargetUid()).collection(collections.orders);

      let query = collectionRef.orderBy('meta.created', 'desc').startAfter(lastVisible).limit(pageSize);

      const snapshot = await query.get();
      const moreData = snapshot.docs.map((doc) => doc.data() as IOrder);
      const moreLastVisible = snapshot.docs[snapshot.docs.length - 1];

      setOrders((prevData) => [...prevData, ...moreData]);
      setLastVisible(moreLastVisible);
    } catch (error) {
      console.error('Error loading more data:', error);
    }

    setLoading(false);
  };

  const groupOrdersByTimestamp = (orders: IOrder[], groupBy: 'day' | 'hour') => {
    const groupedOrders: { title: { header: string; total: string }; data: IOrder[] }[] = [];

    orders.forEach((order) => {
      const created = order.meta.created;

      if (created !== undefined) {
        const formattedTimestamp = format(startOfDay(created), groupBy === 'day' ? 'MMM dd, yyyy' : 'MMM dd, yyyy HH:00');
        const existingGroup = groupedOrders.find((group) => group.title.header === formattedTimestamp);

        if (existingGroup) {
          existingGroup.data.push(order);
          existingGroup.title.total = calculateTotal(existingGroup.data); // Update the total for the existing group
        } else {
          const total = calculateTotal([order]);
          groupedOrders.push({
            title: { header: formattedTimestamp, total: total },
            data: [order],
          });
        }
      }
    });

    return groupedOrders;
  };

  const calculateTotal = (orders: IOrder[]) => {
    const total = orders.reduce((sum, order) => {
      const orderTotal = order.cartItems.reduce((subtotal, item) => subtotal + item.price, 0);
      return sum + orderTotal;
    }, 0);

    const formattedTotal = total.toLocaleString(undefined, { minimumFractionDigits: 2 });

    return formattedTotal;
  };

  const groupedOrders = groupOrdersByTimestamp(orders, 'day');

  return (
    <RootView style={[styles.root, { padding: !isTabletOrMobile && Config.isWeb ? Spacing.regular : 0 }]}>
      <SectionListSideMenu loading={loading} data={groupedOrders} onEndReached={loadMoreData} setReload={() => setReload((v) => !v)} />
    </RootView>
  );
};

export default StatsScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    gap: Spacing.small,
  },
});
