import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';
import { useAppSelector, useAppDispatch } from '../../../hooks/hooks';
import { changeLanguage } from '../../features/locale/localeSlice';
import al from '../../../res/locale/albanian/translation.json';
import hr from '../../../res/locale/croatian/translation.json';
import en from '../../../res/locale/english/translation.json';
import fr from '../../../res/locale/french/translation.json';
import de from '../../../res/locale/german/translation.json';
import el from '../../../res/locale/greek/translation.json';
import it from '../../../res/locale/italian/translation.json';
import me from '../../../res/locale/montenegrin/translation.json';
import pl from '../../../res/locale/polish/translation.json';
import ro from '../../../res/locale/romanian/translation.json';
import ru from '../../../res/locale/russian/translation.json';
import rs from '../../../res/locale/serbian/translation.json';
import es from '../../../res/locale/spanish/translation.json';
import ua from '../../../res/locale/ukrainian/translation.json';
import { I18n } from 'i18n-js';
import { setDefaultOptions } from 'date-fns';
import {
  sq as albanian,
  hr as croatian,
  enUS as englishUs,
  fr as french,
  de as german,
  el as greek,
  it as italian,
  mn as montenegrin,
  pl as polish,
  ro as romanian,
  ru as russian,
  sr as serbian,
  es as spanish,
  uk as ukrainian,
} from 'date-fns/locale';

const Context = createContext<{
  locale: string;
  setLanguage: (value: string) => void;
  translate: (data: string) => any[] | string;
}>({
  locale: '',
  setLanguage: Function,
  translate: () => '',
});

export interface ILocalizationProviderProps {
  children: ReactNode;
}

export const LocalizationProvider = ({ children }: ILocalizationProviderProps) => {
  const dispatch = useAppDispatch();

  const translations = {
    al,
    hr,
    en,
    fr,
    de,
    el,
    it,
    me,
    pl,
    ro,
    ru,
    rs,
    es,
    ua,
  };

  const i18n = new I18n(translations);
  const currentLanguageCode: any = useAppSelector((state) => state.locale.currentLanguage);

  const [locale, setLocale] = useState(currentLanguageCode);

  i18n.locale = currentLanguageCode;

  const handleChangeDateLocale = (langCode: any) => {
    switch (langCode) {
      case 'al':
        return albanian;
      case 'hr':
        return croatian;
      case 'en':
        return englishUs;
      case 'fr':
        return french;
      case 'de':
        return german;
      case 'el':
        return greek;
      case 'it':
        return italian;
      case 'me':
        return montenegrin;
      case 'pl':
        return polish;
      case 'ro':
        return romanian;
      case 'ru':
        return russian;
      case 'rs':
        return serbian;
      case 'es':
        return spanish;
      case 'ua':
        return ukrainian;
    }
  };
  useEffect(() => {
    setLocale(currentLanguageCode);

    setDefaultOptions({ locale: handleChangeDateLocale(currentLanguageCode) });
  }, [currentLanguageCode]);

  const setLanguage = (newLanguage: string) => {
    dispatch(changeLanguage(newLanguage));
  };

  const translate = (value: string): any[] | string => {
    return i18n.t(value);
  };

  const memoValue = useMemo(() => ({ locale, setLanguage, translate }), [locale]);

  return <Context.Provider value={memoValue}>{children}</Context.Provider>;
};

export default function useLocalization() {
  return useContext(Context);
}
