export default [
  {
    id: 0,
    currency: 'Dollar',
    symbol: 'Usd',
  },
  {
    id: 1,
    currency: 'Euro',
    symbol: 'Eur',
  },
  {
    id: 2,
    currency: 'Lek',
    symbol: 'All',
  },
  {
    id: 3,
    currency: 'Leu',
    symbol: 'RON',
  },
];
