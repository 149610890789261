import { StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import { FormType, UserRole } from '../../utils/enums';
import { useFirestore } from '../../app/providers/firestore';
import { useAuth } from '../../app/providers/authentication';
import Spacing from '../../res/constants/Spacing';
import { MATERIAL_COLORS, TRANSPARENT } from '../../res/constants/Colors';
import { View as RootView } from '../../components/Themed';
import { USER_INFO_CARD } from '../../res/constants/Layout';
import { SafeAreaView } from 'react-native-safe-area-context';
import CustomPressable from '../../components/pressable/CustomPressable';
import { MoreSquare, Plus } from '../../../assets/icons';
import { LinearGradient } from 'expo-linear-gradient';
import ProfilePicture from '../../components/images/ProfilePicture';
import CText from '../../components/CText';
import { bottomSheetfragmentContainerRef } from '../../utils/actions';
import ProfileOptionsDialog from '../../components/dialogs/profile_settings/ProfileOptionsDialog';
import { ProfileNewItemOptionMenu, ProfileNewItemOptionMenuBackOfficeUser, ProfileSettingOptionMenu } from '../../res/data/DummyProfileMenu';
import { maximumExpandableHeight } from '../../utils/common';
import { PrivateScreenProps } from '../../app/navigation/types';

// Mapbox.setAccessToken('AIzaSyCxDGaEEUwoERNuGCbFsrkoKuzvDjY63eU');

export default function ProfileScreen({ navigation, route }: PrivateScreenProps<'Profile'>) {
  //#region HOOKS
  const { currentUser } = useFirestore();
  const { signOut, handleChangeEmail } = useAuth();
  const [markers, setMarkers] = useState([]);
  //#endregion
  //#region FUNCTIONS
  const handleAddNewItem = (index: number) => {
    bottomSheetfragmentContainerRef.current?.dismiss();

    switch (index) {
      case 0:
        navigation.navigate('EditStaff', { key: undefined, type: FormType.NEW });
        break;
      case 1:
        navigation.navigate('EditMenu', { key: undefined, type: FormType.NEW });
        break;
      case 2:
        navigation.navigate('EditTable', { key: undefined, type: FormType.NEW });
        break;
      case 3:
        navigation.navigate('EditSponsor', { type: FormType.NEW });
        break;
      case 4:
        navigation.navigate('EditTranslations', { type: FormType.NEW });
        break;
      default:
        break;
    }
  };

  const handleOptionsSelect = (index: number) => {
    bottomSheetfragmentContainerRef.current?.dismiss();

    switch (index) {
      case 0:
        navigation.navigate('Settings');
        break;
      case 1:
        break;
      case 2:
        signOut();
        break;
      default:
        break;
    }
  };

  //#endregion
  if (currentUser === undefined) {
    return null;
  }

  return (
    <RootView style={styles.root}>
      <SafeAreaView edges={['top']}>
        <View style={styles.infoCard}>
          <View style={styles.buttonContainer}>
            <View style={styles.buttonMask}>
              <CustomPressable
                style={{ padding: Spacing.micro }}
                onPress={() => {
                  const items = currentUser.role === UserRole.BACKOFFICE ? ProfileNewItemOptionMenuBackOfficeUser : ProfileNewItemOptionMenu;
                  bottomSheetfragmentContainerRef.current?.present(
                    maximumExpandableHeight(items),
                    <ProfileOptionsDialog data={items} onPress={handleAddNewItem} />
                  );
                }}
              >
                <Plus />
              </CustomPressable>
            </View>
            <View style={styles.buttonMask}>
              <CustomPressable
                style={{ padding: Spacing.micro }}
                onPress={() => {
                  bottomSheetfragmentContainerRef.current?.present(
                    maximumExpandableHeight(ProfileSettingOptionMenu),
                    <ProfileOptionsDialog data={ProfileSettingOptionMenu} onPress={handleOptionsSelect} />
                  );
                }}
              >
                <MoreSquare />
              </CustomPressable>
            </View>
          </View>
          <LinearGradient
            colors={[MATERIAL_COLORS.grey_300, MATERIAL_COLORS.white]}
            style={{ flex: 1, padding: Spacing.regular, alignItems: 'center', justifyContent: 'center' }}
          >
            <ProfilePicture source={currentUser.photoURL} size={120} hasRadius={true} />
            {/* Displayed info */}
            <View style={styles.titleCardContainer}>
              <CText fontType="overline" style={{ fontSize: 16 }} numberOfLines={1} ellipsizeMode="tail">
                {currentUser.businessName ? currentUser.businessName : currentUser.email}
              </CText>
              <CText numberOfLines={1} ellipsizeMode="tail" fontType="caption">
                {currentUser.email}
              </CText>
            </View>
          </LinearGradient>
        </View>
        {/* <Maps {...{ currentUser }} /> */}
      </SafeAreaView>
    </RootView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Spacing.regular,
  },
  infoCard: {
    height: USER_INFO_CARD,
    borderTopStartRadius: Spacing.medium,
    borderTopEndRadius: Spacing.medium,
    overflow: 'hidden',
  },
  buttonContainer: {
    position: 'absolute',
    top: 0,
    end: 0,
    zIndex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: Spacing.small,
    gap: Spacing.medium,
  },
  buttonMask: { borderRadius: 100, overflow: 'hidden' },
  titleCardContainer: { alignItems: 'center', backgroundColor: TRANSPARENT, marginTop: Spacing.medium },
  map: {
    flex: 1,
  },
});
