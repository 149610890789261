import { View } from 'react-native';
import React, { FC } from 'react';
import { Orientation } from '../../utils/enums';

interface Props {
  orientation?: Orientation;
  size: string;
  color: string;
}
const GeneralSeparator: FC<Props> = ({ orientation = Orientation.HORIZONTAL, size = '100%', color }) => {
  return (
    <View
      style={{
        width: orientation === Orientation.HORIZONTAL ? size : 1,
        height: orientation === Orientation.HORIZONTAL ? 1 : size,
        backgroundColor: color,
        alignSelf: 'center',
      }}
    />
  );
};

export default GeneralSeparator;
