import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import Styles from '../../res/constants/Styles';
import useColorScheme from '../../hooks/useColorScheme';
import Spacing from '../../res/constants/Spacing';
import CText from '../CText';
import { useTheme } from 'react-native-paper';

interface Props {
  switchItems: any[];
  selectedId: number;
  setSelectedId: (value: number) => void;
}

//#region GLOBAL TO THIS SCOPE
const ROOT_PADDING = 2;
const ROOT_BORDER_WIDTH = 0;
//#endregion
const GeneralSwitch: FC<Props> = ({ switchItems, selectedId, setSelectedId }) => {
  //#region STATES
  const [coordinateX, setCoordinateX] = useState<number[]>([]);

  const coordinateXOrdered = useMemo(() => {
    return coordinateX.sort((a, b) => a - b);
  }, [switchItems]);

  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  //#endregion
  //#region MEMBERS
  const theme = useTheme();
  //#endregion
  //#region REFERENCES
  const refs = useRef<Array<TouchableOpacity | null>>([]);
  //#endregion
  //#region FUNCTIONS
  const saveRef = (index: number) => (ref: TouchableOpacity | null) => {
    refs.current[index] = ref;
  };

  const onPress = useCallback((i: number) => setSelectedId(i), [selectedId]);

  const handleViewLayout = (index: number, event: { nativeEvent: { layout: { x: number } } }) => {
    const { x } = event.nativeEvent.layout;
    setCoordinateX((prevWidths: number[]) => {
      const newWidths = [...prevWidths];
      newWidths[index] = x;
      return newWidths;
    });
  };

  //#endregion
  //#region ANIMATED VALUES
  const animatedValue = useSharedValue(0);
  const animatedWidth = useSharedValue(0);
  //#endregion
  //#region ANIMATED STYLES
  const animatedStyle = useAnimatedStyle(() => {
    return { transform: [{ translateX: animatedValue.value }], width: animatedWidth.value };
  });
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    if (coordinateX.length === switchItems.length) {
      refs.current[selectedId]?.measure((_x, _y, width, _height, _pageX, _pageY) => {
        animatedWidth.value = withSpring(width);
        switch (selectedId) {
          case 0:
            animatedValue.value = withTiming(0);
            break;
          default: // 2 for root padding + 1 for borderWidth
            animatedValue.value = withTiming(coordinateXOrdered[selectedId] - (ROOT_PADDING + ROOT_BORDER_WIDTH));
            break;
        }
      });
    }
  }, [selectedId, coordinateX]);
  //#endregion
  return (
    <View style={styles.root}>
      <Animated.View style={[styles.bubble, animatedStyle, { backgroundColor: theme.colors.primary }]} />
      {switchItems.map((item, index) => (
        <TouchableOpacity
          onLayout={(e) => handleViewLayout(index, e)}
          activeOpacity={1}
          onPress={() => onPress(index)}
          key={index}
          ref={saveRef(index)}
          style={styles.option}
        >
          <CText style={{ color: selectedId === index ? theme.colors.onPrimary : theme.colors.onBackground, fontSize: 12 }} fontType="overline">
            {item.title || item}
          </CText>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default GeneralSwitch;

const styles = StyleSheet.create({
  root: {
    borderRadius: Spacing.bigRadius,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: ROOT_BORDER_WIDTH,
    borderColor: 'white',
    overflow: 'hidden',
    padding: ROOT_PADDING,
    height: 32,
  },
  bubble: {
    top: 2,
    start: 2,
    bottom: 2,
    position: 'absolute',
    borderRadius: Spacing.bigRadius,
  },
  option: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: Spacing.small,
    borderRadius: Spacing.regularRadius,
  },
});
