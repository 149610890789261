import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { requestPermissions } from './common';

export async function saveBase64Image(base64Image: string, filename: string): Promise<void> {
  if (Platform.OS === 'web') {
    // The following code will run only in a web browser
    const link = document.createElement('a');
    link.href = base64Image;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    const hasPermissions = await requestPermissions();

    if (hasPermissions) {
      const newFileUri = FileSystem.documentDirectory + filename;

      await Sharing.shareAsync(newFileUri, {
        mimeType: 'image/jpeg',
        dialogTitle: 'Share or save the image',
      });
    }
  }
}
