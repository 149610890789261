import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import Item from './Item';
import Spacing from '../../../../res/constants/Spacing';

interface Props {
  currentSelectedItem: any;
  setCurrentSelectedItem: (value: any) => void;
  options: any[];
}

const Options: FC<Props> = ({ currentSelectedItem, setCurrentSelectedItem, options }) => {
  return (
    <View style={styles.root}>
      {options.map((item, index) => {
        const selected = currentSelectedItem.id === item.id;
        return <Item key={index} {...{ item, selected, setCurrentSelectedItem }} />;
      })}
    </View>
  );
};

export default Options;

const styles = StyleSheet.create({ root: { flexDirection: 'row', justifyContent: 'center', paddingHorizontal: Spacing.regular } });
