import { StyleSheet, Text, View } from 'react-native';
import React, { FC } from 'react';
import { Chip } from 'react-native-paper';
import { IGeneralItem } from '../../../../models/IGeneralItem';
import { MATERIAL_COLORS } from '../../../../res/constants/Colors';

interface Props {
  item: IGeneralItem;
  selectOption: (value: IGeneralItem) => void;
  isOptionSelected: (value: IGeneralItem) => boolean;
}
const LabelSelectoreListitem: FC<Props> = ({ item, selectOption, isOptionSelected }) => {
  // #region STATES
  // #endregion
  // #region CUSTOM HOOKS
  // #endregion
  // #region FUNCTIONS
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <Chip
      theme={{ colors: { secondaryContainer: MATERIAL_COLORS.light_blue_200 } }}
      showSelectedOverlay
      style={{ flexGrow: 0 }}
      selected={isOptionSelected(item)}
      onPress={() => selectOption(item)}
    >
      {item.title}
    </Chip>
  );
};

export default LabelSelectoreListitem;

const styles = StyleSheet.create({ root: {} });
