import { StyleSheet, Text, View } from 'react-native';
import React, { FC, memo } from 'react';
import Styles from '../../res/constants/Styles';
import useColorScheme from '../../hooks/useColorScheme';

interface Props {
  checked: boolean;
}

//#region CONSTANS
const SIZE = 18;
//#endregion
const RadioButton: FC<Props> = ({ checked }) => {
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];
  return (
    <View style={[styles.root, { borderColor: theme.icon }]}>{checked && <View style={[styles.indicator, { backgroundColor: theme.icon }]} />}</View>
  );
};

export default memo(RadioButton);

const styles = StyleSheet.create({
  root: {
    height: SIZE,
    width: SIZE,
    borderRadius: SIZE / 2,
    borderWidth: 2,
    padding: 2,
  },
  indicator: {
    flex: 1,
    borderRadius: SIZE / 2,
  },
});
