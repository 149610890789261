import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC } from 'react';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { IGeneralItem } from '../../models/IGeneralItem';
import { Chip, IconButton, Menu, Text, useTheme } from 'react-native-paper';
import useToggle from '../../hooks/useToggle';
import Spacing from '../../res/constants/Spacing';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { setSelectedDepartment } from '../../app/features/departments/departmentsSlice';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { MATERIAL_COLORS } from '../../res/constants/Colors';

const DepartmentPicker = () => {
  // #region STATES
  const [visibility, toggleMenu] = useToggle(false);
  // #endregion
  // #region CUSTOM HOOKS
  const dispatch = useAppDispatch();
  const value: number = useAppSelector((state) => state.departments.selectedDepartment);
  const { translate } = useLocalization();
  const theme = useTheme();
  // #endregion
  // #region FUNCTIONS
  const handleMenuClick = (value: IGeneralItem) => {
    dispatch(setSelectedDepartment(value.id));
    toggleMenu();
  };

  // #endregion
  // #region MEMBERS
  const departments = translate('pages.menu.recipient') as IGeneralItem[];

  const handleGetDepartmentName = () => {
    const foundIndex = departments.findIndex((i: IGeneralItem) => i.id === value);

    if (foundIndex > -1) {
      return departments[foundIndex].title;
    } else return 'Error';
  };
  // #endregion
  return (
    <View style={styles.root}>
      <Menu
        onDismiss={() => toggleMenu()}
        visible={visibility}
        anchor={
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => toggleMenu()}
            style={{
              backgroundColor: theme.colors.primary,
              paddingLeft: Spacing.small,
              paddingVertical: Spacing.minimal,
              borderRadius: Spacing.defaultRadius,
              flexDirection: 'row',
              alignItems: 'center',
              overflow: 'hidden',
              marginHorizontal: Spacing.regular,
            }}
          >
            <Text variant="labelMedium" style={{ color: theme.colors.onPrimary }}>
              {handleGetDepartmentName()}
            </Text>
            <MaterialCommunityIcons name="filter" color={theme.colors.onPrimary} style={{ paddingHorizontal: Spacing.small }} />
          </TouchableOpacity>
        }
      >
        {departments.map((item, index) => {
          return (
            <Menu.Item
              style={{
                backgroundColor: value === item.id ? theme.colors.primaryContainer : undefined,
              }}
              key={index}
              title={item.title}
              onPress={() => handleMenuClick(item)}
            />
          );
        })}
      </Menu>
    </View>
  );
};

export default DepartmentPicker;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
