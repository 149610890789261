import { FlatList, StyleSheet, View } from 'react-native';
import React, { FC, useEffect, useState } from 'react';
import { IOrder } from '../../models/IOrder';
import { ITable } from '../../models/ITable';
import { Avatar, Button, Divider, Text } from 'react-native-paper';
import { formatDistance } from 'date-fns';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { DEFAULT_GAP } from '../../res/constants/Layout';
import { Orientation } from '../../utils/enums';
import GeneralSeparator from '../separator/GeneralSeparator';
import OrderListItem from './OrderListItem';
import { SafeAreaView } from 'react-native-safe-area-context';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import ConfirmDialog from '../dialogs/confirm/ConfirmDialog';
import { useFirestore } from '../../app/providers/firestore';
import { currencyResolver, printInvoice } from '../../utils/common';
import OrderDurationPicker from './OrderDurationPicker';

interface Props {
  order: IOrder;
  table: ITable;
  onSubmit: (duration?: number, isCompleted?: boolean) => void;
  department: number;
}

const OrderDetailComponentRefactor: FC<Props> = ({ order, table, onSubmit, department }) => {
  //#region STATES
  const [durationValue, setDurationValue] = useState<number | undefined>(order.duration);
  const [errorDialogVisibility, setErrorDialogVisibility] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  const { currentUser } = useFirestore();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  const total = order.cartItems.reduce((total, item) => (total += item.price), 0);

  const groupedItems = order.cartItems?.reduce((accumulator: any, currentValue: any) => {
    accumulator[currentValue.key] = accumulator[currentValue.key] || [];
    accumulator[currentValue.key].price += currentValue.price;
    accumulator[currentValue.key].push(currentValue);
    return accumulator;
  }, {});

  //#endregion
  //#region FUNCTIONS
  const handleOnClickSelectDuration = (value: number) => {
    setDurationValue(value);
    setError(false);
  };

  const isPending = () => {
    return order.cartItems.some((item) => item.meta?.isPending ?? true);
  };

  const isCompleted = () => {
    return order.cartItems.every((item) => item.meta?.isCompleted ?? false);
  };

  //#endregion

  useEffect(() => {
    setDurationValue(order.duration);
  }, []);

  return (
    <SafeAreaView style={styles.root} edges={['bottom']}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View style={{ flex: 1 }}>
          <Text style={{ color: theme.text }} variant="headlineMedium">
            {table.name}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: Spacing.medium,
            }}
          >
            <Avatar.Icon size={24} icon="clock-time-three" style={{ backgroundColor: theme.primary }} />
            <Text style={{ marginStart: Spacing.medium, color: theme.text }} variant="labelLarge">
              {order &&
                order.meta &&
                order.meta.created &&
                formatDistance(order.meta.created, new Date(), {
                  addSuffix: true,
                })}
            </Text>
          </View>

          {order.meta.modified && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: Spacing.medium,
              }}
            >
              <Avatar.Icon size={24} icon="check" style={{ backgroundColor: theme.primary }} />
              <Text style={{ marginStart: Spacing.medium, color: theme.text }} variant="labelLarge">
                {order &&
                  formatDistance(order.meta.modified, new Date(), {
                    addSuffix: true,
                  })}
              </Text>
            </View>
          )}

          {table.category === 2 && (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: Spacing.medium,
                }}
              >
                <Avatar.Icon size={24} icon="account" style={{ backgroundColor: theme.primary }} />
                <Text
                  style={{ marginStart: Spacing.medium, color: theme.text }}
                  variant="labelLarge"
                >{`${order?.customerName} • ${order?.customerPhone}`}</Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: Spacing.medium,
                }}
              >
                <Avatar.Icon size={24} icon="map-marker" style={{ backgroundColor: theme.primary }} />
                <Text style={{ marginStart: Spacing.medium, color: theme.text }} variant="labelLarge">
                  {order.customerAddress}
                </Text>
              </View>
            </View>
          )}
        </View>
        <View style={{ alignItems: 'flex-end' }}>
          <Text style={{ color: theme.text }} variant="headlineMedium">{`${currencyResolver(currentUser?.currency)?.symbol} ${total}`}</Text>

          {table.category === 2 && (
            <OrderDurationPicker
              error={error}
              handleOnItemClicked={handleOnClickSelectDuration}
              buttonText={`${translate('general.duration')}: ${
                durationValue ? durationValue : order.duration === undefined || order.duration === null ? '___' : order.duration
              } min`}
            />
          )}
        </View>
      </View>

      <Divider style={{ marginVertical: Spacing.regular }} />

      <View style={{ flex: 1 }}>
        <FlatList
          ItemSeparatorComponent={() => <GeneralSeparator orientation={Orientation.HORIZONTAL} size={'90%'} color={MATERIAL_COLORS.grey_300} />}
          data={
            groupedItems
              ? Object.entries(groupedItems).map(([key, data]) => ({
                  key,
                  data,
                }))
              : []
          }
          keyExtractor={(item) => item?.key?.toString()}
          contentContainerStyle={{ gap: DEFAULT_GAP }}
          renderItem={({ item, index }: { item: any; index: number }) => {
            return <OrderListItem key={index} {...{ item }} />;
          }}
        />
      </View>

      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        {isPending() ? (
          <Button
            icon={'cart'}
            mode="contained-tonal"
            contentStyle={{ backgroundColor: theme.primary }}
            textColor="white"
            onPress={() => {
              if (durationValue || table.category !== 2) {
                if (currentUser) {
                  onSubmit(durationValue, false);
                }
              } else if (table.category === 2 && durationValue === undefined) setErrorDialogVisibility(true);
            }}
          >
            {translate('general.accept')}
          </Button>
        ) : (
          <>
            {!isCompleted() && (
              <Button
                icon={'check'}
                mode="contained-tonal"
                contentStyle={{ backgroundColor: theme.primary }}
                textColor="white"
                onPress={() => onSubmit(durationValue, true)}
              >
                {translate('general.complete_order')}
              </Button>
            )}
          </>
        )}
        <Button
          onPress={() => {
            if (currentUser) {
              printInvoice(
                Object.entries(groupedItems).map(([key, data]) => ({ key, data })),
                currentUser,
                table.name,
                order.customerPhone,
                order.customerAddress
              );
            }
          }}
          icon={'printer'}
        >
          Print
        </Button>
      </View>

      <ConfirmDialog
        title={translate('general.error').toString()}
        message={translate('general.duration_missing_error').toString()}
        visible={errorDialogVisibility}
        dismiss={() => {
          setErrorDialogVisibility(false);
        }}
        onConfirm={() => {
          setError(true);
          setErrorDialogVisibility(false);
        }}
      />
    </SafeAreaView>
  );
};

export default OrderDetailComponentRefactor;

const styles = StyleSheet.create({
  root: {
    padding: Spacing.regular,
    flex: 1,
  },
});
