import { SectionList, StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC, useState } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import Styles from '../../res/constants/Styles';
import Spacing from '../../res/constants/Spacing';
import { format } from 'date-fns';
import { ActivityIndicator, Avatar, Divider, IconButton } from 'react-native-paper';
import { getInitials } from '../../utils/common';
import { Text } from 'react-native-paper';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { useFirestore } from '../../app/providers/firestore';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { FormType, UserRole } from '../../utils/enums';
import { IStaff } from '../../models/IStaff';
import ConfirmDialog from '../dialogs/confirm/ConfirmDialog';
import collections from '../../utils/collections';

interface Props {
  data: any[];
  onEndReached?: (info: { distanceFromEnd: number }) => void;
  loading: boolean;
  setReload: () => void;
}
const SectionListSideMenu: FC<Props> = ({ data, onEndReached, loading, setReload }) => {
  // #region STATES
  const [confirmDialogVisibility, setConfirmDialogVisibility] = useState<boolean>(false);
  const [markedItem, setMarkedItem] = useState(undefined);
  // #endregion
  // #region CUSTOM HOOKS
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  const { staff, currentUser, handleDeleteData } = useFirestore();
  const navigation = useNavigation<NavigationProp<{ EditStaff: { key?: string; type: FormType } }, 'EditStaff'>>(); // #endregion
  // #endregion
  // #region FUNCTIONS
  const renderFooter = () => {
    return loading && data.length > 0 ? (
      <View style={{ padding: Spacing.regular, alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator />
      </View>
    ) : null;
  };

  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  const theme = Styles[colorScheme];
  // #endregion
  return (
    <View style={[styles.root, { backgroundColor: theme.lightSelectedItem }]}>
      <View style={{ borderRadius: Spacing.defaultRadius, overflow: 'hidden', flex: 1, backgroundColor: 'white' }}>
        <SectionList
          ListEmptyComponent={() => (
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ color: theme.text }} variant="titleMedium">
                {translate('general.no_data')}
              </Text>
            </View>
          )}
          ListFooterComponent={renderFooter}
          contentContainerStyle={{ flexGrow: 1 }}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.5}
          ItemSeparatorComponent={() => (
            <Divider style={{ width: '95%', alignSelf: 'center' }} theme={{ colors: { outlineVariant: MATERIAL_COLORS.grey_200 } }} />
          )}
          sections={data}
          keyExtractor={(item, index) => item.key ?? index.toString()} // Adjust the keyExtractor based on your item structure
          renderSectionHeader={({ section: { title } }) => (
            <View
              style={{
                padding: Spacing.regular,
                flexDirection: 'row',
                alignItems: 'center',
                borderBottomWidth: 1,
                borderBottomColor: MATERIAL_COLORS.grey_300,
                backgroundColor: MATERIAL_COLORS.grey_50,
              }}
            >
              <Text style={{ textTransform: 'capitalize', flex: 1 }} variant="titleMedium">
                {title.header}
              </Text>
              <Text style={{ textTransform: 'capitalize' }} variant="titleMedium">
                {title.total}
              </Text>
            </View>
          )}
          renderItem={({ item }) => {
            const total = item.cartItems.reduce((total: number, item: { price: number }) => (total += item.price), 0);

            let currentStaff: IStaff | undefined = undefined;

            const foundIndex = staff.findIndex((i) => i.key === item.staffKey);

            if (foundIndex > -1 && currentUser?.role === UserRole.MANAGER) {
              currentStaff = staff[foundIndex];
            }

            return (
              <View style={{ padding: Spacing.regular, alignItems: 'center', flexDirection: 'row', gap: Spacing.regular }}>
                {currentStaff !== undefined && (
                  <TouchableOpacity onPress={() => navigation.navigate('EditStaff', { key: currentStaff && currentStaff.key, type: FormType.EDIT })}>
                    <Avatar.Text label={getInitials(currentStaff.name)} size={24} />
                  </TouchableOpacity>
                )}

                <Text style={{ flex: 1, textTransform: 'capitalize' }}>{item.meta.created && format(item.meta.created, 'HH:mm')}</Text>

                <Text>{total}</Text>
                {/* <IconButton
                  icon={'delete'}
                  onPress={() => {
                    setConfirmDialogVisibility(true);
                    setMarkedItem(item);
                  }}
                  size={24}
                /> */}
              </View>
            );
          }}
        />
      </View>

      <ConfirmDialog
        title={'Are you sure?'}
        message={`Attention! You are about to delete this item forever, you can't undo it.`}
        visible={confirmDialogVisibility}
        dismiss={() => {
          setConfirmDialogVisibility(false);
        }}
        onConfirm={() => {
          handleDeleteData(markedItem, collections.orders).then(() => {
            setConfirmDialogVisibility(false);
            setReload();
          });
        }}
      />
    </View>
  );
};

export default SectionListSideMenu;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Spacing.regular,
    borderRadius: Spacing.defaultRadius,
  },
});
