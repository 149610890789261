import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';

import { firebase, FirebaseFirestoreTypes } from '@react-native-firebase/firestore';
import useAuth from '../authentication/AuthenticationProvider.mobile';
import { IMenu, ISuggestion } from '../../../models/IMenu';
import { IUser } from '../../../models/IUser';
import collections from '../../../utils/collections';
import { ITable } from '../../../models/ITable';
import { IStaff } from '../../../models/IStaff';
import { IOrder } from '../../../models/IOrder';

const Context = createContext<{
  currentUser: IUser | undefined;
  handleDataCU: (params: any, collection: string) => Promise<any>;
  handleDataUnAuthorized: (business: string, params: any, collection: string) => Promise<any>;
  handleDeleteData: (params: any, collection: string) => Promise<any>;
  handleGetDocument: (id: string, params: string, collection: string) => Promise<any>;
  handleGetCollection: (key: string, collection: string) => Promise<any>;
  handleListenDocumentChange: (key: string, uid: string) => Promise<any>;
  handleListenCollectionChange: (key: string, collection: string) => FirebaseFirestoreTypes.Query<FirebaseFirestoreTypes.DocumentData>;
  handleListenUsersChange: () => FirebaseFirestoreTypes.Query<FirebaseFirestoreTypes.DocumentData>;
  handleGetUserDocument: (key: string) => Promise<any>;
  menu: IMenu[];
  staff: IStaff[];
  suggestedMenu: ISuggestion[];
  orders: IOrder[];
}>({
  currentUser: undefined,
  handleDataCU: () => Promise.prototype,
  handleDataUnAuthorized: () => Promise.prototype,
  handleDeleteData: () => Promise.prototype,
  handleGetDocument: () => Promise.prototype,
  handleGetCollection: () => Promise.prototype,
  handleListenDocumentChange: () => Promise.prototype,
  handleListenCollectionChange: () => {
    // You should replace this with the actual implementation
    return firebase.firestore().collection('dummy').limit(0);
  },
  handleListenUsersChange: () => {
    // You should replace this with the actual implementation
    return firebase.firestore().collection('dummy').limit(0);
  },
  handleGetUserDocument: () => Promise.prototype,
  menu: [],
  staff: [],
  suggestedMenu: [],
  orders: [],
});

export interface IFirestoreProviderProps {
  children: ReactNode;
}

export const FirestoreProvider = ({ children }: IFirestoreProviderProps) => {
  //#region HOOKS
  const { user } = useAuth();
  //#endregion
  //#region STATES
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const [menu, setMenu] = useState<IMenu[]>([]);
  const [tables, setTables] = useState<ITable[]>([]);
  const [staff, setStaff] = useState<IStaff[]>([]);
  const [suggestedMenu, setSuggestedMenu] = useState<ISuggestion[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);

  //#endregion
  //#region MEMOIZED
  const usersRef = useMemo(() => firebase.firestore().collection(collections.users), []);
  const dataRef = useMemo(() => firebase.firestore().collection(collections.data), []);
  const memoValue = useMemo(
    () => ({
      currentUser,
      handleDataCU,
      handleDataUnAuthorized,
      handleDeleteData,
      handleGetDocument,
      handleGetCollection,
      handleListenDocumentChange,
      handleListenCollectionChange,
      handleListenUsersChange,
      handleGetUserDocument,
      menu,
      staff,
      suggestedMenu,
      orders,
    }),
    [currentUser, menu, staff, suggestedMenu, orders, handleListenDocumentChange]
  );
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    if (user !== null && user) {
      const userQuery = usersRef.doc(user.uid);

      const menuQueryUnSubscribe = dataRef.doc(user.uid).collection(collections.menu).onSnapshot(onMenuCollectionResult);
      const tableQueryUnSubscribe = dataRef.doc(user.uid).collection(collections.table).onSnapshot(onTableCollectionResult);
      const staffQueryUnSubscribe = dataRef.doc(user.uid).collection(collections.staff).onSnapshot(onStaffCollectionResult);
      const ordersQueryUnSubscribe = dataRef
        .doc(user.uid)
        .collection(collections.orders)
        .where('isPending', '==', true)
        .onSnapshot(onOrdersCollectionResult);

      const suggestedMenuQueryUnSubscribe = dataRef
        .doc(user.uid)
        .collection(collections.menu)
        .where('canBeSuggested', '==', true)
        .onSnapshot(onSuggestedMenuCollectionResult);

      const userQueryUnSubscribe = userQuery.onSnapshot((value) => {
        if (value.exists) {
          const data = value.data() as IUser;
          setCurrentUser(data);
        }
      });

      return () => {
        menuQueryUnSubscribe();
        tableQueryUnSubscribe();
        staffQueryUnSubscribe();
        suggestedMenuQueryUnSubscribe();
        ordersQueryUnSubscribe();
        userQueryUnSubscribe();
      };
    } else setCurrentUser(undefined);
  }, [user]);
  //#endregion
  //#region FUNCTIONS
  function onMenuCollectionResult(querySnapshot: FirebaseFirestoreTypes.QuerySnapshot<FirebaseFirestoreTypes.DocumentData>) {
    const temporary: any[] = [];
    querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });
    setMenu(temporary);
  }
  function onTableCollectionResult(querySnapshot: FirebaseFirestoreTypes.QuerySnapshot<FirebaseFirestoreTypes.DocumentData>) {
    const temporary: any[] = [];
    querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });
    setTables(temporary);
  }
  function onStaffCollectionResult(querySnapshot: FirebaseFirestoreTypes.QuerySnapshot<FirebaseFirestoreTypes.DocumentData>) {
    const temporary: any[] = [];
    querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });
    setStaff(temporary);
  }

  function onSuggestedMenuCollectionResult(querySnapshot: FirebaseFirestoreTypes.QuerySnapshot<FirebaseFirestoreTypes.DocumentData>) {
    const temporary: any[] = [];
    querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });
    setSuggestedMenu(temporary);
  }

  function onOrdersCollectionResult(querySnapshot: FirebaseFirestoreTypes.QuerySnapshot<FirebaseFirestoreTypes.DocumentData>) {
    const temporary: any[] = [];
    querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });
    setOrders(temporary);
  }
  async function handleDataCU(params: any, collection: string) {
    if (currentUser) {
      const parentRef = dataRef.doc(currentUser.uid).collection(collection);
      if (!params.key) {
        params.key = parentRef.doc().id;
      }

      if (params.key) {
        const ref = parentRef.doc(params.key);
        await ref.get().then((document) => {
          if (!document.exists) {
            ref
              .set(params)
              .then(() => console.debug('Item Added'))
              .catch((error: any) => console.error(error));
          } else {
            ref
              .update(params)
              .then(() => console.debug('Item Updated'))
              .catch((error: any) => console.error(error));
          }
        });
      }
    }
  }

  async function handleDeleteData(params: any, collection: string) {
    if (currentUser) {
      const parentRef = dataRef.doc(currentUser.uid).collection(collection);
      if (params.key === undefined || params.key === null || params.key === '') {
        params.key = parentRef.doc().id;
      }

      if (params.key) {
        const ref = parentRef.doc(params.key);
        await ref.get().then((document) => {
          if (document.exists) {
            ref
              .delete()
              .then(() => console.debug('Item Deleted'))
              .catch((error: any) => console.error(error));
          }
        });
      }
    }
  }

  async function handleGetDocument(id: string, key: string, collection: string) {
    const parentRef = dataRef.doc(id).collection(collection);

    return (await parentRef.doc(key).get()).data();
  }

  async function handleGetUserDocument(id: string) {
    const payload = await usersRef.doc(id).get();
    return payload.data();
  }

  async function handleGetCollection(key: string, collection: string) {
    const parentRef = dataRef.doc(key).collection(collection);
    const collections = await parentRef.get();

    const temporary: any[] = [];

    collections.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });

    return temporary;
  }

  async function handleDataUnAuthorized(business: string, params: any, collection: string) {
    const parentRef = dataRef.doc(business).collection(collection);
    if (!params.key) {
      params.key = parentRef.doc().id;
    }

    if (params.key) {
      const ref = parentRef.doc(params.key);
      await ref.get().then((document) => {
        if (!document.exists) {
          ref
            .set(params)
            .then(() => console.debug('Item Added'))
            .catch((error: any) => console.error(error));
        } else {
          ref
            .update(params)
            .then(() => console.debug('Item Updated'))
            .catch((error: any) => console.error(error));
        }
      });
    }
  }

  //#region LISTENERS
  async function handleListenDocumentChange(key: string, uid: string): Promise<any> {
    return new Promise<any>((resolve) => {
      dataRef
        .doc(uid)
        .collection(collections.orders)
        .doc(key)
        .onSnapshot((value) => {
          if (value.exists) {
            resolve(value.data());
          }
        });
    });
  }

  function handleListenCollectionChange(uid: string, collection: string) {
    return dataRef.doc(uid).collection(collection);
  }

  function handleListenUsersChange() {
    return usersRef;
  }
  //#endregion

  //#endregion

  return <Context.Provider value={memoValue}>{children}</Context.Provider>;
};

export default function useFirestore() {
  return useContext(Context);
}
