import { IPrice } from '../../models/IPrice';

export const handleExtractMinimumPrice = (price: number | IPrice[]) => {
  if (Array.isArray(price)) {
    // Extract numeric values
    let numericPrices = price
      .map((price) => Number(price.value)) // Convert all values to numbers
      .filter((value) => !isNaN(value)); // Filter out non-numeric values

    // If there are valid numeric prices, find the minimum
    return numericPrices.length > 0 ? Math.min(...numericPrices) : null;
  } else {
    // If price is a number, return it; if it's not a number, return null
    return !isNaN(Number(price)) ? Number(price) : null;
  }
};
