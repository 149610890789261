import { Platform } from 'react-native';
import { FirestoreProvider as Web } from './FirestoreProvider.web';
import { FirestoreProvider as Mob } from './FirestoreProvider.mobile';
import { default as useWeb } from './FirestoreProvider.web';
import { default as useMob } from './FirestoreProvider.mobile';

export const FirestoreProvider = Platform.select({
  web: () => Web,
  default: () => Mob,
})();

export const useFirestore = Platform.select({
  web: () => useWeb,
  default: () => useMob,
})();
