import { Alert, StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { IStaff } from '../../../models/IStaff';
import Spacing from '../../../res/constants/Spacing';
import { Button } from 'react-native-paper';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';
import { IMeta } from '../../../models/IMeta';
import collections from '../../../utils/collections';
import { FormType, ItemStatus, UserRole } from '../../../utils/enums';
import { useFirestore } from '../../../app/providers/firestore';
import { useAppSelector } from '../../../hooks/hooks';
import ConfirmDialog from '../../../components/dialogs/confirm/ConfirmDialog';
import { ITable } from '../../../models/ITable';
import ExpandableListContainer from '../../../components/expandable/ExpandableListContainer';
import { IGeneralItem } from '../../../models/IGeneralItem';
import LabelSelector from '../../../components/general/selectable/label-selector/LabelSelector';
import { useFocusEffect } from '@react-navigation/native';
import CustomInput from '../../../components/inputs/CustomInput';

interface Props {
  currentModel?: ITable;
  formType: FormType;
  handleClearObject: () => void;
  archivedMode: boolean;
  confirmDialogVisibility: boolean;
  toggleConfirmDialog: () => void;
}

enum FieldType {
  CATEGORY,
  TYPE,
}

const TableForm: FC<Props> = ({ currentModel, formType, handleClearObject, archivedMode, confirmDialogVisibility, toggleConfirmDialog }) => {
  // #region STATES
  const [staffList, setStaffList] = useState<IStaff[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  //#endregion
  // #region CUSTOM HOOKS
  const { handleDataUnAuthorized, handleListenCollectionChange, currentUser } = useFirestore();
  const { translate } = useLocalization();
  const currentSelectedUser = useAppSelector((state) => state.backOffice.selectedUser);
  const tableCategory = translate('pages.table.category') as IGeneralItem[];
  const tableType = translate('pages.table.type') as IGeneralItem[];

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ITable>({ values: currentModel });
  // #endregion
  // #region FUNCTIONS

  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
        else Alert.alert('Error', 'You need to select a user');
      } else {
        return currentUser.uid;
      }
    }
  };

  const onSubmit = (params: FieldValues) => {
    const { name, size, category, type, staff } = params;

    setLoading(true);
    const meta: IMeta =
      formType === FormType.NEW
        ? { created: new Date().getTime(), status: ItemStatus.ACTIVE }
        : { ...currentModel?.meta, modified: new Date().getTime() };

    const payload: ITable = {
      key: currentModel?.key,
      size,
      meta,
      name,
      category,
      type: type,
      staff: staff || null,
    };

    // for (let i = 3; i < 24; i++) {
    //   console.log(i);
    // }

    const uid = handleTargetUid();

    if (uid) {
      handleDataUnAuthorized(uid, payload, collections.table).then(() => {
        handleClearForm();
        handleClearObject();
        setLoading(false);
      });
    }
  };

  const handleClearForm = () => {
    const defaultValues = {
      name: '',
      phone: '',
      meta: {},
    };
    reset(defaultValues);
  };

  const handleGetTableObject = (value: number, field: FieldType) => {
    let foundIndex = -1;

    switch (field) {
      case FieldType.CATEGORY:
        foundIndex = tableCategory.findIndex((i) => i.id === value);
        break;

      default:
        foundIndex = tableType.findIndex((i) => i.id === value);
        break;
    }

    if (foundIndex > -1) {
      return field === FieldType.CATEGORY ? tableCategory[foundIndex] : tableType[foundIndex];
    } else {
      return undefined;
    }
  };
  const handleSetSelectedObject = (item: IGeneralItem, field: FieldType) => {
    const id = Number(item.id);

    if (field === FieldType.CATEGORY) {
      setValue('category', id);
    } else {
      setValue('type', id);
    }
  };

  // #endregion
  // #region EFFECTS
  useEffect(() => {
    if (formType === FormType.NEW) {
      handleClearForm();
    }
  }, [formType]);

  useFocusEffect(
    useCallback(() => {
      const uid = handleTargetUid();
      if (uid) {
        const staff = handleListenCollectionChange(uid, collections.staff)
          .where('meta.status', '==', archivedMode ? ItemStatus.INACTIVE : ItemStatus.ACTIVE)
          .onSnapshot((querySnapshot) => {
            const temporary: any[] = [];
            querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
              temporary.push({
                ...documentSnapshot.data(),
                key: documentSnapshot.id,
              });
            });
            setStaffList(temporary);
          });

        return () => {
          staff();
        };
      }
    }, [currentUser, currentSelectedUser, archivedMode])
  );

  // #endregion
  // #region MEMBERS
  //#endregion
  return (
    <View style={[styles.root]}>
      <View style={{ flex: 1, gap: Spacing.micro }}>
        <CustomInput
          error={errors.name}
          required={true}
          control={control}
          name={'name'}
          placeholder={translate('general.name') as string}
          iconProps={{ leftName: 'Paper', isLeftIconly: true, showRight: false }}
        />
        <CustomInput
          error={errors.size}
          required={true}
          control={control}
          name={'size'}
          placeholder={translate('general.table_size') as string}
          iconProps={{ leftName: 'People', isLeftIconly: true, showRight: false }}
        />

        <ExpandableListContainer
          title={handleGetTableObject(watch('category'), FieldType.CATEGORY)?.title}
          items={tableCategory}
          placeholder={translate('general.category') as string}
          selectedId={handleGetTableObject(watch('category'), FieldType.CATEGORY)?.id}
          onSelectItem={(value) => handleSetSelectedObject(value, FieldType.CATEGORY)}
          iconProps={{ leftName: 'Category', isLeftIconly: true, rightName: 'sort', isRightIconly: false }}
        />
        <ExpandableListContainer
          title={handleGetTableObject(watch('type'), FieldType.TYPE)?.title}
          items={tableType}
          placeholder={translate('general.type') as string}
          selectedId={handleGetTableObject(watch('type'), FieldType.TYPE)?.id}
          onSelectItem={(value) => handleSetSelectedObject(value, FieldType.TYPE)}
          iconProps={{ leftName: 'Category', isLeftIconly: true, rightName: 'sort', isRightIconly: false }}
        />

        <LabelSelector
          multiple={true}
          value={watch('staff')}
          options={staffList.map((item: IStaff) => ({
            id: item.key ? item.key : new Date().getTime().toString(),
            title: item.name,
          }))}
          onChange={(value) => {
            setValue('staff', value);
          }}
        />
      </View>

      <Button loading={loading} style={{ marginTop: Spacing.regular }} disabled={loading} onPress={handleSubmit(onSubmit)} mode="contained">
        {translate('general.save') as string}
      </Button>

      <ConfirmDialog
        title={'Are you sure?'}
        message={`Attention! You are about to ${archivedMode ? 'restore' : 'remove'} this item.`}
        visible={confirmDialogVisibility}
        dismiss={() => toggleConfirmDialog()}
        onConfirm={() => {
          const uid = handleTargetUid();

          if (uid)
            handleDataUnAuthorized(
              uid,
              {
                ...currentModel,
                meta: { ...currentModel?.meta, modified: new Date().getTime(), status: archivedMode ? ItemStatus.ACTIVE : ItemStatus.INACTIVE },
              },
              collections.table
            ).then(() => {
              toggleConfirmDialog();
              handleClearObject();
            });
        }}
      />
    </View>
  );
};

export default TableForm;

const styles = StyleSheet.create({ root: { flex: 1 } });
