import { StyleSheet, ViewProps } from 'react-native';
import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import Animated, { runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

interface Props extends ViewProps {
  children?: ReactNode;
}

export type ExpandableViewRef = {
  present: (value: number) => void;
  dismiss: () => void;
  isOpen: boolean;
};
const ExpandableView = forwardRef<ExpandableViewRef, Props>(({ children }, ref) => {
  //#region STATES
  const [active, setActive] = useState<boolean>(false);
  //#endregion
  //#region VALUES
  const height = useSharedValue(0);
  //#endregion
  //#region ACTIONS
  const present = useCallback((value: number) => {
    setActive(true);
    animate(value);
  }, []);

  const dismiss = useCallback(() => {
    animate(0);
  }, []);

  const isOpen = useMemo(() => {
    return active;
  }, [active]);

  useImperativeHandle(ref, () => ({ present, dismiss, isOpen }), [present, dismiss, isOpen]);
  //#endregion
  //#region ANIMATED STYLES
  const rStyle = useAnimatedStyle(() => {
    const display = height.value === 0 ? 'none' : 'flex';
    return { height: height.value };
  });
  //#endregion
  //#region FUNCTIONS
  const animate = (value: number) => {
    height.value = withTiming(value, { duration: 250 }, (finished) => {
      if (finished) {
        if (value === 0) runOnJS(setActive)(false);
      }
    });
  };
  //#endregion

  if (active) return <Animated.View style={[styles.root, rStyle]}>{children}</Animated.View>;
  else return null;
});

export default ExpandableView;

const styles = StyleSheet.create({ root: {} });
