import { MATERIAL_COLORS } from './Colors';

const tintColorLight = '#2f95dc';

export default {
  light: {
    primary: MATERIAL_COLORS.light_blue,
    onPrimary: MATERIAL_COLORS.white,
    text: MATERIAL_COLORS.blue_grey_900,
    background: '#fff',
    lightBackground: '#FAFCFE',
    lightSelectedItem: '#E2F0F9',
    listItemBackgroundColor: '#f2f5f7',
    onBackground: MATERIAL_COLORS.grey_50,
    tint: MATERIAL_COLORS.light_blue_300,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    icon: MATERIAL_COLORS.blue_grey_900,
    placeholder: MATERIAL_COLORS.grey_400,
    error: MATERIAL_COLORS.red,
  },
  dark: {
    primary: MATERIAL_COLORS.light_blue,
    onPrimary: MATERIAL_COLORS.white,
    text: MATERIAL_COLORS.blue_grey_900,
    background: '#fff',
    lightBackground: '#FAFCFE',
    lightSelectedItem: '#E2F0F9',
    listItemBackgroundColor: '#f2f5f7',
    onBackground: MATERIAL_COLORS.grey_50,
    tint: MATERIAL_COLORS.light_blue_300,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    icon: MATERIAL_COLORS.blue_grey_900,
    placeholder: MATERIAL_COLORS.grey_400,
    error: MATERIAL_COLORS.red,
  },
};
