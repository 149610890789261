import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import Spacing from '../../res/constants/Spacing';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Avatar, Text } from 'react-native-paper';
import { IUser } from '../../models/IUser';
import { currencyResolver } from '../../utils/common';

interface Props {
  item: any;
  currentUser: IUser;
}
const BillOrderListItem: FC<Props> = ({ item, currentUser }) => {
  const colorScheme = useColorScheme();
  const theme = Styles[colorScheme];

  console.log(item);
  return (
    <View style={styles.root}>
      <Text style={{ color: theme.text }} variant="bodyLarge">{`${item?.quantity} x`}</Text>
      {item && item.image && item.image !== undefined && item.image !== null ? (
        <Avatar.Image size={32} source={{ uri: item?.image }} style={[styles.image, { backgroundColor: theme.primary }]} />
      ) : (
        <Avatar.Icon size={32} icon={'image'} style={[styles.image, { backgroundColor: theme.primary }]} />
      )}

      <Text style={{ color: theme.text, flex: 1 }} variant="bodyMedium">
        {item && item.name}
      </Text>
      {item && (
        <Text style={{ color: theme.text }} variant="bodyMedium">
          {`${item.price} ${currencyResolver(currentUser.currency)?.symbol}`}
        </Text>
      )}
    </View>
  );
};

export default BillOrderListItem;

const styles = StyleSheet.create({
  root: { flexDirection: 'row', alignItems: 'center', padding: Spacing.medium },
  image: { marginHorizontal: Spacing.regular },
  title: { flex: 1, paddingHorizontal: Spacing.regular },
});
