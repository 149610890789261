import { StyleSheet } from 'react-native';
import React, { FC, memo } from 'react';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { DEFAULT_ITEM_HEIGHT } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import CText from '../CText';
import CustomPressable from '../pressable/CustomPressable';
import RadioButton from '../radio_buttons/RadioButton';

interface Props {
  value: string;
  checked: boolean;
  onPress: () => void;
}
const GeneralItem: FC<Props> = ({ value, checked, onPress }) => {
  return (
    <CustomPressable onPress={onPress} style={styles.root}>
      <RadioButton checked={checked} />
      <CText style={{ marginStart: Spacing.regular }} fontType="body1">
        {value}
      </CText>
    </CustomPressable>
  );
};

export default memo(GeneralItem, (prevProps, nextProps) => {
  return prevProps.checked === nextProps.checked;
});

const styles = StyleSheet.create({
  root: {
    height: DEFAULT_ITEM_HEIGHT - 1,
    alignItems: 'center',
    backgroundColor: MATERIAL_COLORS.grey_50,
    paddingHorizontal: Spacing.regular,
    flexDirection: 'row',
  },
});
