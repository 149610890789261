import { StyleSheet, Image, View, TouchableOpacity, Alert } from 'react-native';
import React, { FC } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import { ViewProps } from '../Themed';
import Styles from '../../res/constants/Styles';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { Image2 } from '../../../assets/icons';
import { useFirestore } from '../../app/providers/firestore';
import { ImageEditor } from 'expo-image-crop-editor';
import * as ImagePicker from 'expo-image-picker';
import useStorage from '../../app/providers/firebase-storage/StorageProvider';

interface Props extends ViewProps {
  size: number;
  source?: string | undefined | null;
  setSource: (value: string) => void;
  canChangePicture?: boolean;
}
const ProductPicture: FC<Props> = ({ size, source, setSource, canChangePicture = false, ...restOfProps }) => {
  //#region STATES
  const [imageUri, setImageUri] = React.useState<undefined | string>(undefined);
  const [editorVisible, setEditorVisible] = React.useState(false);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { currentUser } = useFirestore();
  const { uploadImage } = useStorage();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  //#region FUNCTIONS
  const selectPhoto = async () => {
    // Get the permission to access the camera roll
    const response = await ImagePicker.requestMediaLibraryPermissionsAsync();
    // If they said yes then launch the image picker
    if (response.granted) {
      const pickerResult = await ImagePicker.launchImageLibraryAsync();
      // Check they didn't cancel the picking
      if (!pickerResult.canceled) {
        launchEditor(pickerResult.assets[0].uri);
      }
    } else {
      // If not then alert the user they need to enable it
      Alert.alert('Please enable camera roll permissions for this app in your settings.');
    }
  };

  const launchEditor = (uri: any) => {
    // Then set the image uri
    setImageUri(uri);
    // And set the image editor to be visible
    setEditorVisible(true);
  };
  //#endregion
  return (
    <View
      {...restOfProps}
      style={[
        {
          height: size,
          width: size,
          borderRadius: size / 2,
          backgroundColor: theme.background,
        },
        styles.root,
        restOfProps.style,
      ]}
    >
      <TouchableOpacity
        onPress={selectPhoto}
        disabled={!canChangePicture}
        style={{ ...StyleSheet.absoluteFillObject, justifyContent: 'center', alignItems: 'center' }}
      >
        {source !== undefined && source !== null && source !== '' ? (
          <Image
            source={{ uri: source }}
            style={{
              ...StyleSheet.absoluteFillObject,
              borderRadius: size / 2,
              borderColor: theme.background,
              borderWidth: 5,
              backgroundColor: MATERIAL_COLORS.grey_200,
            }}
            resizeMode="cover"
          />
        ) : (
          <Image2 size={size - size * (10 / 100)} />
        )}
      </TouchableOpacity>
      <ImageEditor
        visible={editorVisible}
        onCloseEditor={() => setEditorVisible(false)}
        imageUri={imageUri}
        fixedCropAspectRatio={1}
        lockAspectRatio={true}
        minimumCropDimensions={{
          width: 100,
          height: 100,
        }}
        onEditingComplete={(result) => {
          uploadImage(result.uri, `${currentUser?.uid}/images/menu_images/${new Date().getTime()}`).then((imageUrl) => {
            if (currentUser) setSource(imageUrl);
          });
        }}
        throttleBlur={false}
        mode="crop-only"
        allowedTransformOperations={['crop']}
      />
    </View>
  );
};

export default ProductPicture;

const styles = StyleSheet.create({
  root: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
