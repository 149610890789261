import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { useAppSelector } from '../../hooks/hooks';
import { IMenu } from '../../models/IMenu';
import CText from '../CText';
import CustomPressable from './CustomPressable';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Buy } from '../../../assets/icons';
import Spacing from '../../res/constants/Spacing';
import { useNavigation } from '@react-navigation/native';

interface Props {
  businessId: string;
  tableKey: string;
}
const CustomCartIcon: FC<Props> = ({ businessId, tableKey }) => {
  //#region HOOKS
  const cartItems: IMenu[] = useAppSelector((state) => state.cart.items);
  const colorScheme = useColorScheme();
  const navigation = useNavigation();
  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion
  if (cartItems.length > 0) {
    return (
      <View style={[styles.root]}>
        <CustomPressable
          onPress={() => navigation.navigate('Cart' as never, { business: businessId, table: tableKey } as never)}
          style={{ flexDirection: 'row', alignItems: 'center', padding: Spacing.small }}
        >
          <Buy stroke="regular" size={24} set="light" />
        </CustomPressable>
        <View
          style={{
            position: 'absolute',
            backgroundColor: theme.primary,
            padding: Spacing.minimal,
            borderRadius: 50,
            height: 20,
            width: 20,
            alignItems: 'center',
            justifyContent: 'center',
            end: 0,
          }}
        >
          <CText
            style={{
              color: theme.onPrimary,
            }}
            fontType="caption"
          >
            {cartItems.length}
          </CText>
        </View>
      </View>
    );
  } else return null;
};

export default CustomCartIcon;

const styles = StyleSheet.create({
  root: {
    marginHorizontal: Spacing.medium,
  },
});
