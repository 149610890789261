import { FlatList, SectionList, StyleSheet, View } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { View as RootView } from '../../components/Themed';
import { useFirestore } from '../../app/providers/firestore';
import { ITable } from '../../models/ITable';
import CText from '../../components/CText';
import Spacing from '../../res/constants/Spacing';
import { Button } from 'react-native-paper';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { PrivateScreenProps } from '../../app/navigation/types';
import { FormType, ItemStatus, UserRole } from '../../utils/enums';
import TableCard from '../../components/table/TableCard';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { IGeneralItem } from '../../models/IGeneralItem';
import collections from '../../utils/collections';
import { useFocusEffect } from '@react-navigation/native';
import BusinessPicker from '../../components/general/userPicker/BusinessPicker';
import { useAppSelector } from '../../hooks/hooks';
import Container from '../../components/cropper/Container';
import ImageSelector from '../../components/images/ImageSelector';

enum FieldType {
  CATEGORY,
  TYPE,
}

const HomeScreen = ({ navigation }: PrivateScreenProps<'Home'>) => {
  //#region STATES
  const [tables, setTables] = useState<ITable[]>([]);
  const currentSelectedUser: any = useAppSelector((state) => state.backOffice.selectedUser);
  //#endregion
  //#region HOOKS
  const { currentUser, handleListenCollectionChange, orders } = useFirestore();
  const { translate } = useLocalization();
  const colorScheme = useColorScheme();
  const tableCategory = translate('pages.table.category') as IGeneralItem[];
  const tabletype = translate('pages.table.type') as IGeneralItem[];
  // const userIsPremium = usePremiumStatus(user);
  //#endregion
  //#region EFFECTS
  useFocusEffect(
    useCallback(() => {
      if (currentUser) {
        const uid = handleTargetUid();

        if (uid) {
          const unsubscribe = handleListenCollectionChange(uid, collections.table)
            .where('meta.status', '==', ItemStatus.ACTIVE)
            .onSnapshot((querySnapshot) => {
              const temporary: any[] = [];
              querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
                temporary.push({
                  ...documentSnapshot.data(),
                  key: documentSnapshot.id,
                });
              });
              setTables(temporary);
            });

          return () => {
            unsubscribe();
          };
        }
      }
    }, [currentUser, currentSelectedUser])
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => {
        {
          if (currentUser?.role === UserRole.BACKOFFICE) return <BusinessPicker />;
        }
      },
    });
  }, [navigation, currentUser, currentSelectedUser]);
  //#endregion
  //#region FUNCTIONS
  const handleGetName = (id: number, fieldType: FieldType) => {
    if (id !== undefined && id !== null) {
      if (fieldType === FieldType.CATEGORY) return tableCategory[id].title;
      else return `, ${tabletype[id].title}`;
    } else return '';
  };
  const handleTargetUid = () => {
    if (currentUser) {
      if (currentUser.role === UserRole.BACKOFFICE) {
        if (currentSelectedUser) return currentSelectedUser.uid;
      } else {
        return currentUser.uid;
      }
    }
  };
  //#endregion
  //#region MEMBERS
  const groupedItems = tables.reduce((acc, item) => {
    const title = `${handleGetName(item.category, FieldType.CATEGORY)}${handleGetName(item.type, FieldType.TYPE)}`;

    const groupIndex = acc.findIndex((group) => group.title === title);

    if (groupIndex === -1) {
      acc.push({ title, data: [item] });
    } else {
      acc[groupIndex].data.push(item);
    }

    return acc;
  }, [] as Array<{ title: string; data: ITable[] }>);

  const sortedData = groupedItems.map((section) => {
    return {
      ...section,
      data: section.data.sort((a, b) => {
        if (a.meta.created === undefined && b.meta.created === undefined) {
          return a.name.localeCompare(b.name);
        }

        if (a.meta.created === undefined) {
          return 1;
        }

        if (b.meta.created === undefined) {
          return -1;
        }

        if (a.meta.created === b.meta.created) {
          return a.name.localeCompare(b.name);
        }

        return a.meta.created - b.meta.created;
      }),
    };
  });

  const theme = Styles[colorScheme];

  //#endregion
  return (
    <RootView style={[styles.root, { backgroundColor: theme.lightBackground }]}>
      <SectionList
        ListEmptyComponent={() => (
          <View style={{ flex: 1, padding: Spacing.regular, alignItems: 'center', justifyContent: 'center' }}>
            <Button
              textColor={theme.primary}
              icon="plus"
              mode="text"
              onPress={() => navigation.navigate('EditTable', { key: undefined, type: FormType.NEW })}
            >
              Add new item
            </Button>
          </View>
        )}
        showsVerticalScrollIndicator={false}
        sections={sortedData}
        keyExtractor={(item, index) => item.key! + index}
        renderItem={() => null}
        contentContainerStyle={{ flexGrow: 1 }}
        renderSectionHeader={({ section: { title, data } }) => (
          <View style={{ padding: Spacing.medium }}>
            <CText style={{ paddingVertical: Spacing.small }} fontType="header5">
              {title}
            </CText>

            <View style={{ flexDirection: 'row', padding: Spacing.minimal, gap: Spacing.regular, flexWrap: 'wrap' }}>
              {data.map((item, index) => {
                const tableOrders = orders.filter((i) => i.tableKey === item.key && !i.isCompleted);

                return <TableCard {...{ item, currentUser, tableOrders }} key={index} onClick={() => {}} />;
              })}
            </View>
          </View>
        )}
      />
    </RootView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
