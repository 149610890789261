import { StyleSheet, View } from 'react-native';
import React, { FC, useEffect } from 'react';
import { FormType, ItemStatus } from '../../../utils/enums';
import { ISponsor } from '../../../models/ISponsor';
import { FieldValues, useForm } from 'react-hook-form';
import ConfirmDialog from '../../../components/dialogs/confirm/ConfirmDialog';
import CustomInput from '../../../components/inputs/CustomInput';
import Spacing from '../../../res/constants/Spacing';
import collections from '../../../utils/collections';
import useLocalization from '../../../app/providers/localization/LocalizationProvider';
import { Button } from 'react-native-paper';
import { IMeta } from '../../../models/IMeta';
import { useFirestore } from '../../../app/providers/firestore';

interface Props {
  currentModel?: ISponsor;
  formType: FormType;
  handleClearObject: () => void;
  archivedMode: boolean;
  confirmDialogVisibility: boolean;
  toggleConfirmDialog: () => void;
}
const SponsorForm: FC<Props> = ({ currentModel, formType, handleClearObject, archivedMode, confirmDialogVisibility, toggleConfirmDialog }) => {
  // #region STATES
  // #endregion
  // #region CUSTOM HOOKS
  const { translate } = useLocalization();
  const { handlePublicDataCU } = useFirestore();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ISponsor>({ values: currentModel });
  // #endregion
  // #region FUNCTIONS
  const onSubmit = (params: FieldValues) => {
    const { name } = params;

    const meta: IMeta =
      formType === FormType.NEW
        ? { created: new Date().getTime(), status: ItemStatus.ACTIVE }
        : { ...currentModel?.meta, modified: new Date().getTime() };

    const payload: ISponsor = {
      key: currentModel?.key,
      meta,
      name,
    };

    handlePublicDataCU(payload, collections.sponsors, collections.entity).then(() => {
      handleClearObject();
    });
  };

  const handleClearForm = () => {
    const defaultValues = {
      name: '',
      meta: {},
    };
    reset(defaultValues);
  };

  // #endregion
  // #region EFFECTS
  useEffect(() => {
    if (formType === FormType.NEW) {
      handleClearForm();
    }
  }, [formType]);
  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <View style={[styles.root]}>
      <View style={{ flex: 1, gap: Spacing.micro }}>
        <CustomInput
          error={errors.name}
          required={true}
          control={control}
          name={'name'}
          placeholder={translate('general.name') as string}
          iconProps={{ leftName: 'Paper', isLeftIconly: true, showRight: false }}
        />
      </View>

      <Button loading={false} style={{ marginTop: Spacing.regular }} disabled={false} onPress={handleSubmit(onSubmit)} mode="contained">
        {translate('general.save') as string}
      </Button>

      <ConfirmDialog
        title={'Are you sure?'}
        message={`Attention! You are about to ${archivedMode ? 'restore' : 'remove'} this item.`}
        visible={confirmDialogVisibility}
        dismiss={() => toggleConfirmDialog()}
        onConfirm={() => {
          handlePublicDataCU(
            {
              ...currentModel,
              meta: { ...currentModel?.meta, modified: new Date().getTime(), status: archivedMode ? ItemStatus.ACTIVE : ItemStatus.INACTIVE },
            },
            collections.sponsors,
            collections.entity
          ).then(() => {
            toggleConfirmDialog();
            handleClearObject();
          });
        }}
      />
    </View>
  );
};

export default SponsorForm;

const styles = StyleSheet.create({ root: { flex: 1 } });
