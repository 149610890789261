import { combineReducers, configureStore } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistReducer, persistStore } from 'redux-persist'; //Mos i fshi
import cartSlice from '../features/cart/cartSlice';
import localeSlice from '../features/locale/localeSlice';
import backOfficeSlice from '../features/back_office/backOfficeSlice';
import departmentsSlice from '../features/departments/departmentsSlice';

const reducers = combineReducers({
  cart: cartSlice,
  locale: localeSlice,
  backOffice: backOfficeSlice,
  departments: departmentsSlice,
});

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['cart', 'locale', 'backOffice', 'departments'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
