import { StyleSheet, TouchableOpacity, View } from 'react-native';
import React, { FC, useState } from 'react';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import { Menu, IconButton, Text, Button, Divider, Avatar } from 'react-native-paper';
import Spacing from '../../res/constants/Spacing';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import useToggle from '../../hooks/useToggle';
import { IStaff } from '../../models/IStaff';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { getInitials } from '../../utils/common';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { FormType } from '../../utils/enums';
import { IOrder } from '../../models/IOrder';

interface Props {
  section: any;
  staffList: IStaff[];
  onCloseClick: (section: any) => void;
  onError: () => void;
}

const OrderListHeader: FC<Props> = ({ section, staffList, onCloseClick, onError }) => {
  // #region STATES
  const [menuVisibility, toggleMenu] = useToggle(false);
  const [staff, setStaff] = useState<IStaff | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  // #endregion
  // #region CUSTOM HOOKS
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  const navigation = useNavigation<NavigationProp<{ EditStaff: { key?: string; type: FormType } }, 'EditStaff'>>(); // #endregion
  // #region FUNCTIONS
  const handleOnMenuClick = (item: IStaff) => {
    setStaff(item);
    toggleMenu();
  };
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  const theme = Styles[colorScheme];

  let uncompletedCount = section.data.reduce((acc: number, item: IOrder) => {
    if (!item.cartItems.every((item) => item.meta?.isCompleted ?? false)) {
      return acc + 1;
    }
    return acc;
  }, 0);

  // #endregion
  return (
    <View style={[styles.root, { backgroundColor: theme.listItemBackgroundColor }]}>
      <View style={styles.titleContainer}>
        {staff && <Avatar.Text label={getInitials(staff.name)} size={24} />}
        <Text numberOfLines={2} style={{ flex: 1 }} variant="titleMedium">
          {section.title.header}
        </Text>
      </View>
      <Button
        mode="text"
        labelStyle={{ fontSize: 14 }}
        disabled={uncompletedCount > 0}
        icon={'file-document'}
        onPress={() => {
          if (staff) {
            const copyOfSection = { ...section, staffKey: staff.key };
            onCloseClick(copyOfSection);
          } else {
            setError(true);
            onError();
          }
        }}
      >
        {uncompletedCount === 0 ? translate('general.close_table') : `${translate('general.pending_orders')}: ${uncompletedCount}`}
      </Button>

      <Menu
        onDismiss={() => toggleMenu()}
        anchor={
          <IconButton
            size={24}
            disabled={uncompletedCount > 0}
            style={{ backgroundColor: error ? MATERIAL_COLORS.red_300 : undefined }}
            icon={'dots-vertical'}
            onPress={() => {
              toggleMenu();
              setError(false);
            }}
          />
        }
        visible={menuVisibility}
      >
        <View style={{ padding: Spacing.regular, flexDirection: 'row', alignItems: 'center', gap: Spacing.small }}>
          <MaterialCommunityIcons name="account" size={24} color={MATERIAL_COLORS.blue_grey_500} />
          <Text style={{ color: MATERIAL_COLORS.blue_grey_500 }} variant="titleSmall">
            {translate('general.select_staff')}
          </Text>
        </View>
        <Divider />
        {staffList.length > 0 ? (
          staffList.map((item, index) => <Menu.Item key={index} title={item.name} onPress={() => handleOnMenuClick(item)} />)
        ) : (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('EditStaff', { key: undefined, type: FormType.NEW });
              toggleMenu();
            }}
            style={{
              paddingHorizontal: Spacing.regular,
              paddingTop: Spacing.regular,
              paddingBottom: Spacing.medium,
              alignItems: 'center',
              flexDirection: 'row',
              gap: Spacing.small,
            }}
          >
            <MaterialCommunityIcons name="account-plus" size={24} />

            <Text variant="bodyMedium">{translate('general.add_staff')}</Text>
          </TouchableOpacity>
        )}
      </Menu>
    </View>
  );
};

export default OrderListHeader;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: Spacing.small,
    paddingLeft: Spacing.regular,
  },
  titleContainer: { flex: 1, flexDirection: 'row', alignItems: 'center', gap: Spacing.small },
});
