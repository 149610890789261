import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';
import { firebase, auth } from '../../config/web/firebase_config';
import { UserRole } from '../../../utils/enums';
import collections from '../../../utils/collections';
import { ILoginDTO } from '../../../models/dto/ILoginDTO';
import { IUser, userMapper } from '../../../models/IUser';
import { IRegisterBusinessDTO } from '../../../models/dto/IRegisterBusinessDTO';
import { IUserMetadata } from '../../../models/IUserMetadata';
import ActivityIndicatorContainer from '../../../components/progress_views/ActivityIndicatorContainer';
import { useAppDispatch } from '../../../hooks/hooks';
import { clearSelectedUser } from '../../features/back_office/backOfficeSlice';
import { onAuthStateChanged, signOut as authSignOut, User, updateEmail } from 'firebase/auth';
import Config from '../../../res/constants/Config';
import { saveMessagingDeviceToken } from '../../config/web/messaging';

const Context = createContext<{
  user: User | undefined | null;
  error: any;
  loading: boolean;
  signInWithEmailAndPassword: (value: ILoginDTO) => Promise<any>;
  signUpWithEmailAndPassword: (value: IRegisterBusinessDTO) => Promise<any>;
  handleChangeEmail: (value: string) => Promise<any>;
  signOut: () => void;
}>({
  user: null,
  error: undefined,
  loading: false,
  signInWithEmailAndPassword: () => Promise.prototype,
  signUpWithEmailAndPassword: () => Promise.prototype,
  handleChangeEmail: () => Promise.prototype,
  signOut: () => Function,
});

export interface IAuthenticationProviderProps {
  children: ReactNode;
}

export const AuthenticationProvider = ({ children }: IAuthenticationProviderProps) => {
  const usersRef = useMemo(() => firebase.firestore().collection(collections.users), []);
  const dispatch = useAppDispatch();
  //#region STATES
  const [user, setUser] = useState<User | undefined | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [error, setError] = useState<any | undefined>(undefined);
  //#endregion
  //#region FUNCTIONS
  const authStateChanged = async (authUser: User | null | undefined) => {
    if (authUser && authUser !== null) {
      setUser(authUser);
      // Update database with device token
      if (Config.isWeb) {
        saveMessagingDeviceToken(authUser.uid);
      }
    } else {
      setUser(null);
    }

    setInitialLoading(false);
  };

  const signInWithEmailAndPassword = async (body: ILoginDTO) => {
    try {
      firebase
        .auth()
        .signInWithEmailAndPassword(body.email, body.password)
        .then((authUser: firebase.auth.UserCredential) => {
          if (authUser.user) {
            updateUser(authUser.user);

            console.debug(authUser);
          }
        })
        .catch((error) => setError(error));
    } catch (error) {
      setError(error);
    }
  };

  const signUpWithEmailAndPassword = async (body: IRegisterBusinessDTO) => {
    try {
      firebase
        .auth()
        .createUserWithEmailAndPassword(body.email, body.password)
        .then((authUser: firebase.auth.UserCredential) => {
          if (authUser.user) {
            const metadata: IUserMetadata = {
              creationTime: authUser.user.metadata.creationTime,
              lastSignInTime: authUser.user.metadata.lastSignInTime,
            };

            const temporary: IUser = {
              displayName: body.displayName,
              email: authUser.user.email,
              emailVerified: authUser.user.emailVerified,
              isAnonymous: authUser.user.isAnonymous,
              metadata,
              phoneNumber: body.phone,
              photoURL: authUser.user.photoURL,
              uid: authUser.user.uid,
              businessName: body.businessName,
              role: UserRole.SIMPLE,
              orderPrivileges: [],
            };

            updateUser(temporary);
          }
        })
        .catch((error) => setError(error));
    } catch (error) {
      setError(error);
    }
  };

  const updateUser = async (value: any) => {
    //FIXME: kur vendos foto, emer, telefon duhet te vendosen dhe te auth user i firebase, sepse nuk i merr
    // Per kete arsye po perdor momentalisht
    //  ...userMapper(value),
    //  photoURL: data.photoURL,
    //  businessName: data.businessName,
    //  displayName: data.displayName,
    //  phoneNumber: data.phoneNumber,

    setLoading(true);
    await usersRef
      .doc(value.uid)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          if (data) {
            usersRef
              .doc(value.uid)
              .update({
                ...userMapper(value),
                photoURL: data.photoURL,
                businessName: data.businessName,
                displayName: data.displayName,
                phoneNumber: data.phoneNumber,
              })
              .then(() => {
                console.debug('User updated!');
              })
              .catch((error) => console.error(`useFirebase ${error}`));
          }
        } else {
          usersRef
            .doc(value.uid)
            .set({ ...userMapper(value), businessName: value.businessName || null, role: UserRole.SIMPLE })
            .then(() => {
              console.debug('User created!');
            })
            .catch((error) => console.error(`useFirebase ${error}`));
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const signOut = () => {
    try {
      authSignOut(auth).then(() => {
        setUser(null);
      });
    } catch (e) {
      console.error(e);
    } finally {
      //TODO: set loading false
      AsyncStorage.clear();
      dispatch(clearSelectedUser());
    }
  };

  const handleChangeEmail = async (email: string) => {
    if (auth.currentUser) {
      updateEmail(auth.currentUser, email)
        .then(() => {
          console.log('Info@montecarlosrlsiracusa.it');
        })
        .catch((error) => {
          console.error('update email: ', error);
        });
    }
  };
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, []);
  //#endregion

  const memoValue = useMemo(
    () => ({ user, error, loading, signInWithEmailAndPassword, signUpWithEmailAndPassword, handleChangeEmail, signOut }),
    [user, error, loading]
  );

  if (loading || initialLoading) return <ActivityIndicatorContainer />;

  return <Context.Provider value={memoValue}>{children}</Context.Provider>;
};

export default function useAuth() {
  return useContext(Context);
}
