import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { View as RootView } from '../../components/Themed';
import { Text } from 'react-native-paper';
import Spacing from '../../res/constants/Spacing';

interface Props {}
const TermsAndConditionsScreen: FC<Props> = ({}) => {
  // #region STATES
  // #endregion
  // #region CUSTOM HOOKS
  // #endregion
  // #region FUNCTIONS
  // #endregion
  // #region EFFECTS
  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <RootView style={styles.root}>
      <Text variant="headlineSmall">TERMENI ȘI CONDIȚII</Text>
      <Text variant="titleLarge">Condiții Generale pentru utilizarea site-ului ”www.menuamoremio.com”</Text>
      <View style={{ padding: Spacing.regular }}></View>
    </RootView>
  );
};

export default TermsAndConditionsScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Spacing.regular,
  },
});
