import { StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { PrivateStackScreenProps } from '../../../../app/navigation/types';
import { LeftPanelRefactor } from '../../../../components/responsive_containers/LeftPanelRefactor';
import EditItemSideMenu from '../../../../components/side_menu/EditItemSideMenu';
import { FormType, ItemStatus, UserRole } from '../../../../utils/enums';
import { View as RootView } from '../../../../components/Themed';
import { useMediaQuery } from 'react-responsive';
import useToggle from '../../../../hooks/useToggle';
import Spacing from '../../../../res/constants/Spacing';
import { useFocusEffect } from '@react-navigation/native';
import { IStaff } from '../../../../models/IStaff';
import collections from '../../../../utils/collections';
import { useFirestore } from '../../../../app/providers/firestore';
import RightPanelRefactor from '../../../../components/responsive_containers/RightPanelRefactor';
import SponsorForm from '../SponsorForm';
import { ISponsor } from '../../../../models/ISponsor';
import { IconButton } from 'react-native-paper';
import BusinessPicker from '../../../../components/general/userPicker/BusinessPicker';

const EditSponsorScreen = ({ navigation, route }: PrivateStackScreenProps<'EditSponsor'>) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // #region STATES
  const [archivedMode, toggleArchivedMode] = useToggle(false);
  const [sponsorList, setSponsorList] = useState<ISponsor[]>([]);
  const [currentModel, setCurrentModel] = useState<ISponsor | undefined>(undefined);
  const [formType, setFormType] = useState<FormType>(route.params.type);
  const [confirmDialogVisibility, toggleConfirmDialog] = useToggle(false);
  // #endregion
  // #region CUSTOM HOOKS
  const { handleListenPublicCollectionChange } = useFirestore();
  // #endregion
  // #region FUNCTIONS
  const handleClearObject = () => {
    setCurrentModel(undefined);
    setFormType(FormType.NEW);
    navigation.setParams({ type: FormType.NEW });
  };

  const handleSelectCurrentModel = (item: ISponsor) => {
    if (!isTabletOrMobile) {
      setCurrentModel(item);
      setFormType(FormType.EDIT);
      navigation.setParams({ type: FormType.EDIT });
    } else {
      // navigation.navigate('TableDetail', { key: item.key, type: FormType.EDIT, archivedMode: archivedMode });
    }
  };

  const handleMenuItemClick = (index: number) => {
    switch (index) {
      case 0:
        toggleArchivedMode();
        handleClearObject();
        break;

      default:
        break;
    }
  };

  // #endregion
  // #region EFFECTS
  useFocusEffect(
    useCallback(() => {
      const staff = handleListenPublicCollectionChange(collections.sponsors, collections.entity)
        .where('meta.status', '==', archivedMode ? ItemStatus.INACTIVE : ItemStatus.ACTIVE)
        .onSnapshot((querySnapshot) => {
          const temporary: IStaff[] = [];
          querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
            temporary.push({
              ...documentSnapshot.data(),
              key: documentSnapshot.id,
            });
          });
          setSponsorList(temporary);
        });

      return () => {
        staff();
      };
    }, [archivedMode])
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        currentModel !== undefined && <IconButton icon={archivedMode ? 'delete-restore' : 'delete'} onPress={() => toggleConfirmDialog()} />,
    });
  }, [navigation, formType, archivedMode]);

  // #endregion
  // #region MEMBERS
  // #endregion
  return (
    <RootView style={[styles.root, { padding: isTabletOrMobile ? 0 : Spacing.regular }]}>
      <LeftPanelRefactor
        showFab={!archivedMode}
        onFabPress={() => {
          handleClearObject();
          if (isTabletOrMobile) {
            // navigation.navigate('TableDetail', { key: undefined, type: FormType.NEW, archivedMode });
          }
        }}
      >
        <EditItemSideMenu
          isBackVisible={archivedMode}
          menuItems={[{ id: 0, title: 'archived', icon: 'delete' }]}
          items={sponsorList}
          onPress={handleSelectCurrentModel}
          onMenuItemPress={handleMenuItemClick}
          onBackPress={() => {
            toggleArchivedMode();
          }}
          currentSelectedItem={currentModel && currentModel?.key}
        />
      </LeftPanelRefactor>

      <RightPanelRefactor>
        <SponsorForm {...{ currentModel, handleClearObject, formType, archivedMode, confirmDialogVisibility, toggleConfirmDialog }} />
      </RightPanelRefactor>
    </RootView>
  );
};

export default EditSponsorScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    gap: Spacing.small,
  },
});
