import { StyleSheet, View, Image } from 'react-native';
import React, { FC } from 'react';
import Layout from '../../res/constants/Layout';
import { ILandingModel } from '../../screens/public/LandingPageScreen';
import Spacing from '../../res/constants/Spacing';
import { useMediaQuery } from 'react-responsive';
import { Button, Text } from 'react-native-paper';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { useNavigation } from '@react-navigation/native';

interface Props {
  item: ILandingModel;
  height: number;
}
const LandingAnimatedItem: FC<Props> = ({ item, height }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  //#region HOOKS
  const { translate } = useLocalization();
  const navigation = useNavigation();
  //#endregion
  //#region FUNCTIONS
  const loadImage = (index: number) => {
    try {
      const image = require(`../../../assets/images/landing/landing_${index}.png`);
      return image;
    } catch (error) {
      console.warn(`Image asset not found: landing_${index}.png`);
      return require(`../../../assets/images/landing/landing_0.png`);
    }
  };

  const renderImage = (index: number) => {
    return (
      <Image
        source={loadImage(index)}
        style={{
          height: isTabletOrMobile ? '40%' : '50%',
          width: isTabletOrMobile ? '90%' : '40%',
          borderRadius: Spacing.bigRadius,
        }}
        resizeMode="cover"
      />
    );
  };
  //#endregion
  return (
    <View style={[styles.root, { backgroundColor: item.backgroundColor, height: Layout.window.height - height * 2 }]}>
      <View
        style={{
          flexDirection: isTabletOrMobile ? 'column-reverse' : 'row',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {renderImage(item.key)}
        <View style={{ maxWidth: isTabletOrMobile ? undefined : '40%', paddingVertical: Spacing.regular }}>
          <Text variant={isTabletOrMobile ? 'headlineSmall' : 'displaySmall'} style={styles.title}>
            {item.title}
          </Text>
          {item.subtitle && (
            <Text variant={isTabletOrMobile ? 'bodyLarge' : 'titleLarge'} style={styles.subtitle}>
              {item.subtitle}
            </Text>
          )}
          <View style={{ alignItems: 'flex-start', margin: Spacing.regular }}>
            {item.key === 8 && (
              <Button onPress={() => navigation.navigate('Register' as never)} mode="elevated">
                {translate('authentication.register')}
              </Button>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default LandingAnimatedItem;

const styles = StyleSheet.create({
  root: {
    padding: Spacing.regular,
  },
  title: { paddingHorizontal: Spacing.regular },
  subtitle: { paddingHorizontal: Spacing.regular, marginTop: Spacing.medium },
});
