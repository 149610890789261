import { StyleSheet } from 'react-native';
import React from 'react';
import { FontType } from '../utils/types';
import { Text, TextProps } from './Themed';

interface Props extends TextProps {
  fontType: FontType;
}
const CText = (props: Props) => {
  const selectedStyle = () => {
    switch (props.fontType) {
      case 'body1':
        return styles.body_1;
      case 'body2':
        return styles.body_2;
      case 'button':
        return styles.button;
      case 'caption':
        return styles.caption;
      case 'header1':
        return styles.header_1;
      case 'header2':
        return styles.header_2;
      case 'header3':
        return styles.header_3;
      case 'header4':
        return styles.header_4;
      case 'header5':
        return styles.header_5;
      case 'header6':
        return styles.header_6;
      case 'overline':
        return styles.overline;
      case 'subtitle1':
        return styles.subtitle_1;
      case 'subtitle2':
        return styles.subtitle_2;
    }
  };
  return <Text {...props} style={[{ ...selectedStyle(), ...(props.style as object) }]} />;
};

export default CText;

const styles = StyleSheet.create({
  body_1: {
    fontSize: 16,
    letterSpacing: 0.5,
  },
  body_2: {
    fontSize: 14,
    letterSpacing: 0.25,
  },
  button: {
    fontSize: 14,
    letterSpacing: 1.25,
    textTransform: 'uppercase',
  },
  caption: { fontSize: 12, letterSpacing: 0.4 },
  header_1: {
    fontSize: 96,
    letterSpacing: -1.5,
  },
  header_2: {
    fontSize: 60,
    letterSpacing: -0.5,
  },
  header_3: {
    fontSize: 48,
    letterSpacing: 0,
  },
  header_4: {
    fontSize: 34,
    letterSpacing: 0.25,
  },
  header_5: {
    fontSize: 24,
    letterSpacing: 0,
  },
  header_6: {
    fontSize: 20,
    letterSpacing: 0.15,
  },
  overline: {
    fontSize: 10,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
  },
  subtitle_1: {
    fontSize: 16,
    letterSpacing: 0.15,
  },
  subtitle_2: {
    fontSize: 14,
    letterSpacing: 0.1,
  },
});
