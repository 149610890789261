import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import React, { ReactNode, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import Animated, { useAnimatedStyle, interpolate, useSharedValue, withTiming, Easing, runOnJS } from 'react-native-reanimated';
import { MATERIAL_COLORS, OVERLAY } from '../../res/constants/Colors';
import Spacing from '../../res/constants/Spacing';
import Layout from '../../res/constants/Layout';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';

interface Props {}
export type BottomSheetFragmentContainerRef = {
  present: (height: number, children: ReactNode) => void;
  dismiss: () => void;
};

const BottomSheetContainer = forwardRef<BottomSheetFragmentContainerRef>(({}, ref) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  //#region MEMBERS
  const insets = useSafeAreaInsets();
  //#endregion
  //#region STATES
  const [children, setChildren] = useState<ReactNode>(null);
  const [height, setHeight] = useState<number>(0);
  //#endregion
  //#region ACTIONS
  const present = useCallback((height: number, children: ReactNode) => {
    setHeight(height);
    setChildren(children);
    animate(-height - Spacing.small - insets.bottom);
  }, []);
  const dismiss = useCallback(() => {
    animate(0);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      present,
      dismiss,
    }),
    [present, dismiss]
  );
  //#endregion
  //#region ANIMATED VALUES
  const translateY = useSharedValue(0);
  //#endregion
  //#region ANIMATED STYLE
  const innerStyle = useAnimatedStyle(() => {
    return { transform: [{ translateY: translateY.value }] };
  });
  const outerStyle = useAnimatedStyle(() => {
    const opacity = interpolate(translateY.value, [0, -height - Spacing.small - insets.bottom], [0, 1]);
    const display = opacity === 0 ? 'none' : 'flex';
    return { opacity, display };
  });
  //#endregion
  //#region FUNCTIONS
  const animate = (value: number) => {
    translateY.value = withTiming(value, { easing: Easing.circle }, (finished) => {
      if (finished) {
        if (value === 0) {
          runOnJS(setHeight)(0);
          runOnJS(setChildren)(null);
        }
      }
    });
  };
  //#endregion
  return (
    <TouchableWithoutFeedback onPress={dismiss}>
      <Animated.View style={[styles.outer, outerStyle]}>
        <Animated.View style={[styles.innerContainer, innerStyle, { height, width: isTabletOrMobile ? '95%' : '40%' }]}>{children}</Animated.View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
});

export default BottomSheetContainer;

const styles = StyleSheet.create({
  outer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: OVERLAY,
    alignItems: 'center',
  },
  innerContainer: {
    backgroundColor: MATERIAL_COLORS.white,
    top: Layout.window.height,
    borderRadius: Spacing.defaultRadius,
  },
});
