import { StyleSheet, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useFirestore } from '../../app/providers/firestore';
import useColorScheme from '../../hooks/useColorScheme';
import Styles from '../../res/constants/Styles';
import Spacing from '../../res/constants/Spacing';
import { Avatar, Card, Chip, Text } from 'react-native-paper';
import { formatDistance } from 'date-fns';
import { IUser } from '../../models/IUser';
import ProfilePicture from '../../components/images/ProfilePicture';
import useLocalization from '../../app/providers/localization/LocalizationProvider';
import { color } from 'react-native-reanimated';
import { IOrder } from '../../models/IOrder';

const OrderStatusScreen = ({ navigation, route }: any) => {
  //#region STATES
  const [model, setModel] = useState<IOrder | undefined>(undefined);
  const [business, setBusiness] = useState<IUser | undefined>(undefined);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  const { handleListenDocumentChangeRefactored, handleGetUserDocument } = useFirestore();
  //#endregion
  //#region MEMBERS
  const key = route.params.key;
  const uid = route.params.uid;
  const theme = Styles[colorScheme];
  //#endregion
  //#region EFFECTS
  useEffect(() => {
    const orderListener = handleListenDocumentChangeRefactored(key, uid).then((value) =>
      value.onSnapshot((item) => {
        if (item !== undefined) {
          setModel(item.data() as IOrder);
        }
      })
    );
    handleGetUserDocument(uid).then((value) => setBusiness(value));

    return () => {
      orderListener;
    };
  }, [key, uid]);

  // useEffect(() => {
  //   navigation.addListener('beforeRemove', (e: { preventDefault: () => void; data: { action: any } }) => {
  //     // Prevent default behavior of leaving the screen
  //     e.preventDefault();
  //   });
  // }, [navigation]);

  //#endregion

  if (model === undefined) {
    return null;
  }

  return (
    <View style={[styles.root, { backgroundColor: theme.background }]}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ flex: 1 }}>
          <Card style={{ margin: Spacing.micro, backgroundColor: 'white' }} contentStyle={{ alignItems: 'center', padding: Spacing.regular }}>
            <ProfilePicture size={120} source={business?.photoURL} hasRadius={true} />
            <Card.Content style={{ alignItems: 'center' }}>
              <Text style={{ color: theme.text }} variant="titleLarge">
                {business?.businessName}
              </Text>
              <Text style={{ color: theme.text }} variant="bodyMedium">
                {business?.phoneNumber}
              </Text>
            </Card.Content>
          </Card>

          {model.duration && (
            <Chip
              textStyle={{ color: theme.lightSelectedItem }}
              style={{ backgroundColor: theme.primary, alignSelf: 'flex-start', marginTop: Spacing.regular }}
            >
              {`${translate('general.duration')}: ${translate('general.around')} ${model.duration} min`}
            </Chip>
          )}

          <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: Spacing.medium }}>
            <Avatar.Icon size={24} icon="clock-time-three" style={{ backgroundColor: theme.primary }} />
            <Text style={{ marginStart: Spacing.medium, color: theme.text }} variant="labelLarge">
              {`${translate('general.ordered')}: ${
                model && model.meta && model.meta.created && formatDistance(model.meta.created, new Date(), { addSuffix: true })
              }`}
            </Text>
          </View>
          {!model.meta.isPending && !model.meta.isCompleted && (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: Spacing.medium }}>
              <Avatar.Icon size={24} icon="check" style={{ backgroundColor: theme.primary }} />
              <Text style={{ marginStart: Spacing.medium, color: theme.text }} variant="labelLarge">
                {`${translate('general.confirmed')}: ${
                  model && model.meta && model.meta.modified && formatDistance(model.meta.modified, new Date(), { addSuffix: true })
                }`}
              </Text>
            </View>
          )}
          {model.meta.isCompleted && (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: Spacing.medium }}>
              <Avatar.Icon size={24} icon="check" style={{ backgroundColor: theme.primary }} />
              <Text style={{ marginStart: Spacing.medium, color: theme.text }} variant="labelLarge">
                {`${translate('general.completed')}: ${
                  model && model.meta && model.meta.modified && formatDistance(model.meta.modified, new Date(), { addSuffix: true })
                }`}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default OrderStatusScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: Spacing.regular,
  },
});
