import { StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import { useAuth } from '../../app/providers/authentication';
import { AuthType } from '../../utils/enums';
import useColorScheme from '../../hooks/useColorScheme';
import { ILoginDTO } from '../../models/dto/ILoginDTO';
import Layout, { DEFAULT_GAP, RIGHT_SIDE_BAR_WIDTH } from '../../res/constants/Layout';
import Spacing from '../../res/constants/Spacing';
import { MATERIAL_COLORS } from '../../res/constants/Colors';
import CText from '../../components/CText';
import CustomInput from '../../components/inputs/CustomInput';
import CustomPressable from '../../components/pressable/CustomPressable';
import SaveButton from '../../components/pressable/SaveButton';
import Config from '../../res/constants/Config';
import { FieldValues, useForm } from 'react-hook-form';
import { View as RootView } from '../../components/Themed';
import Footer from '../../components/authentication/Footer';
import Styles from '../../res/constants/Styles';
import Header from '../../components/authentication/Header';
import InitialOnBoarding from '../../components/onBoarding/InitialOnBoarding';
import useLocalization from '../../app/providers/localization/LocalizationProvider';

export default function SignIn() {
  //#region STATES
  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
  //#endregion
  //#region HOOKS
  const colorScheme = useColorScheme();
  const { translate } = useLocalization();
  const { signInWithEmailAndPassword, loading, error } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginDTO>({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  //#endregion
  //#region FUNCTIONS
  const onSubmit = (params: FieldValues) => {
    signInWithEmailAndPassword(params);
    // reset();
  };

  //#endregion
  //#region MEMBERS
  const theme = Styles[colorScheme];
  //#endregion

  return (
    <RootView style={styles.root}>
      {Config.isWeb && Layout.window.width > 800 && <InitialOnBoarding />}

      <View
        style={{
          flex: 1,
          justifyContent: 'space-between',
          maxWidth: Config.isWeb && Layout.window.width > 800 ? RIGHT_SIDE_BAR_WIDTH : undefined,
        }}
      >
        {/* Title */}
        <Header type={AuthType.LOGIN} />

        <View style={{ flex: 1 }}>
          {/* Form */}
          <View
            style={{
              padding: Spacing.regular,
              margin: Spacing.regular,
              gap: DEFAULT_GAP,
              backgroundColor: MATERIAL_COLORS.grey_100,
              borderRadius: Spacing.defaultRadius,
            }}
          >
            <CustomInput
              error={errors.email}
              required={true}
              control={control}
              name={'email'}
              placeholder={'Email'}
              iconProps={{ leftName: 'Message', isLeftIconly: true, showRight: false, rightDisabled: true, leftDisabled: true }}
            />
            <CustomInput
              error={errors.password}
              required={true}
              control={control}
              name={'password'}
              placeholder={translate('authentication.password').toString()}
              iconProps={{
                leftName: 'Lock',
                isLeftIconly: true,
                showRight: true,
                rightName: isPasswordVisible ? 'Hide' : 'Show',
                isRightIconly: true,
                rightDisabled: false,
                leftDisabled: true,
              }}
              onRightIconPress={() => setPasswordVisibility((value) => !value)}
              passwordVisibility={!isPasswordVisible}
            />
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <CText style={{ color: MATERIAL_COLORS.red, padding: Spacing.small }} fontType="caption">
                {error?.code}
              </CText>
              <CustomPressable style={{ padding: Spacing.small }}>
                <CText fontType="caption">{translate('authentication.forgot_password')}</CText>
              </CustomPressable>
            </View>
            <SaveButton title={translate('authentication.login') as string} onPress={handleSubmit(onSubmit)} style={{ marginTop: Spacing.regular }} />
          </View>
        </View>

        {/* Footer */}
        <Footer type={AuthType.LOGIN} />
      </View>
    </RootView>
  );
}

const styles = StyleSheet.create({
  root: { flex: 1, flexDirection: 'row' },
});
