import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ReactNode } from 'react';
import useAuth from '../authentication/AuthenticationProvider.web';
import { firebase } from '../../config/web/firebase_config';
import { ISuggestion } from '../../../models/IMenu';
import { IUser } from '../../../models/IUser';
import collections from '../../../utils/collections';
import { IStaff } from '../../../models/IStaff';
import { ItemStatus } from '../../../utils/enums';

const Context = createContext<{
  currentUser: IUser | undefined;
  loading: boolean;
  handleDataCU: (params: any, collection: string) => Promise<any>;
  handleDataUnAuthorized: (business: string, params: any, collection: string) => Promise<any>;
  handleGlobalDataCU: (params: any) => Promise<any>;
  handleGetTranslation: (id: number, language: string) => Promise<any>;
  handleDeleteData: (params: any, collection: string) => Promise<any>;
  handleGetDocument: (id: string, params: string, collection: string) => Promise<any>;
  handleGetCollection: (key: string, collection: string) => Promise<any>;
  handleListenDocumentChange: (key: string, uid: string) => Promise<any>;
  handleListenDocumentChangeRefactored: (key: string, uid: string) => Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>;
  handleListenCollectionChange: (key: string, collection: string) => firebase.firestore.Query<firebase.firestore.DocumentData>;
  handleListenGlobalCollectionChange: () => firebase.firestore.Query<firebase.firestore.DocumentData>;
  handleListenUsersChange: () => firebase.firestore.Query<firebase.firestore.DocumentData>;
  handleGetUserDocument: (key: string) => Promise<any>;
  handleUpdateUser: (business: string, params: any) => Promise<any>;
  handlePublicDataCU: (params: any, document: string, collection: string) => Promise<any>;
  handleListenPublicCollectionChange: (key: string, collection: string) => firebase.firestore.Query<firebase.firestore.DocumentData>;
  staff: IStaff[];
  suggestedMenu: ISuggestion[];
  orders: any[];
  ingredients: any[];
}>({
  currentUser: undefined,
  loading: false,
  handleDataCU: () => Promise.prototype,
  handleDataUnAuthorized: () => Promise.prototype,
  handleGlobalDataCU: () => Promise.prototype,
  handleGetTranslation: () => Promise.prototype,
  handleDeleteData: () => Promise.prototype,
  handleGetDocument: () => Promise.prototype,
  handleGetCollection: () => Promise.prototype,
  handleListenDocumentChange: () => Promise.prototype,
  handleListenDocumentChangeRefactored: () => Promise.prototype,
  handleListenCollectionChange: () => {
    // You should replace this with the actual implementation
    return firebase.firestore().collection('dummy').limit(0);
  },
  handleListenGlobalCollectionChange: () => {
    // You should replace this with the actual implementation
    return firebase.firestore().collection('dummy').limit(0);
  },
  handleListenUsersChange: () => {
    // You should replace this with the actual implementation
    return firebase.firestore().collection('dummy').limit(0);
  },
  handleGetUserDocument: () => Promise.prototype,
  handleUpdateUser: () => Promise.prototype,
  handlePublicDataCU: () => Promise.prototype,
  handleListenPublicCollectionChange: () => {
    // You should replace this with the actual implementation
    return firebase.firestore().collection('dummy').limit(0);
  },
  staff: [],
  suggestedMenu: [],
  orders: [],
  ingredients: [],
});

export interface IFirestoreProviderProps {
  children: ReactNode;
}

export const FirestoreProvider = ({ children }: IFirestoreProviderProps) => {
  //#region HOOKS
  const { user } = useAuth();
  //#endregion
  //#region STATES
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [staff, setStaff] = useState<IStaff[]>([]);
  const [suggestedMenu, setSuggestedMenu] = useState<ISuggestion[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [ingredients, setIngredients] = useState<any[]>([]);
  //#endregion
  //#region MEMOIZED
  const usersRef = useMemo(() => firebase.firestore().collection(collections.users), []);
  const dataRef = useMemo(() => firebase.firestore().collection(collections.data), []);
  const publicRef = useMemo(() => firebase.firestore().collection(collections.public), []);
  const translationsRef = useMemo(() => firebase.firestore().collection(collections.translations), []);
  const memoValue = useMemo(
    () => ({
      currentUser,
      loading,
      handleDataCU,
      handleDataUnAuthorized,
      handleGlobalDataCU,
      handleGetTranslation,
      handleDeleteData,
      handleGetDocument,
      handleGetCollection,
      handleListenDocumentChange,
      handleListenDocumentChangeRefactored,
      handleListenCollectionChange,
      handleListenGlobalCollectionChange,
      handleListenUsersChange,
      handleGetUserDocument,
      handleUpdateUser,
      handlePublicDataCU,
      handleListenPublicCollectionChange,
      staff,
      suggestedMenu,
      orders,
      ingredients,
    }),
    [currentUser, loading, staff, suggestedMenu, orders, ingredients, handleListenDocumentChange, handleListenGlobalCollectionChange]
  );
  //#endregion
  //#region EFFECTS

  useEffect(() => {
    if (user !== null && user) {
      const userQuery = usersRef.doc(user.uid);
      const staffQueryUnSubscribe = dataRef
        .doc(user.uid)
        .collection(collections.staff)
        .where('meta.status', '==', ItemStatus.ACTIVE)
        .onSnapshot(onStaffCollectionResult);

      const ordersQueryUnSubscribe = dataRef
        .doc(user.uid)
        .collection(collections.orders)
        .where('meta.isActive', '==', true)
        .onSnapshot(onOrdersCollectionResult);

      const userQueryUnSubscribe = userQuery.onSnapshot((value) => {
        if (value.exists) {
          const data = value.data() as IUser;
          setCurrentUser(data);
        }
      });

      return () => {
        staffQueryUnSubscribe();
        ordersQueryUnSubscribe();
        userQueryUnSubscribe();
      };
    } else setCurrentUser(undefined);
  }, [user]);

  useEffect(() => {
    if (currentUser === undefined || currentUser === null) return;

    const ingredientsQueryUnSubscribe = translationsRef
      .doc('user_generated_items')
      .collection(collections.ingredients)
      .where('meta.isActive', '==', true)
      .onSnapshot(onIngredientsCollectionResult);

    return () => {
      ingredientsQueryUnSubscribe();
    };
  }, [currentUser]);

  //#endregion
  //#region FUNCTIONS

  function onStaffCollectionResult(querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
    const temporary: any[] = [];
    querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });
    setStaff(temporary);
  }

  function onOrdersCollectionResult(querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
    const temporary: any[] = [];
    querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });
    setOrders(temporary);
  }

  function onIngredientsCollectionResult(querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
    if (currentUser) {
      // if (currentUser.role !== UserRole.BACKOFFICE) return;
      const temporary: any[] = [];
      querySnapshot.forEach((documentSnapshot: { data: () => any; id: any }) => {
        temporary.push({
          ...documentSnapshot.data(),
          key: documentSnapshot.id,
        });
      });

      setIngredients(temporary);
    }
  }

  async function handleDataCU(params: any, collection: string) {
    if (currentUser) {
      const parentRef = dataRef.doc(currentUser.uid).collection(collection);
      if (!params.key) {
        params.key = parentRef.doc().id;
      }

      if (params.key) {
        const ref = parentRef.doc(params.key);
        await ref.get().then((document) => {
          if (!document.exists) {
            ref
              .set(params)
              .then(() => console.debug('Item Added'))
              .catch((error: any) => console.error(error));
          } else {
            ref
              .update(params)
              .then(() => console.debug('Item Updated'))
              .catch((error: any) => console.error(error));
          }
        });
      }
    }
  }

  async function handleDataUnAuthorized(business: string, params: any, collection: string) {
    setLoading(true);
    const parentRef = dataRef.doc(business).collection(collection);
    if (!params.key) {
      params.key = parentRef.doc().id;
    }

    if (params.key) {
      const ref = parentRef.doc(params.key);
      await ref
        .get()
        .then((document) => {
          if (!document.exists) {
            ref
              .set(params)
              .then(() => {
                console.debug('Item Added');
              })
              .catch((error: any) => console.error(error));
          } else {
            ref
              .update(params)
              .then(() => {
                console.debug('Item Updated');
              })
              .catch((error: any) => console.error(error));
          }
        })
        .finally(() => setLoading(false));

      return params.key;
    }
  }

  async function handleGlobalDataCU(params: any) {
    const parentRef = translationsRef.doc('user_generated_items').collection(collections.ingredients);
    if (!params.key) {
      params.key = parentRef.doc().id;
    }

    if (params.key) {
      const ref = parentRef.doc(params.key);
      await ref.get().then((document) => {
        if (!document.exists) {
          ref
            .set(params)
            .then(() => {
              console.debug('Translation Added');
            })
            .catch((error: any) => console.error(error));
        } else {
          ref
            .update(params)
            .then(() => {
              console.debug('Translation Updated');
            })
            .catch((error: any) => console.error(error));
        }
      });

      return params.key;
    }
  }

  async function handleGetTranslation(id: number, collection: string) {
    const parentRef = translationsRef.doc('user_generated_items').collection(collection).where('id', '==', id).limit(1);
    const snapshot = await parentRef.get();

    if (!snapshot.empty) {
      const document = snapshot.docs[0];
      const documentData = document.data();
      return documentData;
    } else {
      console.debug('[FirestoreProvider - handleGetTranslation]: ', 'No document found');
    }
  }

  async function handleUpdateUser(business: string, params: any) {
    const parentRef = usersRef.doc(business);

    if (business) {
      await parentRef.get().then((document) => {
        if (document.exists) {
          parentRef
            .update(params)
            .then(() => {
              console.debug('User Updated');
            })
            .catch((error: any) => console.error(error));
        }
      });

      return params.key;
    }
  }

  async function handleDeleteData(params: any, collection: string) {
    if (currentUser) {
      const parentRef = dataRef.doc(currentUser.uid).collection(collection);
      if (params.key === undefined || params.key === null || params.key === '') {
        params.key = parentRef.doc().id;
      }

      if (params.key) {
        const ref = parentRef.doc(params.key);
        await ref.get().then((document) => {
          if (document.exists) {
            ref
              .delete()
              .then(() => console.debug('Item Deleted'))
              .catch((error: any) => console.error(error));
          }
        });
      }
    }
  }

  async function handleGetDocument(targetId: string, key: string, collection: string) {
    setLoading(true);

    const parentRef = dataRef.doc(targetId).collection(collection);

    const payload = await parentRef
      .doc(key)
      .get()
      .finally(() => setLoading(false));

    return payload.data();
  }

  async function handleGetUserDocument(id: string) {
    const payload = await usersRef.doc(id).get();
    return payload.data();
  }

  async function handleGetCollection(key: string, collection: string) {
    const parentRef = dataRef.doc(key).collection(collection).where('meta.status', '==', ItemStatus.ACTIVE);
    const collections = await parentRef.get();

    const temporary: any[] = [];

    collections.forEach((documentSnapshot: { data: () => any; id: any }) => {
      temporary.push({
        ...documentSnapshot.data(),
        key: documentSnapshot.id,
      });
    });

    return temporary;
  }

  async function handlePublicDataCU(params: any, document: string, collection: string) {
    if (currentUser) {
      const parentRef = publicRef.doc(document).collection(collection);
      if (!params.key) {
        params.key = parentRef.doc().id;
      }

      if (params.key) {
        const ref = parentRef.doc(params.key);
        await ref.get().then((document) => {
          if (!document.exists) {
            ref
              .set(params)
              .then(() => console.debug('Item Added'))
              .catch((error: any) => console.error(error));
          } else {
            ref
              .update(params)
              .then(() => console.debug('Item Updated'))
              .catch((error: any) => console.error(error));
          }
        });
      }
    }
  }

  //#region LISTENERS
  async function handleListenDocumentChange(key: string, uid: string): Promise<any> {
    return new Promise<any>((resolve) => {
      dataRef
        .doc(uid)
        .collection(collections.orders)
        .doc(key)
        .onSnapshot((value) => {
          if (value.exists) {
            resolve(value.data());
          }
        });
    });
  }

  async function handleListenDocumentChangeRefactored(key: string, uid: string) {
    return dataRef.doc(uid).collection(collections.orders).doc(key);
  }

  function handleListenCollectionChange(uid: string, collection: string) {
    return dataRef.doc(uid).collection(collection);
  }

  function handleListenGlobalCollectionChange() {
    return translationsRef.doc('user_generated_items').collection(collections.ingredients);
  }

  function handleListenUsersChange() {
    return usersRef;
  }

  function handleListenPublicCollectionChange(document: string, collection: string) {
    return publicRef.doc(document).collection(collection);
  }

  //#endregion

  //#endregion

  return <Context.Provider value={memoValue}>{children}</Context.Provider>;
};

export default function useFirestore() {
  return useContext(Context);
}
