export default {
  users: 'users',
  data: 'data',
  events: 'events',
  services: 'services',
  customers: 'customers',
  staff: 'staff',
  menu: 'menu',
  table: 'tables',
  orders: 'orders',
  global: 'global',
  ingredients: 'ingredients',
  translations: 'translations',
  public: 'public',
  sponsors: 'sponsors',
  entity: 'entity',
};
