import { StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CustomPressable from './CustomPressable';
import Iconly from '../iconly/mapIcons';
import Spacing from '../../res/constants/Spacing';

interface Props {
  name: any;
  isIconly: boolean;
  onPress: () => void;
}
const RoundIconPressable: FC<Props> = ({ name, isIconly, onPress }) => {
  return (
    <View style={styles.root}>
      <CustomPressable onPress={onPress} style={styles.button}>
        {isIconly ? <Iconly name={name} set="light" /> : <MaterialCommunityIcons name={name} size={18} />}
      </CustomPressable>
    </View>
  );
};

export default RoundIconPressable;

const styles = StyleSheet.create({
  root: {
    borderRadius: 100,
    overflow: 'hidden',
  },
  button: {
    padding: Spacing.small,
  },
});
